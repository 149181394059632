import React, { useState, useRef, useEffect } from 'react';
import {
  Container,
  TextField,
  List,
  ListItem,
  Typography,
  Box,
  CircularProgress,
  IconButton,
  Button,
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { getUserData } from 'lib/Helper/Auth';
import { ChatMessage, ChatRequest, ChatResponse } from 'lib/Model/Chatbot';
import { sendMessageToChatbot } from 'lib/Api/Chatbot';
import ReactMarkdown from 'react-markdown';
import { Send, ThumbUpOutlined, ThumbDownOutlined } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';

const user = getUserData();

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '90vh',
    position: 'relative',
  },
  chatBox: {
    flex: 1,
    overflowY: 'auto',
  },
  messageBox: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(2),
    position: 'relative',
    '&:hover $feedbackButtonsBox': {
      display: 'flex',
    },
  },
  userMessage: {
    alignSelf: 'flex-end',
    backgroundColor: '#1C75BC',
    color: theme.palette.common.white,
    padding: theme.spacing(1),
    borderRadius: '10px',
    maxWidth: '70%',
    wordWrap: 'break-word',
  },
  botMessage: {
    alignSelf: 'flex-start',
    backgroundColor: theme.palette.grey[300],
    padding: theme.spacing(1),
    borderRadius: '10px',
    maxWidth: '70%',
    wordWrap: 'break-word',
    position: 'relative',
  },
  feedbackButtonsBox: {
    display: 'none',
    position: 'absolute',
    right: '-40px',
    top: '50%',
    transform: 'translateY(-50%)',
    flexDirection: 'column',
  },
  iconButtonRoot: {
    padding: 4,
  },
  inputBox: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
  },
  inputField: {
    flex: 1,
    marginRight: theme.spacing(1),
  },
  messageHeader: {
    fontSize: '0.8rem',
    fontWeight: 'bold',
    marginBottom: theme.spacing(0.5),
    alignSelf: 'flex-end',
  },
  botHeader: {
    alignSelf: 'flex-start',
  },
  loader: {
    alignSelf: 'flex-start',
    padding: theme.spacing(1),
  },
  footer: {
    paddingTop: theme.spacing(0.5),
    textAlign: 'center',
    fontSize: '0.8rem',
    color: theme.palette.text.secondary,
  },
  feedbackButtonContainer: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
  },
  infoBox: {
    // backgroundColor: theme.palette.info.light,
    padding: theme.spacing(2),
    borderRadius: '10px',
    textAlign: 'center',
    color: theme.palette.common.black,
    marginBottom: theme.spacing(2),
    fontSize: '1rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.875rem',
    },
  },
  optionsContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    position: 'relative',
  },
  optionBox: {
    width: '100%',
    minHeight: '75px',
    backgroundColor: theme.palette.secondary.light,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    borderRadius: '10px',
    textAlign: 'center',
    padding: theme.spacing(0.5, 2, 0.5, 2),
    color: 'white',
    border: `1px solid black`,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
    zIndex: 2,
  },
  backgroundImage: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '200px',
    height: 'auto',
    opacity: 0.2,
    zIndex: -1,
  },
}));

const sessionId = [...Array(16)]
  .map(() => Math.random().toString(36)[2])
  .join('');

const options = [
  "What's the C-Number for Konnekt?",
  'How many vacancies have we opened this year?',
  'Does my department have any client meetings today?',
  'Can I book well-being leave during a public holiday week?',
  "What's the last interview I conducted?",
  'Who are the shareholders for Foster Clark?',
  "Who's the CAM for Corinthia Caterers?",
  'When do our Konnekt terms of business expire with Go P.L.C.?',
  'What are the billing details for Kitchen Concepts Ltd?',
  "Who's on leave in my department this week?",
];

const OptionBoxes: React.FC<{ onSelect: (option: string) => void }> = ({
  onSelect,
}) => {
  const classes = useStyles();
  const [randomOptions, setRandomOptions] = useState<string[]>([]);

  useEffect(() => {
    const shuffledOptions = [...options].sort(() => 0.5 - Math.random());
    setRandomOptions(shuffledOptions.slice(0, 5));
  }, []);

  return (
    <>
      <Alert severity="info" variant="outlined">
        <strong>Tip:</strong> Try to be as specific as possible in your question
        - the more accurate your question, the more accurate the response. This
        includes specifying full names, brands, etc.
        <br />
        <br />
        You can get started by clicking one of the below sample prompts, or
        typing in your own question.
      </Alert>
      <Box className={classes.optionsContainer}>
        <img
          src={'/apple-touch-icon.png'}
          alt="Expedition42 Logo"
          className={classes.backgroundImage}
        />

        <Grid container spacing={2} justify="center" alignItems="center">
          {randomOptions.map((option, index) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={2}
              key={index}
              onClick={() => onSelect(option)}
            >
              <Box className={classes.optionBox}>
                <Typography>{option}</Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};

const ChatbotView: React.FC = () => {
  const classes = useStyles();
  const [message, setMessage] = useState('');
  const [chatHistory, setChatHistory] = useState<ChatMessage[]>([]);
  const [loading, setLoading] = useState(false);
  const [showOptions, setShowOptions] = useState(true);
  const endOfMessagesRef = useRef<HTMLDivElement | null>(null);

  const handleSend = async (msg: string) => {
    if (msg.trim() === '') return;

    setShowOptions(false);

    const newMessage: ChatMessage = { role: 'user', content: msg };
    setChatHistory((prevHistory) => [...prevHistory, newMessage]);
    setMessage('');
    setLoading(true);

    const requestPayload: ChatRequest = {
      message: msg,
      session_id: sessionId,
    };

    try {
      const response: ChatResponse = await sendMessageToChatbot(requestPayload);
      const botMessage: ChatMessage = {
        role: 'bot',
        content: response.response,
      };
      setChatHistory((prevHistory) => [...prevHistory, botMessage]);
    } finally {
      setLoading(false);
    }
  };

  const handleFeedback = (feedback: string) => {
    handleSend(feedback);
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleSend(message);
    }
  };

  useEffect(() => {
    if (endOfMessagesRef.current) {
      endOfMessagesRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [chatHistory]);

  return (
    <Container className={classes.container}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box />
        <Button
          color="primary"
          href="https://docs.google.com/document/d/1KI-fZ_E-io0DvPgdQ3tx8LPLRgfgo5Ojj5udRQtpKVs/edit#heading=h.tunhn5ld2jm"
          target="_blank"
        >
          Need help?
        </Button>{' '}
      </Box>
      {showOptions && (
        <OptionBoxes
          onSelect={(selectedOption) => {
            handleSend(selectedOption);
          }}
        />
      )}
      <Box className={classes.chatBox}>
        <List>
          {chatHistory.map((chat, index) => (
            <React.Fragment key={index}>
              {chat.role === 'user' ? (
                <ListItem className={classes.messageBox}>
                  <Typography className={classes.messageHeader}>
                    {user?.name}
                  </Typography>
                  <Box className={classes.userMessage}>
                    <Typography>{chat.content}</Typography>
                  </Box>
                </ListItem>
              ) : (
                <ListItem className={classes.messageBox}>
                  <Typography
                    className={`${classes.messageHeader} ${classes.botHeader}`}
                  >
                    Expedition42 Bot
                  </Typography>
                  <Box className={classes.botMessage}>
                    <ReactMarkdown>{chat.content}</ReactMarkdown>
                    <div className={classes.feedbackButtonsBox}>
                      <IconButton
                        classes={{ root: classes.iconButtonRoot }}
                        onClick={() => handleFeedback('This was helpful.')}
                      >
                        <ThumbUpOutlined color="primary" />
                      </IconButton>
                      <IconButton
                        classes={{ root: classes.iconButtonRoot }}
                        onClick={() =>
                          handleFeedback("This isn't what I was looking for.")
                        }
                      >
                        <ThumbDownOutlined color="primary" />
                      </IconButton>
                    </div>
                  </Box>
                </ListItem>
              )}
            </React.Fragment>
          ))}
          {loading && (
            <ListItem className={classes.messageBox}>
              <CircularProgress className={classes.loader} />
            </ListItem>
          )}
          <div ref={endOfMessagesRef} />
        </List>
      </Box>
      <Box className={classes.inputBox}>
        <TextField
          className={classes.inputField}
          fullWidth
          autoFocus
          variant="outlined"
          value={message}
          placeholder="Message Expedition42 Bot"
          onChange={(e) => setMessage(e.target.value)}
          onKeyPress={handleKeyPress}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            endAdornment: (
              <IconButton
                type="submit"
                size="small"
                disabled={loading}
                onClick={() => handleSend(message)}
              >
                <Send />
              </IconButton>
            ),
          }}
        />
      </Box>
      <Typography className={classes.footer}>
        This bot can make mistakes. Do <strong>NOT</strong> base any important
        decisions solely on the information provided by this tool.
      </Typography>

      <Typography className={classes.footer}>
        Please report any issues to insights@exp42.com!
      </Typography>
    </Container>
  );
};

export default ChatbotView;
