// components/WellbeingMessage.tsx
import * as React from 'react';
import { grey } from '@material-ui/core/colors';
import { Typography, Box } from '@material-ui/core';

const WellBeingMessage: React.FC = () => {
  return (
    <Box m={2}>
      <Typography variant="body1" style={{ fontWeight: 400, color: grey[600] }}>
        Our mission is to improve individual and organisational wellbeing and to
        have a measurable positive impact on the world of work.
      </Typography>
    </Box>
  );
};

export default WellBeingMessage;
