import * as React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
} from '@material-ui/core';
import { toMonetary } from 'lib';
import { JobInvoice } from 'lib/Model/Job';
import { format, parseISO } from 'date-fns';
import { DateFormat } from 'config';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    textAlign: 'center',
  },
}));

interface Props {
  open: boolean;
  vacancyTitle: string;
  invoices: JobInvoice[];
  onClose(): void;
  loading?: boolean;
}

const InvoicesDialog: React.FC<Props> = ({
  open,
  vacancyTitle,
  invoices,
  onClose,
  loading,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="invoices-dialog"
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle id="invoices-dialog" className={classes.dialogTitle}>
        Invoices for {vacancyTitle}
      </DialogTitle>
      <DialogContent>
        {loading ? (
          <Skeleton variant="rect" style={{ height: 200 }} />
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Invoice Number</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Leader</TableCell>
                <TableCell>Source</TableCell>
                <TableCell>Service Title</TableCell>
                <TableCell>Start Date</TableCell>
                <TableCell>Invoice Amount</TableCell>
                <TableCell>Created On</TableCell>
                <TableCell>Date Processed</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {invoices.map((invoice) => (
                <TableRow key={invoice.invoice_number}>
                  <TableCell>{invoice.invoice_number}</TableCell>
                  <TableCell>{invoice.invoice_description ?? 'N/A'}</TableCell>
                  <TableCell>{invoice.leader}</TableCell>
                  <TableCell>{invoice.source}</TableCell>
                  <TableCell>{invoice.service_title}</TableCell>
                  <TableCell>
                    {invoice.employment_start_date
                      ? format(
                          parseISO(invoice.employment_start_date),
                          DateFormat.EXCEL,
                        )
                      : '-'}
                  </TableCell>
                  <TableCell>{toMonetary(invoice.invoice_amount)}</TableCell>
                  <TableCell>
                    {invoice.invoice_creation_date
                      ? format(
                          parseISO(invoice.invoice_creation_date),
                          DateFormat.EXCEL,
                        )
                      : '-'}
                  </TableCell>
                  <TableCell>
                    {invoice.invoice_date_processed
                      ? format(
                          parseISO(invoice.invoice_date_processed),
                          DateFormat.EXCEL,
                        )
                      : '-'}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InvoicesDialog;
