import axios from 'axios';
import { Config } from 'config';
import { getAccessToken } from 'lib/Helper/Auth';
import { ChatRequest, ChatResponse } from 'lib/Model/Chatbot';

export function sendMessageToChatbot(
  request: ChatRequest,
): Promise<ChatResponse> {
  return axios
    .post(`${Config.API_BASE_URL}intranet/chat`, request, {
      headers: {
        'Content-Type': 'application/json',
        'access-token': getAccessToken(),
      },
    })
    .then((response) => response.data);
}
