import {
  Button,
  Card,
  CardHeader,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Popper,
  Switch,
  Typography,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { PageableTable } from 'components';
import { DateDisplay } from 'components/DateDisplay/DateDisplay';
import { PageableTableHeader } from 'components/PageableTable/components';
import { RelatedCompanyChip } from 'components/RelatedCompanyChip';
import {
  useLocalStorageSettings,
  TableSettings,
  getTableSettingsStorageKey,
} from 'hooks';
import { fetchMeetings } from 'lib/Api/Activity';
import { PagedMeetings, Meeting } from 'lib/Model/Activity';
import * as React from 'react';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: theme.spacing(2),
  },
  dateColumn: {
    width: 60,
    textAlign: 'center',
    paddingRight: 0,
  },
  keyHeading: {
    fontWeight: 500,
    textTransform: 'capitalize',
  },
  keyValue: {},
  keyRow: {
    marginBottom: theme.spacing(1),
  },
  attendeesPopper: {
    width: 350,
    background: theme.palette.common.white,
  },
  attendeeList: {
    maxHeight: 300,
    overflow: 'auto',
  },
}));

interface Props {
  companyId: string;
  withRelated: boolean;
}

export const Meetings: React.FC<Props> = ({ companyId, withRelated }) => {
  const [loading, setLoading] = React.useState(false);

  const classes = useStyles();
  const [pageNumber, setPageNumber] = React.useState(0);
  const { pathname, hash } = useLocation();
  const [tableSettings, setTableSettings] =
    useLocalStorageSettings<TableSettings>(
      getTableSettingsStorageKey(pathname, hash),
      { rowsPerPage: 25 },
    );

  const [data, setData] = React.useState<PagedMeetings | undefined>();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );

  const [includeFutureEvents, setIncludeFutureEvents] = React.useState(false);

  const [selectedMeeting, setSelectedMeeting] = React.useState<
    Meeting | undefined
  >();

  const fetchData = React.useCallback(() => {
    setLoading(true);
    fetchMeetings(
      companyId,
      pageNumber + 1,
      tableSettings.rowsPerPage,
      includeFutureEvents,
      withRelated,
    ).then((d) => {
      setLoading(false);
      setData(d);
    });
  }, [
    setLoading,
    setData,
    pageNumber,
    tableSettings.rowsPerPage,
    companyId,
    includeFutureEvents,
    withRelated,
  ]);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  React.useEffect(() => {
    setAnchorEl(null);
    setSelectedMeeting(undefined);
  }, [setAnchorEl, setSelectedMeeting]);

  const columns: PageableTableHeader[] = [
    {
      key: 'date',
      label: '',
      sortable: false,
    },
    {
      key: 'type',
      label: 'Type',
      sortable: false,
    },
    {
      key: 'summary',
      label: '',
      sortable: false,
    },
  ];

  return (
    <Grid container>
      <Grid item xs={12}>
        <Card className={classes.card}>
          <CardHeader
            title="Meetings"
            subheader="Meetings between this client and Expedition42 team members."
            style={{ paddingBottom: 0, marginBottom: 0 }}
          />
          <FormControl margin="normal" style={{ marginLeft: 20 }}>
            <FormControlLabel
              control={
                <Switch
                  checked={includeFutureEvents}
                  onChange={(e, checked) => {
                    setIncludeFutureEvents(checked);
                  }}
                />
              }
              label={'Include future events'}
            />
          </FormControl>
          <Divider />
          <PageableTable
            tableSettings={tableSettings}
            setTableSettings={setTableSettings}
            showSkeletonLoading
            hideColumns={true}
            rows={
              data?.data?.map((d, index) => ({
                key: index.toString(),
                cells: [
                  {
                    key: 'date',
                    display: d.meeting_date && (
                      <DateDisplay date={d.meeting_date} />
                    ),
                    props: {
                      className: classes.dateColumn,
                    },
                  },
                  {
                    key: 'summary',
                    display: (
                      <React.Fragment>
                        <div>
                          <Typography variant="h6">
                            {d.meeting_title}
                          </Typography>
                          {d.organizer && (
                            <Typography variant="body1">
                              Organized by {d.organizer}
                            </Typography>
                          )}
                        </div>
                        {withRelated && (
                          <RelatedCompanyChip
                            companyId={d.company_id}
                            companyName={d.company_title}
                          />
                        )}
                        <Button
                          size="small"
                          color="secondary"
                          onClick={(e) => {
                            setAnchorEl(e.currentTarget);
                            setSelectedMeeting(d);
                          }}
                        >
                          {d.attendee_emails.split(',').length} Attendees
                        </Button>
                      </React.Fragment>
                    ),
                  },
                ],
              })) ?? []
            }
            loading={loading}
            pageNumber={pageNumber}
            rowCount={data?.total_count ?? 0}
            columns={columns}
            onChangePage={setPageNumber}
          />

          <Popper
            open={Boolean(selectedMeeting)}
            anchorEl={anchorEl} // Used to hide menu when clicking an option
          >
            <Card className={classes.attendeesPopper}>
              <CardHeader
                title={selectedMeeting?.meeting_title ?? 'Meeting'}
                action={
                  <IconButton onClick={() => setSelectedMeeting(undefined)}>
                    <Close />
                  </IconButton>
                }
              />
              <List dense className={classes.attendeeList}>
                {selectedMeeting?.attendee_emails.split(',').map((a, index) => {
                  const attendeeData = a.trim().split(' ');
                  const attendeeEmail =
                    attendeeData.length > 1 ? attendeeData[0] : a;
                  const attendeeSecondary =
                    attendeeData.length > 1
                      ? attendeeData
                          .slice(1)
                          .join(' ')
                          .replace('(', '')
                          .replace(')', '')
                      : undefined;

                  return (
                    <ListItem key={index}>
                      <ListItemText
                        primary={attendeeEmail}
                        secondary={attendeeSecondary}
                      />
                    </ListItem>
                  );
                })}
              </List>
            </Card>
          </Popper>
        </Card>
      </Grid>
    </Grid>
  );
};
