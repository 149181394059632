import {
  Badge,
  Button,
  ButtonProps,
  ClickAwayListener,
  Divider,
  Grow,
  IconButton,
  ListItemIcon,
  ListItemText,
  makeStyles,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  SvgIcon,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/MoreVert';
import classNames from 'classnames';
import * as React from 'react';
import GetAppIcon from '@material-ui/icons/GetApp';
import { DelayedLinearProgress } from 'components/DelayedLinearProgress';
import { grey } from '@material-ui/core/colors';
export interface PopperMenuItem {
  label: string;
  subText?: string;
  badgeCount?: number;
  onClick?(): void;
  icon?: typeof SvgIcon;
  loading?: boolean;
  disabled?: boolean;
  iconColor?: string;
  isDivider?: boolean;
}

interface Props {
  disabled?: boolean;
  buttonText?: string;
  buttonColor?: ButtonProps['color'];
  menuItems: PopperMenuItem[];
  showBadge?: boolean;
  dropdownIcon?: React.ReactNode;
  buttonStyle?: React.CSSProperties;
  dropdownStyle?: React.CSSProperties;
  actionText?: string;
  onAction?: (...props: unknown[]) => void;
}

const useStyles = makeStyles((theme) => ({
  popperClose: {
    pointerEvents: 'none',
  },
  popperResponsive: {
    zIndex: 9999,
    [theme.breakpoints.down('md')]: {
      ...theme.mixins.popperResponsive,
    },
  },
  dropdown: {
    ...theme.mixins.dropdown,
    padding: 0,
  },
  dropdownMenu: {
    padding: 0,
    maxHeight: 300,
    overflow: 'auto',
  },
  icon: {
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0.5),
    },
  },
  dropdownIcon: {
    minWidth: 'unset',
    marginRight: theme.spacing(1.5),
  },
  dropdownItem: {
    ...theme.mixins.dropdownItem,
    whiteSpace: 'normal',
    margin: 0,
    display: 'flex',
    '&:hover': {
      backgroundColor: grey[300],
    },
  },
  badgeRoot: {
    position: 'static',
  },
  badgeBadge: {
    top: theme.spacing(2.5),
    right: theme.spacing(2),
  },
  buttonIcon: {
    marginRight: theme.spacing(0.5),
  },
}));

export const PopperDropdownMenu: React.FC<Props> = ({
  buttonText,
  disabled,
  menuItems,
  dropdownIcon,
  showBadge,
  buttonStyle,
  dropdownStyle,
  buttonColor,
  actionText,
  onAction,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | undefined>();
  const open = Boolean(anchorEl);
  const classes = useStyles();
  const shouldShowBadge =
    showBadge !== undefined
      ? showBadge
      : menuItems.filter((i) => i.badgeCount).length > 0;

  return (
    <React.Fragment>
      {buttonText ? (
        <Button
          disabled={disabled}
          onClick={(e) => setAnchorEl(e.currentTarget)}
          style={buttonStyle}
          color={buttonColor}
          startIcon={
            dropdownIcon ?? (
              <MenuIcon fontSize="small" className={classes.buttonIcon} />
            )
          }
        >
          {buttonText}
        </Button>
      ) : (
        <IconButton
          disabled={disabled}
          onClick={(e) => setAnchorEl(e.currentTarget)}
          style={buttonStyle}
        >
          {shouldShowBadge ? (
            <Badge badgeContent={1} variant="dot" color="secondary">
              {dropdownIcon ?? <MenuIcon fontSize="small" />}
            </Badge>
          ) : (
            dropdownIcon ?? <MenuIcon fontSize="small" />
          )}
        </IconButton>
      )}
      <Popper
        placement="bottom-end"
        open={open}
        className={classNames(classes.popperResponsive, {
          [classes.popperClose]: !open,
        })}
        anchorEl={anchorEl}
        transition
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={() => setAnchorEl(undefined)}>
            <Grow {...TransitionProps}>
              <Paper className={classes.dropdown}>
                <MenuList
                  role="menu"
                  className={classes.dropdownMenu}
                  style={dropdownStyle}
                >
                  {menuItems.map(
                    ({ loading, icon: Icon, iconColor, ...m }, i) => {
                      const key = `${m.label}-${i}`;

                      return m.isDivider ? (
                        <Divider key={key} />
                      ) : (
                        <MenuItem
                          key={key}
                          onClick={() => {
                            if (!m.onClick) {
                              return;
                            }
                            m.onClick();
                            setAnchorEl(undefined);
                          }}
                          className={classes.dropdownItem}
                          style={{ maxWidth: 300 }}
                          disabled={m.disabled}
                        >
                          {typeof loading !== 'undefined' && (
                            <DelayedLinearProgress loading={loading} />
                          )}
                          {Icon && (
                            <ListItemIcon
                              className={classes.dropdownIcon}
                              style={{ color: iconColor }}
                            >
                              <Icon />
                            </ListItemIcon>
                          )}

                          {m.badgeCount && m.badgeCount > 0 ? (
                            <Badge
                              color="secondary"
                              classes={{
                                root: classes.badgeRoot,
                                badge: classes.badgeBadge,
                              }}
                              badgeContent={m.badgeCount}
                            >
                              {m.label}
                            </Badge>
                          ) : (
                            <ListItemText
                              primary={m.label}
                              secondary={m.subText}
                            />
                          )}
                        </MenuItem>
                      );
                    },
                  )}
                  {onAction && actionText && (
                    <Button
                      onClick={onAction}
                      color="secondary"
                      component="span"
                    >
                      <GetAppIcon className={classes.icon} />
                      {actionText}
                    </Button>
                  )}
                </MenuList>
              </Paper>
            </Grow>
          </ClickAwayListener>
        )}
      </Popper>
    </React.Fragment>
  );
};
