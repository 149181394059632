import { Avatar, Chip, makeStyles } from '@material-ui/core';
import { stringAvatar } from 'lib/Helper/Avatars';
import * as React from 'react';

interface RelatedCompanyProps {
  companyName?: string;
  companyId?: string;
}

const useStyles = makeStyles((theme) => ({
  companyChip: {
    marginTop: theme.spacing(0.5),
  },
  smallAvatar: {
    fontSize: 10,
    width: '16px',
    height: '16px',
  },
}));

export const RelatedCompanyChip: React.FC<RelatedCompanyProps> = ({
  companyName,
  companyId,
}) => {
  const classes = useStyles();

  if (!companyName) {
    return null;
  }

  return (
    <Chip
      onClick={
        companyId
          ? () => {
              window.open(`/clients/${companyId}`);
            }
          : undefined
      }
      className={classes.companyChip}
      icon={
        <Avatar
          {...stringAvatar(companyName)}
          className={classes.smallAvatar}
        />
      }
      size="small"
      label={companyName}
    />
  );
};
