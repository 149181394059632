import { Box, Button, Typography } from '@material-ui/core';
import { setGlobalState } from 'App.state';
import Cookies from 'js-cookie';
import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export const AuthHandler: React.FC = () => {
  const history = useHistory();

  const { search } = useLocation();
  const queryString = new URLSearchParams(search);
  const jwt = queryString.get('jwt');
  const error = queryString.get('error');
  React.useEffect(() => {
    if (jwt) {
      Cookies.set('intranetAuthUser', jwt, {
        sameSite: 'none',
        secure: true,
      });
      setGlobalState('authUser', jwt);
      if (window.opener) {
        // If we're not the ones that opened the window, then close the window
        window.close();
      }

      history.push('/dashboard');
      return;
    }
    if (!error) {
      history.push('/login');
      return;
    }
  }, [jwt, history, error]);

  return (
    <Box style={{ textAlign: 'center' }}>
      <Typography variant="body1" gutterBottom>
        Error: {error}
      </Typography>
      <Button variant="text" href="/login">
        Back to login
      </Button>
    </Box>
  );
};
