import { getContrastRatio } from '@material-ui/core';

export function stringAvatar(name: string) {
  const cleanName = name
    .replace('-', '')
    .replace('  ', ' ')
    .replace('(', '')
    .replace(')', '');
  const initials =
    cleanName.indexOf(' ') >= 0
      ? cleanName.split(' ')[0][0] + cleanName.split(' ')[1][0]
      : cleanName[0];
  const color = stringToColor(cleanName);
  return {
    style: {
      backgroundColor: color,
      color: getContrastRatio('#fffffff', color) < 6 ? '#000000' : '#ffffff',
    },
    children: initials,
  };
}

export function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}
