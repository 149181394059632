import * as React from 'react';
import {
  Popper,
  ClickAwayListener,
  Grow,
  Paper,
  makeStyles,
  Typography,
} from '@material-ui/core';
import classNames from 'classnames';
import HelpIcon from '@material-ui/icons/Help';
import { grey } from '@material-ui/core/colors';

interface Props
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  buttonText?: string;
  helpText?: string;
}

const useStyles = makeStyles((theme) => ({
  popperClose: {
    pointerEvents: 'none',
  },
  popperResponsive: {
    zIndex: 9999,
    [theme.breakpoints.down('md')]: {
      ...theme.mixins.popperResponsive,
    },
  },
  dropdown: {
    ...theme.mixins.dropdown,
    padding: theme.spacing(2, 0),
    maxWidth: 250,
  },
  helperButton: {
    color: grey[700],
    fontStyle: 'italic',
    textDecoration: 'underline',
    textDecorationStyle: 'dotted',
    cursor: 'pointer',
  },
  helperIcon: {
    color: grey[800],
    cursor: 'pointer',
    marginLeft: '5px',
    '&:hover': {
      color: grey[400],
    },
  },
  smallIcon: {
    fontSize: 15,
  },
}));

export const HelpPopper: React.FC<Props> = ({
  buttonText,
  helpText,
  children,
  style,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | undefined>();
  const open = Boolean(anchorEl);
  const classes = useStyles();

  return (
    <React.Fragment>
      {buttonText ? (
        <span
          className={classes.helperButton}
          onClick={(e) => setAnchorEl(e.currentTarget)}
        >
          {buttonText}
        </span>
      ) : (
        <span
          className={classes.helperIcon}
          onClick={(e) => setAnchorEl(e.currentTarget)}
        >
          <HelpIcon className={classes.smallIcon} fontSize="small" />
        </span>
      )}
      <Popper
        placement="bottom"
        open={open}
        className={classNames(classes.popperResponsive, {
          [classes.popperClose]: !open,
        })}
        anchorEl={anchorEl}
        transition
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={() => setAnchorEl(undefined)}>
            <Grow {...TransitionProps}>
              <Paper className={classes.dropdown} style={style}>
                {helpText ? <Typography>{helpText}</Typography> : children}
              </Paper>
            </Grow>
          </ClickAwayListener>
        )}
      </Popper>
    </React.Fragment>
  );
};
