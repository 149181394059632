import axios from 'axios';
import { Config } from 'config';
import { getAccessToken } from 'lib/Helper/Auth';
import {
  AllocationCompany,
  AllocationIndustry,
  PagedHistory,
  allocationHistory,
  identifyCamResults,
  AssignCamResponse,
  CurrentAllocations,
} from 'lib/Model/ClientAllocation';

export function fetchAllocationIndustries(
  textSearch?: string,
): Promise<{ data: AllocationIndustry[] }> {
  return axios
    .get(`${Config.API_BASE_URL}intranet/client_allocation/industries`, {
      params: {
        industry_title: textSearch?.length ? textSearch : undefined,
      },
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
        'access-token': getAccessToken(),
      },
    })
    .then((response) => {
      return {
        data: response.data.data as AllocationIndustry[],
      };
    });
}

export function fetchAllocationClients(
  textSearch?: string,
): Promise<{ data: AllocationCompany[] }> {
  return axios
    .get(`${Config.API_BASE_URL}intranet/client_allocation/companies`, {
      params: {
        company_title: textSearch?.length ? textSearch : undefined,
      },
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
        'access-token': getAccessToken(),
      },
    })
    .then((response) => {
      return {
        data: response.data.data as AllocationCompany[],
      };
    });
}
export function identifyCam(
  industry?: string,
  headcount?: string,
  product?: string,
  company_id?: string,
): Promise<{ data: identifyCamResults }> {
  return axios
    .get(`${Config.API_BASE_URL}intranet/client_allocation/identify`, {
      params: {
        industry: industry ? industry : undefined,
        headcount: headcount ? headcount : undefined,
        product: product ? product : undefined,
        company_id: company_id ? company_id : undefined,
      },
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
        'access-token': getAccessToken(),
      },
    })
    .then((response) => {
      return {
        data: response.data.data as identifyCamResults,
      };
    });
}

export function assignCam(
  cam_id: string,
  product: string,
  headcount: string,
  industry: string,
  overridden_from_exp42_admin: boolean,
  is_existing_client: boolean,
  company_title: string,
  overridden_from_cas: boolean,
): Promise<AssignCamResponse> {
  return axios
    .post(
      `${Config.API_BASE_URL}intranet/client_allocation/assign`,
      {
        cam_id,
        product,
        headcount,
        industry,
        overridden_from_exp42_admin,
        is_existing_client,
        company_title,
        overridden_from_cas,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'access-token': getAccessToken(),
        },
      },
    )
    .then((response) => response.data);
}

export function fetchAllocationHistory(
  pageNumber: number,
  itemsPerPage: number,
  debouncedTextSearch?: string,
): Promise<PagedHistory> {
  return axios
    .get(`${Config.API_BASE_URL}intranet/client_allocation/history`, {
      params: {
        page: pageNumber,
        page_size: itemsPerPage,
        search_param: debouncedTextSearch?.length
          ? debouncedTextSearch
          : undefined,
      },
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
        'access-token': getAccessToken(),
      },
    })
    .then((response) => {
      return {
        total_count: response.data.total_count,
        data: response.data.data as allocationHistory[],
      };
    });
}

export function fetchCurrentAllocations(): Promise<CurrentAllocations[]> {
  return axios
    .get(
      `${Config.API_BASE_URL}intranet/client_allocation/current_allocations`,
      {
        responseType: 'json',
        headers: {
          'Content-Type': 'application/json',
          'access-token': getAccessToken(),
        },
      },
    )
    .then((response) => response.data.data as CurrentAllocations[]);
}
