import {
  Card,
  List,
  ListItem,
  ListItemText,
  makeStyles,
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import * as React from 'react';

interface MenuItemData {
  label: string;
  value: string;
}

interface Props {
  selectedItemKey: string;
  setSeletedItemKey(key: string): void;
  menuItems: MenuItemData[];
}

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(1, 0),
  },
  topMargin: {
    marginTop: theme.spacing(1),
  },
  bold: {
    fontWeight: 500,
  },
  selectedItem: {
    '&.Mui-selected': {
      backgroundColor: grey[100],
      color: theme.palette.primary.main,
    },
  },
  leftPadded: {
    paddingLeft: theme.spacing(2),
  },
  leftMargined: {
    marginLeft: theme.spacing(2),
  },
  topPadded: {
    paddingTop: theme.spacing(2),
  },
}));

export const VerticalMenu: React.FC<Props> = ({
  menuItems,
  selectedItemKey,
  setSeletedItemKey,
}) => {
  const classes = useStyles();
  return (
    <Card className={classes.container}>
      {menuItems.length > 0 && <List>{menuItems.map(pageToListItem)}</List>}
    </Card>
  );

  function pageToListItem(page: MenuItemData) {
    return (
      <ListItem
        key={page.value}
        button
        selected={selectedItemKey === page.value}
        onClick={() => setSeletedItemKey(page.value)}
        className={classes.selectedItem}
      >
        <ListItemText primary={page.label} />
      </ListItem>
    );
  }
};
