import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  FormControl,
  FormLabel,
  Typography,
  makeStyles,
  DialogTitle,
  Divider,
  Chip,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { Deal } from 'lib/Model/Deal';
import { format, isValid, parseISO } from 'date-fns';
import { DateFormat } from 'config';
import { toMonetary } from 'lib';

interface InfoDialogProps {
  open: boolean;
  deal: Deal[];
  loading?: boolean;
  onClose: () => void;
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1, 0),
  },
  metricContainer: {
    backgroundColor: '#eee',
    padding: theme.spacing(2.5, 0, 2, 0),
    textAlign: 'center',
  },
  metricFont: {
    fontSize: theme.typography.fontSize * 2,
    fontWeight: theme.typography.fontWeightMedium,
  },
  labelIcon: {
    verticalAlign: 'middle',
    marginRight: '8px',
  },
}));

const InfoDialog: React.FC<InfoDialogProps> = ({
  open,
  deal,
  loading,
  onClose,
}) => {
  const classes = useStyles();
  const dealDetails = !deal ? null : deal[0];

  return (
    <Dialog open={open} maxWidth="lg" fullWidth onClose={onClose}>
      {loading || !dealDetails ? (
        <DialogContent style={{ paddingBottom: 20 }}>
          <Skeleton variant="rect" style={{ height: 200 }} />
        </DialogContent>
      ) : (
        <>
          <DialogTitle
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <Typography variant="h6">{dealDetails.deal_name}</Typography>
              <Typography variant="body2">
                Deal ID: {dealDetails.id}
              </Typography>{' '}
            </div>
            <Typography
              variant="body1"
              style={{
                color: ['Won'].includes(dealDetails.stage)
                  ? 'green'
                  : ['Lost', 'Unqualified'].includes(dealDetails.stage)
                  ? 'red'
                  : 'teal',
                textTransform: 'uppercase',
                fontWeight: 500,
              }}
            >
              {dealDetails.stage.toUpperCase()}
            </Typography>
          </DialogTitle>

          <DialogContent>
            <Divider />
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={12} sm={3}>
                <FormControl className={classes.formControl} fullWidth>
                  <FormLabel className={classes.formControl}>
                    Created On
                  </FormLabel>
                  {dealDetails.created_at
                    ? isValid(parseISO(dealDetails.created_at))
                      ? format(
                          parseISO(dealDetails.created_at),
                          DateFormat.EXCEL,
                        )
                      : 'N/A'
                    : 'N/A'}{' '}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl className={classes.formControl} fullWidth>
                  <FormLabel className={classes.formControl}>Owner</FormLabel>
                  <Typography>{dealDetails.owner || 'N/A'}</Typography>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl className={classes.formControl} fullWidth>
                  <FormLabel className={classes.formControl}>
                    Last Stage Change Date
                  </FormLabel>
                  {dealDetails.created_at
                    ? isValid(parseISO(dealDetails.last_stage_change_date))
                      ? format(
                          parseISO(dealDetails.last_stage_change_date),
                          DateFormat.EXCEL,
                        )
                      : 'N/A'
                    : 'N/A'}{' '}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl className={classes.formControl} fullWidth>
                  <FormLabel className={classes.formControl}>
                    Estimated Close Date
                  </FormLabel>
                  {dealDetails.estimated_close_date
                    ? isValid(parseISO(dealDetails.estimated_close_date))
                      ? format(
                          parseISO(dealDetails.estimated_close_date),
                          DateFormat.EXCEL,
                        )
                      : 'N/A'
                    : 'N/A'}
                </FormControl>
              </Grid>
            </Grid>
            <Divider />
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={12} sm={3}>
                <FormControl className={classes.formControl} fullWidth>
                  <FormLabel className={classes.formControl}>Source</FormLabel>
                  <Typography>{dealDetails.deal_source || 'N/A'}</Typography>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl className={classes.formControl}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: '5px',
                    }}
                  >
                    <FormLabel className={classes.formControl}>
                      Deal Value
                    </FormLabel>
                  </div>
                  <Typography>
                    {toMonetary(dealDetails.deal_value || 'N/A')}
                  </Typography>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={3}>
                <FormControl className={classes.formControl}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: '5px',
                    }}
                  >
                    <FormLabel className={classes.formControl}>
                      Annuity Value
                    </FormLabel>
                  </div>
                  <Typography>
                    {toMonetary(dealDetails.annuity_value || 'N/A')}
                  </Typography>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl className={classes.formControl}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: '5px',
                    }}
                  >
                    <FormLabel className={classes.formControl}>
                      Non-Annuity Value
                    </FormLabel>
                  </div>
                  <Typography>
                    {toMonetary(dealDetails.deal_non_annuity_value || 'N/A')}
                  </Typography>
                </FormControl>
              </Grid>
            </Grid>
            <Divider />
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={12} sm={3}>
                <FormControl className={classes.formControl}>
                  <FormLabel className={classes.formControl}>
                    Referrer
                  </FormLabel>
                  <Typography>{dealDetails.referrer || 'N/A'}</Typography>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl className={classes.formControl}>
                  <FormLabel className={classes.formControl}>
                    Deal Service
                  </FormLabel>
                  {dealDetails.deal_service ? (
                    <Chip
                      label={dealDetails.deal_service}
                      style={{ margin: 2 }}
                      size="small"
                    />
                  ) : (
                    <Typography>N/A</Typography>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={3}>
                <FormControl className={classes.formControl} fullWidth>
                  <FormLabel className={classes.formControl}>
                    Products / Vacancies
                  </FormLabel>
                  <div>
                    <React.Fragment>
                      {dealDetails.vacancies?.length
                        ? dealDetails.vacancies.map((v) => (
                            <Chip
                              style={{ margin: 2 }}
                              label={v}
                              size="small"
                            />
                          ))
                        : dealDetails.deal_products &&
                          dealDetails.deal_products?.map((d) => (
                            <Chip
                              style={{ margin: 2 }}
                              label={d}
                              size="small"
                            />
                          ))}
                    </React.Fragment>
                  </div>
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            {dealDetails.deal_url && (
              <Button
                color="secondary"
                onClick={() => window.open(dealDetails.deal_url, '_blank')}
              >
                Open on Zendesk
              </Button>
            )}
            <Button onClick={onClose} color="default">
              Close
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};
export default InfoDialog;
