import { makeStyles, Typography } from '@material-ui/core';
import * as React from 'react';
import brandLogo from '../../../assets/exp42-logo.png';

interface Props
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  linkHref?: string;
  logoSrc: string;
  text: React.ReactNode;
  logoOnly?: boolean;
}

const useStyles = makeStyles((theme) => ({
  logo: {
    position: 'relative',
    padding: theme.spacing(2, 3),
    zIndex: 4,
    marginTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
  },
  logoLink: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 18,
    textDecoration: 'none',
    color: 'inherit',
  },
  logoImage: {
    width: 35,
    marginRight: theme.spacing(1),
  },
  logoOnly: {
    height: 30,
    maxWidth: '100%',
  },
  logoText: {
    fontSize: 18,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

export const SidebarLogo: React.FC<Props> = ({
  linkHref,
  logoSrc,
  text,
  logoOnly,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <div className={classes.logo} {...rest}>
      {linkHref ? (
        <a
          href={linkHref}
          target="_blank"
          rel="noopener noreferrer"
          className={classes.logoLink}
          title={typeof text === 'string' ? text : undefined}
        >
          {getLogo()}
        </a>
      ) : (
        getLogo()
      )}
    </div>
  );

  function getLogo() {
    return (
      <React.Fragment>
        <img
          src={logoSrc || brandLogo}
          alt="logo"
          className={logoOnly ? classes.logoOnly : classes.logoImage}
        />
        {!logoOnly && (
          <Typography
            color="inherit"
            component="span"
            className={classes.logoText}
          >
            {text}
          </Typography>
        )}
      </React.Fragment>
    );
  }
};
