import axios from 'axios';
import { Config } from 'config';
import Cookies from 'js-cookie';
import jwt_decode from 'jwt-decode';

export function getAccessToken() {
  const user = Cookies.get('intranetAuthUser');
  if (!user) {
    return null;
  }
  return user;
  // return 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJtYXJrLmNhbWlsbGVyaWdhbWJpbkBleHA0Mi5jb20iLCJuYW1lIjoiTWFyayBDYW1pbGxlcmkgR2FtYmluIiwiZW1haWwiOiJtYXJrLmNhbWlsbGVyaWdhbWJpbkBleHA0Mi5jb20iLCJleHAiOjE2Nzk0OTk5MTB9.2uoB5F6BVKBJs5anja6CEWVieXX08rff-x2u5pQBuhY';
}

export function getUserData() {
  const token = getAccessToken();
  if (!token) {
    return null;
  }

  const userData = jwt_decode(token) as {
    sub: string;
    name: string;
    permissions?: string[];
    group: string;
  };
  if (!userData.name) {
    return null;
  }
  return {
    ...userData,
    token,
  };
}

export function triggerGoogleLogin() {
  const popup = window.open(
    `${Config.API_BASE_URL}`,
    'popup',
    'popup=true,height=600,width=400',
  );
  const checkPopup = setInterval(() => {
    if (!popup || !popup.closed) return;
    popup.close();
    clearInterval(checkPopup);
    window.location.reload();
  }, 1000);
}

export function configureApiErrorHandler() {
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error: any) => {
      if (error.response?.status === 401) {
        triggerGoogleLogin();
      }
      if (error.response?.data?.detail) {
        return Promise.reject(new Error(error.response.data.detail));
      }
      return Promise.reject(error);
    },
  );
}
