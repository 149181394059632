import {
  Box,
  CircularProgress,
  CircularProgressProps,
  makeStyles,
  Typography,
} from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(() => ({
  jobStatusLabel: {
    textTransform: 'uppercase',
  },
}));

interface Props {
  label: React.ReactNode;
  progressColor?: string;
  style?: React.CSSProperties;
  circularProgressProps?: CircularProgressProps;
}
export const LabelledCircularProgress: React.FC<Props> = ({
  circularProgressProps,
  label,
  style,
  progressColor,
}) => {
  const classes = useStyles();

  return (
    <div>
      <Box
        position="relative"
        display="flex"
        alignItems="center"
        justifyContent="center"
        style={style}
      >
        <CircularProgress
          {...circularProgressProps}
          style={{ color: progressColor }}
        />
        <Box
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
          width="100%"
          height="100%"
        >
          <Typography color="textSecondary" className={classes.jobStatusLabel}>
            {label}
          </Typography>
        </Box>
      </Box>
    </div>
  );
};
