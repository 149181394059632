import axios from 'axios';
import { Config } from 'config';
import { getAccessToken } from 'lib/Helper/Auth';
import { Deal, DealAudit, PagedDeal } from 'lib/Model/Deal';

export function fetchDeals(
  companyId: string,
  pageNumber: number,
  itemsPerPage: number,
  hideClosedDeals: boolean,
  withRelated: boolean,
): Promise<PagedDeal> {
  return axios
    .get(`${Config.API_BASE_URL}intranet/companies/${companyId}/deals`, {
      params: {
        page: pageNumber,
        page_size: itemsPerPage,
        hide_closed_deals: hideClosedDeals,
        show_related: withRelated,
      },
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
        'access-token': getAccessToken(),
      },
    })
    .then((response) => {
      const data = response.data.data as any[];
      const dealData: Deal[] = data.map((d) => {
        return parseDealObject(d);
      });

      return {
        total_count: response.data.total_count,
        data: dealData,
      };
    });
}

export function fetchDealDetails(id: string): Promise<Deal[]> {
  return axios
    .get(`${Config.API_BASE_URL}intranet/companies/deals/${id}`, {
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
        'access-token': getAccessToken(),
      },
    })
    .then((response) => {
      const data = response.data.data as any[];
      const dealData: Deal[] = data.map((d) => {
        return parseDealObject(d);
      });
      return dealData;
    });
}

function parseDealObject(d: any) {
  let dealProducts: string[] = [];
  let vacancies: string[] = [];

  try {
    if (d.deal_products) {
      dealProducts = JSON.parse(d.deal_products.replaceAll("'", '"'));
    }
  } catch (e) {}

  try {
    if (typeof d.vacancies === 'string') {
      vacancies = d.vacancies.replace(/\n/g, ',').split(',') as string[];
    }
  } catch (e) {}

  return {
    ...d,
    deal_products: dealProducts,
    vacancies: vacancies,
  } as Deal;
}

export function fetchDealAudit(deal_id: string): Promise<DealAudit[]> {
  return axios
    .get(`${Config.API_BASE_URL}intranet/companies/deals/${deal_id}/history`, {
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
        'access-token': getAccessToken(),
      },
    })
    .then((response) => {
      return response.data.data as DealAudit[];
    });
}
