import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  FormControl,
  FormLabel,
  Typography,
  makeStyles,
  DialogTitle,
  Divider,
  Chip,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { Job } from 'lib/Model/Job';
import {
  RemoveRedEye,
  TrendingUp,
  Public,
  PersonAdd,
  HighlightOff,
  CheckCircleOutline,
  LocationOn,
  TouchApp,
  People,
} from '@material-ui/icons';
import { format, isValid, parseISO } from 'date-fns';
import { DateFormat } from 'config';
import { HelpPopper } from 'components';

interface InfoDialogProps {
  open: boolean;
  job: Job[];
  loading?: boolean;
  onClose: () => void;
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1, 0),
  },
  metricContainer: {
    backgroundColor: '#eee',
    padding: theme.spacing(2.5, 0, 2, 0),
    textAlign: 'center',
  },
  metricFont: {
    fontSize: theme.typography.fontSize * 2,
    fontWeight: theme.typography.fontWeightMedium,
  },
  labelIcon: {
    verticalAlign: 'middle',
    marginRight: '8px',
  },
}));

const InfoDialog: React.FC<InfoDialogProps> = ({
  open,
  job,
  loading,
  onClose,
}) => {
  const classes = useStyles();
  const jobDetails = !job ? null : job[0];
  const calculateTotalApplications = () => {
    if (!jobDetails) {
      return 'N/A';
    } // needed this for some reason

    if (!jobDetails.rejection_types) {
      // default to 0 just in case
      return (
        (jobDetails.number_of_eu ?? 0) +
        (jobDetails.number_of_mt ?? 0) +
        (jobDetails.number_of_tcn ?? 0)
      );
    }

    // get a list of unique (just in case theres duplicate) rejection types for the job
    const rejectionTypes = new Set(
      jobDetails.rejection_types.split(',').map((type) => type.trim()),
    );
    let totalApplications = 0;

    // logic according to rejectino types
    // basically if NOT has this, add it, else dont
    if (!rejectionTypes.has('Countries outside the EU')) {
      totalApplications += jobDetails.number_of_tcn ?? 0;
    }
    if (!rejectionTypes.has('European Union (does not include Malta)')) {
      totalApplications += jobDetails.number_of_eu ?? 0;
    }
    if (!rejectionTypes.has('Malta and Gozo')) {
      totalApplications += jobDetails.number_of_mt ?? 0;
    }

    return totalApplications;
  };

  return (
    <Dialog open={open} maxWidth="lg" fullWidth onClose={onClose}>
      {loading || !jobDetails ? (
        <DialogContent style={{ paddingBottom: 20 }}>
          <Skeleton variant="rect" style={{ height: 200 }} />
        </DialogContent>
      ) : (
        <>
          <DialogTitle
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <Typography variant="h6">{jobDetails.title}</Typography>
              {jobDetails.application_method && (
                <Typography variant="body2">
                  Ref: {jobDetails.reference}
                </Typography>
              )}
            </div>
            <Typography
              variant="body1"
              style={{
                color: ['Successful', 'Appointed', 'Published'].includes(
                  jobDetails.status,
                )
                  ? 'green'
                  : jobDetails.is_closed
                  ? 'red'
                  : 'teal',
                textTransform: 'uppercase',
                fontWeight: 500,
              }}
            >
              {jobDetails.status.toUpperCase()}
            </Typography>
          </DialogTitle>

          <DialogContent>
            <Divider />
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={12} sm={4}>
                <FormControl className={classes.formControl} fullWidth>
                  <FormLabel className={classes.formControl}>
                    Created On
                  </FormLabel>
                  {jobDetails.created_on
                    ? isValid(parseISO(jobDetails.created_on))
                      ? format(
                          parseISO(jobDetails.created_on),
                          DateFormat.EXCEL,
                        )
                      : 'N/A'
                    : 'N/A'}{' '}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl className={classes.formControl} fullWidth>
                  <FormLabel className={classes.formControl}>
                    {jobDetails.resource_type === 'job_listing' ||
                    jobDetails.resource_type === 'job_listing_rss'
                      ? 'Moderated On'
                      : 'Salary Commission Rate'}
                  </FormLabel>
                  {jobDetails.resource_type === 'job_listing' ||
                  jobDetails.resource_type === 'job_listing_rss'
                    ? jobDetails.moderated_on &&
                      isValid(parseISO(jobDetails.moderated_on))
                      ? format(
                          parseISO(jobDetails.moderated_on),
                          DateFormat.EXCEL,
                        )
                      : 'N/A'
                    : jobDetails.salary_commission_rate
                    ? (jobDetails.salary_commission_rate * 100).toFixed(0) + '%'
                    : 'N/A'}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl className={classes.formControl} fullWidth>
                  <FormLabel className={classes.formControl}>
                    Closing Date
                  </FormLabel>
                  {jobDetails.closing_date
                    ? isValid(parseISO(jobDetails.closing_date))
                      ? format(
                          parseISO(jobDetails.closing_date),
                          DateFormat.EXCEL,
                        )
                      : 'N/A'
                    : 'N/A'}
                </FormControl>
              </Grid>
            </Grid>
            <Divider />
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={12} sm={4}>
                <FormControl className={classes.formControl} fullWidth>
                  <FormLabel className={classes.formControl}>Leader</FormLabel>
                  <Typography>{jobDetails.leader || 'N/A'}</Typography>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl className={classes.formControl}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: '5px',
                    }}
                  >
                    <FormLabel className={classes.formControl}>
                      Salary:
                    </FormLabel>
                    {jobDetails.disclose_salary ? (
                      <>
                        <CheckCircleOutline
                          style={{ color: 'green', marginLeft: '5px' }}
                        />
                        <Typography
                          style={{ color: 'green', marginLeft: '5px' }}
                        >
                          Disclosed
                        </Typography>
                      </>
                    ) : (
                      <>
                        <HighlightOff
                          style={{ color: 'red', marginLeft: '5px' }}
                        />
                        <Typography style={{ color: 'red', marginLeft: '5px' }}>
                          Not Disclosed
                        </Typography>
                      </>
                    )}
                  </div>
                  <Typography>{jobDetails.salary || 'N/A'}</Typography>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={4}>
                <FormControl className={classes.formControl} fullWidth>
                  <FormLabel className={classes.formControl}>
                    Rejection Criteria
                  </FormLabel>
                  <div>
                    {jobDetails.rejection_types ? (
                      [
                        ...new Set(
                          jobDetails.rejection_types
                            .split(',')
                            .map((type) => type.trim()),
                        ),
                      ].map((rejectionType) => (
                        <Chip
                          key={rejectionType}
                          style={{ margin: 2 }}
                          label={rejectionType}
                          size="small"
                        />
                      ))
                    ) : (
                      <Chip
                        style={{ margin: 2 }}
                        label="Accepting all candidates"
                        size="small"
                      />
                    )}
                  </div>
                </FormControl>
              </Grid>
            </Grid>
            <Divider />
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={12} sm={4}>
                <FormControl className={classes.formControl}>
                  <FormLabel className={classes.formControl}>
                    Application Method
                  </FormLabel>
                  {jobDetails.application_method ? (
                    jobDetails.application_method
                  ) : (
                    <Typography>N/A</Typography>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl className={classes.formControl}>
                  <FormLabel className={classes.formControl}>
                    Category
                  </FormLabel>
                  {jobDetails.primary_job_focus ? (
                    <Chip
                      label={jobDetails.primary_job_focus}
                      style={{ margin: 2 }}
                      size="small"
                    />
                  ) : (
                    <Typography>N/A</Typography>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={4}>
                <FormControl className={classes.formControl} fullWidth>
                  <FormLabel className={classes.formControl}>
                    Specialisations
                  </FormLabel>
                  <div>
                    {jobDetails.subprimary_job_focuses ? (
                      jobDetails.subprimary_job_focuses
                        .split(',')
                        .map((focus, index) => (
                          <Chip
                            key={index}
                            style={{ margin: 2 }}
                            label={focus.trim()}
                            size="small"
                          />
                        ))
                    ) : (
                      <Typography>N/A</Typography>
                    )}
                  </div>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container alignItems="center" spacing={2}>
              {jobDetails.resource_type === 'vacancy' && (
                <Grid item md={1}></Grid>
              )}

              <Grid item xs={12} sm={2} md={2}>
                <FormControl className={classes.metricContainer} fullWidth>
                  <FormLabel className={classes.formControl}>
                    <PersonAdd className={classes.labelIcon} />
                    Total Applications
                    <HelpPopper>
                      <Typography
                        variant="body2"
                        gutterBottom
                        style={{ padding: '5px' }}
                      >
                        The total applications is the sum of applications from
                        regions that are NOT being excluded for the role.
                      </Typography>
                    </HelpPopper>
                  </FormLabel>
                  <Typography className={classes.metricFont}>
                    {calculateTotalApplications()}
                  </Typography>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={2} md={2}>
                <FormControl className={classes.metricContainer} fullWidth>
                  <FormLabel className={classes.formControl}>
                    <LocationOn className={classes.labelIcon} />
                    Local Applications
                  </FormLabel>
                  <Typography className={classes.metricFont}>
                    {jobDetails.number_of_mt || 'N/A'}
                  </Typography>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={2} md={2}>
                <FormControl className={classes.metricContainer} fullWidth>
                  <FormLabel className={classes.formControl}>
                    <People className={classes.labelIcon} />
                    EU Applications
                  </FormLabel>
                  <Typography className={classes.metricFont}>
                    {jobDetails.number_of_eu || 'N/A'}
                  </Typography>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={2} md={2}>
                <FormControl className={classes.metricContainer} fullWidth>
                  <FormLabel className={classes.formControl}>
                    <Public className={classes.labelIcon} />
                    Non-EU Applications
                  </FormLabel>
                  <Typography className={classes.metricFont}>
                    {jobDetails.number_of_tcn || 'N/A'}
                  </Typography>
                </FormControl>
              </Grid>

              {jobDetails.resource_type === 'vacancy' && (
                <Grid item xs={12} sm={2} md={2}>
                  <FormControl className={classes.metricContainer} fullWidth>
                    <FormLabel className={classes.formControl}>
                      <TrendingUp className={classes.labelIcon} />
                      Expected Success
                      <HelpPopper>
                        <Typography
                          variant="body2"
                          gutterBottom
                          style={{ padding: '5px' }}
                        >
                          Approximated using the number of days a vacancy has
                          been open, the number of quality applications and
                          whether it is an executive role.
                        </Typography>
                      </HelpPopper>
                    </FormLabel>
                    <Typography className={classes.metricFont}>
                      {jobDetails.expected_success
                        ? (jobDetails.expected_success * 100).toFixed(2) + '%'
                        : 'N/A'}
                    </Typography>
                  </FormControl>
                </Grid>
              )}

              {jobDetails.resource_type === 'vacancy' && (
                <Grid item md={1}></Grid>
              )}

              {(jobDetails.resource_type === 'job_listing' ||
                jobDetails.resource_type === 'job_listing_rss') && (
                <>
                  <Grid item xs={12} sm={2} md={2}>
                    <FormControl className={classes.metricContainer} fullWidth>
                      <FormLabel className={classes.formControl}>
                        <RemoveRedEye className={classes.labelIcon} />
                        Views
                      </FormLabel>
                      <Typography className={classes.metricFont}>
                        {jobDetails.views || 'N/A'}
                      </Typography>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={2} md={2}>
                    <FormControl className={classes.metricContainer} fullWidth>
                      <FormLabel className={classes.formControl}>
                        <TouchApp className={classes.labelIcon} />
                        Apply Clicks
                      </FormLabel>
                      <Typography className={classes.metricFont}>
                        {jobDetails.number_of_clicks || 'N/A'}
                      </Typography>
                    </FormControl>
                  </Grid>
                </>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            {jobDetails.resource_url && (
              <Button
                color="secondary"
                onClick={() => window.open(jobDetails.resource_url, '_blank')}
              >
                {jobDetails.resource_type === 'job_listing'
                  ? 'Open Client Profile'
                  : 'Open in ATS'}
              </Button>
            )}
            <Button onClick={onClose} color="default">
              Close
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};
export default InfoDialog;
