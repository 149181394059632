import { makeStyles, Theme } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

export const useStyles = makeStyles<Theme>(
  ({
    lightMode,
    drawerWidth,
    spacing,
    palette: { primary, common, getContrastText },
  }) => {
    let sidebarBgColor: string = grey[900];
    let scrollbarColor: string = grey[600];

    if (lightMode) {
      sidebarBgColor = grey[100];
      scrollbarColor = grey[300];
    }

    return {
      selectedMenuCategory: {
        '&.Mui-selected, &.Mui-selected:hover': {
          backgroundColor: `${primary.main}!important`,
          color: `${primary.contrastText}!important`,
        },
      },
      rootMenuCategory: {
        backgroundColor: '#333333',
        color: `${common.white}!important`,
      },
      drawer: {
        color: getContrastText(sidebarBgColor),
        width: drawerWidth + 1, // +1 because of border applied by .MuiDrawer-paperAnchorDockedLeft
      },
      background: {
        position: 'absolute',
        zIndex: 1,
        height: '100%',
        width: '100%',
        display: 'block',
        top: '0',
        left: '0',
        backgroundSize: 'cover',
        backgroundColor: sidebarBgColor,
        backgroundPosition: 'center center',
      },
      loadingWrapper: {
        display: 'flex',
        height: '100%',
        overflow: 'hidden',
        alignItems: 'center',
        justifyContent: 'center',
      },
      list: {
        overflow: 'auto',
        flex: 1,
        width: drawerWidth,
        height: '100%',
        zIndex: 4,
        '&::-webkit-scrollbar': {
          width: spacing(1),
        },
        '&::-webkit-scrollbar-track': {
          background: 'none',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: scrollbarColor,
          borderRadius: spacing(1),
        },
      },
      skeleton: {
        margin: spacing(0.5, 1),
      },
      item: {
        transition: 'all 150ms linear',
        margin: spacing(0.5, 1),
        padding: spacing(1, 2),
        borderRadius: 3,
        width: 'auto',
        color: lightMode ? grey[700] : common.white,
        '&:hover, &:focus': {
          color: getContrastText(lightMode ? primary.light : primary.dark),
          backgroundColor: lightMode ? primary.light : primary.dark,
        },
        '&>div>p': {
          fontSize: '90%',
        },
      },
      itemActive: {
        color: primary.contrastText,
        backgroundColor: primary.main,
        boxShadow: 'none',
        '&:hover, &:focus': {
          color: primary.contrastText,
          backgroundColor: primary.main,
        },
      },
      itemIcon: {
        color: 'inherit',
      },
      itemText: {
        color: 'inherit',
      },
      toggleButton: {
        '&:hover': {
          backgroundColor: lightMode ? common.black : undefined,
        },
      },
    };
  },
);
