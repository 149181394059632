import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  makeStyles,
} from '@material-ui/core';
import { green, orange, red } from '@material-ui/core/colors';
import { Edit, Save } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { addTag, fetchTags, updateTag } from 'lib/Api/Nps';
import { NpsTag } from 'lib/Model/Nps';
import * as React from 'react';

const useStyles = makeStyles((theme) => ({
  parityButton: {
    height: '100%',
  },
  actionsColumn: {
    textAlign: 'right',
  },
}));

interface Props {
  open: boolean;
  onClose(): void;
}

export const TagsDialog: React.FC<Props> = ({ open, onClose }) => {
  const [loadingTags, setLoadingTags] = React.useState(false);

  const classes = useStyles();
  const [tags, setTags] = React.useState<NpsTag[]>([]);
  const [editingTags, setEditingTags] = React.useState<NpsTag[]>([]);

  const fetchTagsData = React.useCallback(() => {
    setLoadingTags(true);
    fetchTags().then((d) => {
      setLoadingTags(false);
      setTags(d);
    });
  }, [setLoadingTags, setTags]);

  React.useEffect(() => {
    if (!open) {
      return;
    }
    fetchTagsData();
  }, [open, fetchTagsData]);

  return (
    <Dialog
      open={open}
      maxWidth="lg"
      fullWidth
      onClose={() => onClose && onClose()}
    >
      <DialogTitle>Tag Manager</DialogTitle>
      <DialogContent>
        {loadingTags ? (
          <Skeleton variant="rect" style={{ height: 400 }} />
        ) : (
          <React.Fragment>
            {tags.map((t, index) => {
              const isNew = t.id < 0;
              const isEditing = editingTags.some((f) => f.id === t.id) || isNew;
              return (
                <Grid container id={`t_${index}`}>
                  <Grid item xs={9}>
                    <TextField
                      onChange={(e) => {
                        const tagToUpdate = tags.findIndex(
                          (f) => f.id === t.id,
                        );
                        const newValue = e.target.value;
                        setTags((existingTags) => {
                          const newTags = existingTags.slice();
                          newTags[tagToUpdate].tag_name = newValue;
                          return newTags;
                        });
                      }}
                      value={t.tag_name}
                      variant="outlined"
                      fullWidth
                      margin="none"
                      disabled={!isEditing}
                      autoFocus={isNew}
                    ></TextField>
                  </Grid>
                  <Grid item xs={2}>
                    <Button
                      className={classes.parityButton}
                      disabled={!isEditing}
                      variant="outlined"
                      fullWidth
                      style={{
                        borderColor: !isEditing ? '#fff' : undefined,
                        color:
                          t.parity === 'positive'
                            ? green[500]
                            : t.parity === 'negative'
                            ? red[500]
                            : t.parity === 'other'
                            ? orange[500]
                            : undefined,
                      }}
                      onClick={() => {
                        const tagToUpdate = tags.findIndex(
                          (f) => f.id === t.id,
                        );
                        const newParity =
                          t.parity === 'negative'
                            ? 'positive'
                            : t.parity === 'positive'
                            ? 'Other'
                            : 'negative';
                        setTags((existingTags) => {
                          const newTags = existingTags.slice();
                          newTags[tagToUpdate].parity = newParity;
                          return newTags;
                        });
                      }}
                    >
                      {t.parity}
                    </Button>
                  </Grid>
                  <Grid item xs={1} className={classes.actionsColumn}>
                    {!isEditing && (
                      <IconButton
                        onClick={() => {
                          setEditingTags((e) => [...e, t]);
                        }}
                      >
                        <Edit />
                      </IconButton>
                    )}
                    {isEditing && (
                      <IconButton
                        onClick={async () => {
                          setLoadingTags(true);
                          if (isNew) {
                            const insertedTag = await addTag(t);
                            setLoadingTags(false);
                            // Update the id
                            const tagToUpdate = tags.findIndex(
                              (f) => f.id === t.id,
                            );
                            setTags((existingTags) => {
                              const newTags = existingTags.slice();
                              newTags[tagToUpdate].id = insertedTag.id;
                              return newTags;
                            });
                            return;
                          }
                          await updateTag(t);
                          setLoadingTags(false);
                          setEditingTags((e) => {
                            // TODO: send tag to api
                            const indexToRemove = e.findIndex(
                              (f) => f.id === t.id,
                            );
                            const newTags = e.slice();
                            newTags.splice(indexToRemove, 1);
                            return newTags;
                          });
                        }}
                      >
                        <Save color="primary" />
                      </IconButton>
                    )}
                  </Grid>
                </Grid>
              );
            })}
          </React.Fragment>
        )}
      </DialogContent>

      <DialogActions>
        <Button color="primary" onClick={() => onAddTag()}>
          Add a new tag
        </Button>
        {onClose && (
          <Button
            onClick={() => onClose()}
            color="default"
            disabled={loadingTags}
          >
            Close
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );

  function onAddTag() {
    const newCount = (tags.filter((t) => t.id < 0)?.length ?? 0) + 1;
    setTags((t) => [
      ...t,
      {
        id: newCount * -1,
        tag_name: '',
        parity: 'negative',
      },
    ]);
  }
};
