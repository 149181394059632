import Cookies from 'js-cookie';
import { createGlobalState } from 'react-hooks-global-state';

interface Props {
  authUser: string | null;
  popperEl: HTMLDivElement | null;
}

export const {
  GlobalStateProvider,
  useGlobalState,
  getGlobalState,
  setGlobalState,
} = createGlobalState<Props>({
  authUser: Cookies.get('intranetAuthUser') || null,
  popperEl: null,
});
