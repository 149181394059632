import { makeStyles, Tooltip } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { format, parseISO } from 'date-fns';
import * as React from 'react';
import { DateFormat, DateTimeFormat } from '../../config';

interface DateDisplayProps {
  date: string | Date;
}

const useStyles = makeStyles((theme) => ({
  dateContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',
    textAlign: 'center',
    borderRadius: 5,
    overflow: 'hidden',
    maxWidth: 50,
    margin: '0px auto',
  },
  dateDay: {
    display: 'block',
    padding: theme.spacing(0.25, 0, 0, 0),
    backgroundColor: '#eee',
    color: '#555',
    fontSize: 20,
  },
  dateMonth: {
    display: 'block',
    backgroundColor: grey[600],
    color: theme.palette.getContrastText(grey[600]),
    textTransform: 'uppercase',
    padding: theme.spacing(0.5, 0.2, 0.2, 0.2),
    fontSize: 10,
    fontWeight: 500,
  },
  dateYear: {
    display: 'block',
    textTransform: 'uppercase',
    padding: theme.spacing(0.5, 0.2, 0.2, 0.2),
    fontSize: 10,
    fontWeight: 500,
    textAlign: 'center',
  },
}));

export const DateDisplay: React.FC<DateDisplayProps> = ({ date }) => {
  const classes = useStyles();
  const dateObject = typeof date === 'string' ? parseISO(date) : date;
  return (
    <Tooltip title={format(dateObject, DateTimeFormat.LONG_FRIENDLY)}>
      <div className={classes.dateContainer}>
        <span className={classes.dateDay}>
          {format(dateObject, DateFormat.DAY_ONLY)}
        </span>
        <span className={classes.dateMonth}>
          {format(dateObject, DateFormat.MONTH_NAME_SHORT)}
        </span>
        <span className={classes.dateYear}>
          {format(dateObject, DateFormat.YEAR_ONLY)}
        </span>
      </div>
    </Tooltip>
  );
};
