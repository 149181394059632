import {
  AppBar,
  Button,
  Card,
  CardHeader,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  makeStyles,
  Switch,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import classNames from 'classnames';
import { DelayedLinearProgress, PageableTable } from 'components';
import { DateDisplay } from 'components/DateDisplay/DateDisplay';
import { PageableTableHeader } from 'components/PageableTable/components';
import { isValid, parseISO } from 'date-fns';
import {
  useLocalStorageSettings,
  TableSettings,
  getTableSettingsStorageKey,
} from 'hooks';
import { fetchTicketCounts, fetchTickets } from 'lib/Api/Tickets';
import { PagedTicket, TicketCounts } from 'lib/Model/Ticket';
import * as React from 'react';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: theme.spacing(2),
  },
  clickable: {
    cursor: 'pointer',
    color: theme.palette.secondary.main,
    textAlign: 'left',
  },
  boldTitle: {
    fontWeight: 500,
    padding: 0,
    clear: 'both',
    display: 'block',
  },
}));

interface Props {
  companyId: string;
  withRelated: boolean;
}

export const Tickets: React.FC<Props> = ({ companyId, withRelated }) => {
  const [loading, setLoading] = React.useState(false);
  const classes = useStyles();
  const [pageNumber, setPageNumber] = React.useState(0);
  // const [pageSize, setPageSize] = React.useState(10);
  const { pathname, hash } = useLocation();
  const [tableSettings, setTableSettings] =
    useLocalStorageSettings<TableSettings>(
      getTableSettingsStorageKey(pathname, hash),
      { rowsPerPage: 25 },
    );

  const [ticketCounts, setTicketCounts] = React.useState<
    TicketCounts | undefined
  >();
  const [data, setData] = React.useState<PagedTicket | undefined>();
  const [hideClosedTickets, setHideClosedTickets] = React.useState(false);

  const fetchData = React.useCallback(() => {
    setLoading(true);
    fetchTickets(
      companyId,
      pageNumber + 1,
      tableSettings.rowsPerPage,
      hideClosedTickets,
    ).then((d) => {
      setLoading(false);
      setData(d);
    });
    fetchTicketCounts(companyId).then((d) => {
      setTicketCounts(d);
    });
  }, [
    setLoading,
    setData,
    setTicketCounts,
    companyId,
    pageNumber,
    tableSettings.rowsPerPage,
    hideClosedTickets,
  ]);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);
  const columns: PageableTableHeader[] = [
    {
      key: 'created',
      label: 'Date created',
      sortable: false,
    },
    {
      key: 'title',
      label: 'Ticket Subject',
      sortable: false,
    },
    {
      key: 'assignee',
      label: 'Assignee',
      sortable: false,
    },
    {
      key: 'status',
      label: 'Ticket Status',
      sortable: false,
    },
    {
      key: 'type',
      label: 'Ticket Type',
      sortable: false,
    },
    {
      key: 'solvedAt',
      label: 'Solved At',
      sortable: false,
    },
    {
      key: 'daysToSolve',
      label: 'Days to Solve',
      sortable: false,
    },
    {
      key: 'tags',
      label: 'Tags',
      sortable: false,
    },
  ];

  return (
    <Card className={classes.card}>
      <CardHeader
        title="Tickets"
        subheader="Tickets opened by the client handled by our customer service team."
        style={{ paddingBottom: 0, marginBottom: 0 }}
        action={
          <Button color="primary" href="/dashboards#customer-success">
            Customer Support Dashboard
          </Button>
        }
      />
      <FormControl margin="normal" style={{ marginLeft: 20 }}>
        <FormControlLabel
          control={
            <Switch
              checked={hideClosedTickets}
              onChange={(e, checked) => {
                setHideClosedTickets(checked);
              }}
            />
          }
          label={'Hide closed tickets'}
        />
      </FormControl>
      <AppBar color="primary" position="static" style={{ textAlign: 'center' }}>
        <DelayedLinearProgress loading={loading} />
        <Toolbar>
          {loading ? (
            <Skeleton variant="rect" width="100%" height={50} />
          ) : (
            <Grid container spacing={2} style={{ overflow: 'hidden' }}>
              <Grid item sm={4}>
                <Typography variant="h6">
                  {ticketCounts?.lifetime_tickets ?? 0}
                </Typography>
                <Typography variant="caption">
                  Total Lifetime Tickets
                </Typography>
              </Grid>

              <Grid item sm={4}>
                <Typography variant="h6">
                  {ticketCounts?.open_tickets ?? 0}
                </Typography>
                <Typography variant="caption">Total Open Tickets</Typography>
              </Grid>

              <Grid item sm={4}>
                <Typography variant="h6">
                  {ticketCounts?.closed_tickets ?? 0}
                </Typography>
                <Typography variant="caption">Total Closed Tickets</Typography>
              </Grid>
            </Grid>
          )}
        </Toolbar>
      </AppBar>
      <Divider />
      <PageableTable
        tableSettings={tableSettings}
        setTableSettings={setTableSettings}
        showSkeletonLoading
        rows={
          data?.data?.map((d, index) => ({
            key: `ticket_${index}`,
            cells: [
              {
                key: 'created',
                display: d.ticket_created_at ? (
                  <DateDisplay date={d.ticket_created_at} />
                ) : (
                  ''
                ),
              },
              {
                key: 'title',
                display: (
                  <React.Fragment>
                    <div>
                      <Button
                        className={classNames(
                          d.raw_subject
                            ? [classes.boldTitle, classes.clickable]
                            : [classes.boldTitle],
                        )}
                        variant="text"
                        href={`https://talexiohr.zendesk.com/agent/tickets/${d.ticket_id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {d.raw_subject}
                      </Button>
                    </div>
                  </React.Fragment>
                ),
              },
              {
                key: 'owner',
                display: d.assignee,
              },
              {
                key: 'status',
                display: (
                  <span
                    style={{
                      color:
                        d.status?.toLowerCase() === 'closed' ||
                        d.status?.toLowerCase() === 'solved'
                          ? 'green'
                          : 'red',
                      textTransform: 'uppercase',
                    }}
                  >
                    {d.status?.toUpperCase() ?? 'N/A'}
                  </span>
                ),
              },
              {
                key: 'type',
                display: d.ticket_type,
              },
              {
                key: 'solvedAt',
                display: d.solved_at ? (
                  isValid(parseISO(d.solved_at)) ? (
                    <DateDisplay date={d.solved_at} />
                  ) : (
                    'No Date'
                  )
                ) : (
                  ''
                ),
              },
              {
                key: 'daysToSolve',
                display: d.days_to_solve,
              },
              {
                key: 'tags',
                display: d.tags
                  ? d.tags.split(',').map((tag) => (
                      <Chip
                        label={tag
                          .trim()
                          .split('_')
                          .map(
                            (word) =>
                              word.charAt(0).toUpperCase() +
                              word.slice(1).toLowerCase(),
                          )
                          .join(' ')}
                        size="small"
                        style={{ margin: '2px' }}
                      />
                    ))
                  : null,
              },
            ],
          })) ?? []
        }
        loading={loading}
        pageNumber={pageNumber}
        rowCount={data?.total_count ?? 0}
        columns={columns}
        onChangePage={setPageNumber}
      />
    </Card>
  );
};
