import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { PageableTable } from 'components';
import { DateDisplay } from 'components/DateDisplay/DateDisplay';
import { PageableTableHeader } from 'components/PageableTable/components';
import { RelatedCompanyChip } from 'components/RelatedCompanyChip';
import { DateTimeFormat } from 'config';
import { format, parseISO } from 'date-fns';
import {
  useLocalStorageSettings,
  TableSettings,
  getTableSettingsStorageKey,
} from 'hooks';
import { fetchActivity, fetchActivityDetail } from 'lib/Api/Activity';
import { PagedActivity, Activity as ActivityEntity } from 'lib/Model/Activity';
import * as React from 'react';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: theme.spacing(2),
  },
  dateColumn: {
    width: 60,
    textAlign: 'center',
    paddingRight: 0,
  },
  typeColumn: {
    width: 120,
    textAlign: 'center',
  },
  keyHeading: {
    fontWeight: 500,
    textTransform: 'capitalize',
  },
  keyValue: {},
  keyRow: {
    marginBottom: theme.spacing(1),
  },
}));

interface Props {
  companyId: string;
  withRelated: boolean;
}

export const ActivityLog: React.FC<Props> = ({ companyId, withRelated }) => {
  const [loading, setLoading] = React.useState(false);
  const [loadingSingleActivity, setLoadingSingleActivity] =
    React.useState(false);

  const classes = useStyles();
  const [pageNumber, setPageNumber] = React.useState(0);
  const { pathname, hash } = useLocation();
  const [tableSettings, setTableSettings] =
    useLocalStorageSettings<TableSettings>(
      getTableSettingsStorageKey(pathname, hash),
      { rowsPerPage: 25 },
    );
  const [resourceType] = React.useState<string[]>([]);
  const [zendeskLeader] = React.useState<string[]>([]);

  const [data, setData] = React.useState<PagedActivity | undefined>();
  const [selectedActivity, setSelectedActivity] = React.useState<
    ActivityEntity | undefined
  >();

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const fetchData = React.useCallback(() => {
    setLoading(true);
    fetchActivity(
      companyId,
      pageNumber + 1,
      tableSettings.rowsPerPage,
      withRelated,
      zendeskLeader,
      resourceType,
    ).then((d) => {
      setLoading(false);
      setData(d);
    });
  }, [
    setLoading,
    setData,
    pageNumber,
    tableSettings.rowsPerPage,
    companyId,
    withRelated,
    zendeskLeader,
    resourceType,
  ]);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  const [singleActivityData, setSingleActivityData] = React.useState<
    any | undefined
  >();

  React.useEffect(() => {
    if (selectedActivity === undefined) {
      setSingleActivityData(undefined);
      return;
    }
    setLoadingSingleActivity(true);
    fetchActivityDetail(companyId, selectedActivity.id).then((d) => {
      setLoadingSingleActivity(false);
      setSingleActivityData(d);
    });
  }, [selectedActivity, setSingleActivityData, companyId]);

  const columns: PageableTableHeader[] = [
    {
      key: 'date',
      label: '',
      sortable: false,
    },
    {
      key: 'type',
      label: 'Type',
      sortable: false,
    },
    {
      key: 'summary',
      label: '',
      sortable: false,
    },
  ];

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        md={!selectedActivity ? 12 : 6}
        hidden={Boolean(selectedActivity) && isSmallScreen}
      >
        <Card className={classes.card}>
          <CardHeader
            title="Activity Log"
            subheader="Full activity log from all our platforms. You can click the log entry for more information."
          />
          <PageableTable
            tableSettings={tableSettings}
            setTableSettings={setTableSettings}
            showSkeletonLoading
            hideColumns={true}
            rows={
              data?.data?.map((d) => ({
                key: d.id,
                props: {
                  style: {
                    cursor: 'pointer',
                  },
                  onClick: () => {
                    setSelectedActivity(d);
                  },
                },
                cells: [
                  {
                    key: 'date',
                    display: d.created_on && (
                      <DateDisplay date={d.created_on} />
                    ),
                    props: {
                      className: classes.dateColumn,
                    },
                  },
                  {
                    key: 'type',
                    display: (
                      <React.Fragment>
                        <strong>
                          {d.resource_type.replaceAll('_', ' ').toUpperCase()}
                        </strong>
                        <br />
                      </React.Fragment>
                    ),

                    props: {
                      className: classes.typeColumn,
                    },
                  },
                  {
                    key: 'summary',
                    display: (
                      <div>
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              (d.value?.replace(/(?:\r\n|\r|\n)/g, '<br>') ??
                                '') +
                              (typeof d.value === 'string' &&
                              d.value.length > 50
                                ? '...'
                                : ''),
                          }}
                        />
                        {withRelated && (
                          <RelatedCompanyChip
                            companyId={d.company_id}
                            companyName={d.company_title}
                          />
                        )}
                      </div>
                    ),
                  },
                ],
              })) ?? []
            }
            loading={loading}
            pageNumber={pageNumber}
            rowCount={data?.total_count ?? 0}
            columns={columns}
            onChangePage={setPageNumber}
          />
        </Card>
      </Grid>
      {selectedActivity && (
        <Grid item xs={12} md={6}>
          <Card className={classes.card}>
            <CardHeader
              title={
                !selectedActivity.created_on
                  ? 'Activity'
                  : format(
                      parseISO(selectedActivity.created_on),
                      DateTimeFormat.LONG_FRIENDLY,
                    )
              }
              action={
                <IconButton onClick={() => setSelectedActivity(undefined)}>
                  <Close />
                </IconButton>
              }
              subheader={`${selectedActivity.resource_type
                .replaceAll('_', ' ')
                .toUpperCase()} ${selectedActivity.platform.toUpperCase()}`}
            />
            {loadingSingleActivity ? (
              <Skeleton style={{ height: 300 }} variant="rect" />
            ) : (
              <CardContent>
                <div>
                  {singleActivityData &&
                    Object.keys(singleActivityData).map((key) =>
                      singleActivityData[key] ? (
                        <div key={key} className={classes.keyRow}>
                          <div className={classes.keyHeading}>
                            {key.replaceAll('_', ' ')}
                          </div>
                          <div className={classes.keyValue}>
                            <Typography variant="body1">
                              {key === 'created_on' ||
                              key === 'last_stage_change_at' ? (
                                format(
                                  parseISO(singleActivityData[key]),
                                  DateTimeFormat.LONG,
                                )
                              ) : (
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: singleActivityData[key]
                                      .toString()
                                      .replace(/(?:\r\n|\r|\n)/g, '<br>'),
                                  }}
                                />
                              )}
                            </Typography>
                          </div>
                        </div>
                      ) : null,
                    )}
                </div>
              </CardContent>
            )}
          </Card>
        </Grid>
      )}
    </Grid>
  );
};
