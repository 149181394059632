import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  makeStyles,
} from '@material-ui/core';
import { DialogProps } from '@material-ui/core/Dialog';
import * as React from 'react';
import classNames from 'classnames';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

interface Props {
  title: string;
  dialogProps: DialogProps;
  loading?: boolean;
  content?: React.ReactNode;
  noText?: string;
  yesText?: string;
  typePrompt?: string;
  useTitleMainColor?: boolean;
  overrideWidth?: boolean;
  center?: boolean;
  warningIcon?: boolean;
  disableBackdropClick?: boolean;

  onConfirm?(e: React.MouseEvent<HTMLElement>): void;
  onCancel?(e: React.MouseEvent<HTMLElement>): void;
}
const useStyles = makeStyles(() => ({
  paper: { maxWidth: '331px' },
  centerItems: { textAlign: 'center' },
  centerButtons: { justifyContent: 'center' },
  iconFormatting: {
    width: '100%',
    paddingTop: '21px',
    fontSize: '54px',
    color: '#F34436',
  },
  extraBottom: {
    marginBottom: '10px',
  },
}));

export const ConfirmDialog: React.FC<Props> = ({
  title,
  dialogProps,
  loading = false,
  content,
  noText,
  yesText,
  disableBackdropClick,
  typePrompt,
  onConfirm,
  onCancel,
  useTitleMainColor,
  overrideWidth,
  center,
  warningIcon,
}) => {
  const [typePromptValue, setTypePromptValue] = React.useState('');

  const classes = useStyles();
  return (
    <Dialog
      disableBackdropClick={disableBackdropClick}
      className={classNames({
        [classes.centerItems]: center,
      })}
      classes={{ paper: overrideWidth ? classes.paper : '' }}
      disableEnforceFocus
      {...dialogProps}
    >
      {warningIcon && <ErrorOutlineIcon className={classes.iconFormatting} />}
      <DialogTitle
        style={{
          color: useTitleMainColor ? '#F34436' : '',
          paddingTop: warningIcon ? '2px' : '',
          paddingBottom: warningIcon ? '10px' : '',
        }}
      >
        {title}
      </DialogTitle>

      <DialogContent>
        {typeof content === 'string' ? (
          <DialogContentText>{content}</DialogContentText>
        ) : (
          content
        )}
        {typePrompt && (
          <TextField
            fullWidth
            autoFocus
            onChange={(e) => setTypePromptValue(e.currentTarget.value)}
            placeholder={`Type '${typePrompt}' to confirm this action`}
          />
        )}
      </DialogContent>

      <DialogActions
        classes={{
          root: center ? classes.centerButtons : '',
          spacing: center ? classes.extraBottom : '',
        }}
      >
        <Button
          onClick={onCancel ?? dialogProps.onClose}
          color="default"
          disabled={loading}
        >
          {noText || 'No'}
        </Button>

        {onConfirm && !warningIcon ? (
          <Button
            onClick={(e) => {
              setTypePromptValue('');
              onConfirm(e);

              if (dialogProps.onClose) {
                dialogProps.onClose(e);
              }
            }}
            color="primary"
            disabled={
              loading ||
              (typePrompt !== undefined &&
                typePrompt.toLowerCase() !==
                  typePromptValue.trim().toLowerCase())
            }
            autoFocus
          >
            {yesText || 'Yes'}
          </Button>
        ) : (
          onConfirm && (
            <Button
              onClick={(e) => {
                setTypePromptValue('');
                onConfirm(e);

                if (dialogProps.onClose) {
                  dialogProps.onClose(e);
                }
              }}
              style={{ color: '#F34436' }}
              disabled={
                loading ||
                (typePrompt !== undefined &&
                  typePrompt.toLowerCase() !==
                    typePromptValue.trim().toLowerCase())
              }
              autoFocus
            >
              {yesText || 'Yes'}
            </Button>
          )
        )}
      </DialogActions>
    </Dialog>
  );
};
