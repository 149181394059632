import { BusinessCenter, HeadsetMic, Money, Star } from '@material-ui/icons';
import { AppTabs } from 'components';
import { useDocumentTitle } from 'hooks';
import * as React from 'react';

enum DASHBOARD_TABS {
  COMMERCIAL = 'commercial',
  CUSTOMER_SUPPORT = 'customer-success',
  EMPLOYMENT = 'employment',
  EXECUTIVE = 'executive',
  QUALITY_APPS = 'quality_apps',
  JIM = 'jobsinmalta',
  NPS = 'nps',
  MARKETING = 'marketing',
  RECRUITMENT = 'recruitment',
  VACANCY_SUCCESS = 'vacancy-success',
  PAYROLL = 'payroll',
  PROACT = 'proact',
  PRODUCT = 'product',
  SIM = 'sim',
}

export const DashboardsList = [
  {
    path: `/dashboards#${DASHBOARD_TABS.COMMERCIAL}`,
    value: DASHBOARD_TABS.COMMERCIAL,
    label: 'Commercial',
    secondary: 'Dashboard',
    description:
      'Provides our Client Team with visibility to how we are advancing towards achieving our current year targets. Monitor progress, engagement activities, and make data-driven decisions that enhance sales strategies and drive sales performance to new heights.',
  },
  {
    path: `/dashboards#${DASHBOARD_TABS.CUSTOMER_SUPPORT}`,
    value: DASHBOARD_TABS.CUSTOMER_SUPPORT,
    label: 'Customer Support',
    secondary: 'Dashboard',
    description: 'Statistics and insights from the customer support team.',
    icon: <HeadsetMic />,
  },
  {
    path: `/dashboards#${DASHBOARD_TABS.EXECUTIVE}`,
    value: DASHBOARD_TABS.EXECUTIVE,
    label: 'Executive Recruitment',
    secondary: 'Dashboard',
    description: 'Insights into executive recruitment.',
  },
  {
    path: `/dashboards#${DASHBOARD_TABS.EMPLOYMENT}`,
    value: DASHBOARD_TABS.EMPLOYMENT,
    label: 'Employment Data',
    secondary: 'Dashboard',
    description: 'Insights into local employment statistics.',
  },
  {
    path: `/dashboards#${DASHBOARD_TABS.JIM}`,
    value: DASHBOARD_TABS.JIM,
    label: 'jobsinmalta',
    secondary: 'Dashboard',
    description: 'Insights into Jobsinmalta listings, sales and clients.',
  },
  {
    path: `/dashboards#${DASHBOARD_TABS.MARKETING}`,
    value: DASHBOARD_TABS.MARKETING,
    label: 'Marketing',
    secondary: 'Dashboard',
    description: 'Insights into our marketing efforts.',
    icon: <BusinessCenter />,
  },
  {
    path: `/dashboards/nps#${DASHBOARD_TABS.NPS}`,
    value: DASHBOARD_TABS.NPS,
    label: 'Net Promoter Score (NPS)',
    secondary: 'Dashboard',
    description: 'All Expedition42 NPS results in one place.',
    icon: <Star />,
  },
  {
    path: `/dashboards#${DASHBOARD_TABS.PAYROLL}`,
    value: DASHBOARD_TABS.PAYROLL,
    label: 'Payroll Service',
    secondary: 'Dashboard',
    description: 'Data from our Payroll Service and HR Admin Support team.',
    icon: <Money />,
  },
  {
    path: `/dashboards#${DASHBOARD_TABS.PROACT}`,
    value: DASHBOARD_TABS.PROACT,
    label: 'PROACT',
    secondary: 'Dashboard',
    description:
      'Priority, Revenue, Outreach, and Contact Timing. A dashboard to classify clients and optimise outreach.',
  },
  {
    path: `/dashboards#${DASHBOARD_TABS.PRODUCT}`,
    value: DASHBOARD_TABS.PRODUCT,
    label: 'Product',
    secondary: 'Dashboard',
    description: 'Insights and metrics into Talexio.',
  },
  {
    path: `/dashboards#${DASHBOARD_TABS.QUALITY_APPS}`,
    value: DASHBOARD_TABS.QUALITY_APPS,
    label: 'Quality Applications',
    secondary: 'Dashboard',
    description:
      'Data and insights into quality applications from our Konnekt Marketing efforts.',
  },
  {
    path: `/dashboards#${DASHBOARD_TABS.RECRUITMENT}`,
    value: DASHBOARD_TABS.RECRUITMENT,
    label: 'Recruitment Service',
    secondary: 'Dashboard',
    description: 'Insights from our recruitment team.',
    icon: <BusinessCenter />,
  },
  {
    path: `/dashboards#${DASHBOARD_TABS.VACANCY_SUCCESS}`,
    value: DASHBOARD_TABS.VACANCY_SUCCESS,
    label: 'Recruitment - Vacancy Success',
    secondary: 'Dashboard',
    description:
      'Recruitment data built around the likelihood of success for vacancies.',
    icon: <BusinessCenter />,
  },
  {
    path: `/dashboards#${DASHBOARD_TABS.SIM}`,
    value: DASHBOARD_TABS.SIM,
    label: 'Salariesinmalta',
    secondary: 'Dashboard',
    description:
      'Information on salary benchmarks and our salary benchmarking platform.',
    icon: <BusinessCenter />,
  },
];

export const DashboardsView: React.FC = () => {
  useDocumentTitle('Dashboards');
  return (
    <AppTabs tabs={DashboardsList}>
      {(activeTab) => {
        switch (activeTab) {
          case DASHBOARD_TABS.JIM:
            return (
              <iframe
                title="JIM"
                width="100%"
                src="https://lookerstudio.google.com/embed/reporting/3472b038-18b7-4c98-8d95-47b17badab3a/page/qKvnC"
                style={{ border: 0, height: '98vh' }}
                allowFullScreen
              ></iframe>
            );
          case DASHBOARD_TABS.COMMERCIAL:
            return (
              <iframe
                title="Commercial"
                width="100%"
                src="https://lookerstudio.google.com/embed/reporting/765e896a-c8da-4910-881b-2b1a94deb547/page/p_oiog2a12dd"
                style={{ border: 0, height: '100vh' }}
                allowFullScreen
              ></iframe>
            );
          case DASHBOARD_TABS.CUSTOMER_SUPPORT:
            return (
              <iframe
                title="CS Dashboard"
                width="100%"
                src="https://lookerstudio.google.com/embed/reporting/f4670077-7b05-4ece-821c-14719a361954/page/p_514j1iy6xc"
                style={{ border: 0, height: '98vh' }}
                allowFullScreen
              ></iframe>
            );
          case DASHBOARD_TABS.PROACT:
            return (
              <iframe
                title="PROACT Dashboard"
                width="100%"
                src="https://lookerstudio.google.com/embed/reporting/9bd11dd5-4889-4736-8ca8-a306d284bf32/page/p_ktdlam3bcd"
                style={{ border: 0, height: '98vh' }}
                allowFullScreen
              ></iframe>
            );
          case DASHBOARD_TABS.PRODUCT:
            return (
              <iframe
                title="Product Dashboard"
                width="100%"
                src="https://lookerstudio.google.com/embed/reporting/d3210d09-42eb-48f3-9eaa-8cf6e3e79334/page/0aUwC"
                style={{ border: 0, height: '98vh' }}
                allowFullScreen
              ></iframe>
            );
          case DASHBOARD_TABS.MARKETING:
            return (
              <iframe
                title="Marketing"
                width="100%"
                src="https://lookerstudio.google.com/embed/reporting/11636f22-835b-4023-88e4-3df8b4c91741/page/0aUwC"
                style={{ border: 0, height: '98vh' }}
                allowFullScreen
              ></iframe>
            );
          case DASHBOARD_TABS.RECRUITMENT:
            return (
              <iframe
                title="Recruiter Dashboard"
                width="100%"
                src="https://datastudio.google.com/embed/reporting/1GZ7gQMEW8iBRjEnBNUp0943umC47UmCb/page/xEHDB"
                style={{ border: 0, height: '98vh' }}
                allowFullScreen
              ></iframe>
            );
          case DASHBOARD_TABS.NPS:
            return (
              <iframe
                title="NPS Dashboard"
                width="100%"
                src="https://lookerstudio.google.com/embed/reporting/5559d0c3-a019-438a-b0db-3b70067de737/page/p0aUwC"
                style={{ border: 0, height: '98vh' }}
                allowFullScreen
              ></iframe>
            );
          case DASHBOARD_TABS.VACANCY_SUCCESS:
            return (
              <iframe
                title="Vacancy Success"
                width="100%"
                src="https://lookerstudio.google.com/embed/reporting/d764f69c-da54-403b-a469-f33c006b0d02/page/LQwGD"
                style={{ border: 0, height: '100vh' }}
                allowFullScreen
              ></iframe>
            );
          case DASHBOARD_TABS.SIM:
            return (
              <iframe
                title="Salariesinmalta"
                width="100%"
                src="https://lookerstudio.google.com/embed/reporting/4b1b422d-9b04-41db-badc-3f2947b1035b/page/taLPD"
                style={{ border: 0, height: '100vh' }}
                allowFullScreen
              ></iframe>
            );
          case DASHBOARD_TABS.QUALITY_APPS:
            return (
              <iframe
                title="Quality Applications"
                width="100%"
                src="https://lookerstudio.google.com/embed/reporting/14d0e31f-1cc7-47bd-a233-04f57814518f/page/0aUwC"
                style={{ border: 0, height: '100vh' }}
                allowFullScreen
              ></iframe>
            );
          case DASHBOARD_TABS.EXECUTIVE:
            return (
              <iframe
                title="Executive Recruitment"
                width="100%"
                src="https://lookerstudio.google.com/embed/reporting/99f9fec9-ff5d-4aef-9fc5-658313626a2a/page/l3oyC"
                style={{ border: 0, height: '100vh' }}
                allowFullScreen
              ></iframe>
            );
          case DASHBOARD_TABS.EMPLOYMENT:
            return (
              <iframe
                title="Employment Data"
                width="100%"
                src="https://lookerstudio.google.com/embed/reporting/cb60dabb-1761-4d22-8918-4b9c9b136c90/page/PWr7C"
                style={{ border: 0, height: '100vh' }}
                allowFullScreen
              ></iframe>
            );
          case DASHBOARD_TABS.PAYROLL:
            return (
              <iframe
                title="Payroll Service"
                width="100%"
                src="https://lookerstudio.google.com/embed/reporting/7d4eeb71-277a-480a-a8e8-e6d052d7a83c/page/tfLzC"
                style={{ border: 0, height: '100vh' }}
                allowFullScreen
              ></iframe>
            );
          default:
            return null;
        }
      }}
    </AppTabs>
  );
};
