import axios from 'axios';
import { Config } from 'config';
import { getAccessToken } from 'lib/Helper/Auth';
import { Ticket, PagedTicket, TicketCounts } from 'lib/Model/Ticket';

export function fetchTickets(
  companyId: string,
  pageNumber: number,
  itemsPerPage: number,
  hideClosedTickets: boolean,
): Promise<PagedTicket> {
  return axios
    .get(`${Config.API_BASE_URL}intranet/companies/${companyId}/tickets`, {
      params: {
        page: pageNumber,
        page_size: itemsPerPage,
        hide_closed_tickets: hideClosedTickets,
      },
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
        'access-token': getAccessToken(),
      },
    })
    .then((response) => {
      return {
        total_count: response.data.total_count,
        data: response.data.data as Ticket[],
      };
    });
}

export function fetchTicketCounts(companyId: string): Promise<TicketCounts> {
  return axios
    .get(
      `${Config.API_BASE_URL}intranet/companies/${companyId}/tickets/count`,
      {
        responseType: 'json',
        headers: {
          'Content-Type': 'application/json',
          'access-token': getAccessToken(),
        },
      },
    )
    .then((response) => {
      return response.data;
    });
}
