import axios from 'axios';
import { Config } from 'config';

export function checkUser(token: string): Promise<boolean> {
  return axios
    .post(
      `${Config.API_BASE_URL}validate?token=${token}`,
      {},
      {
        responseType: 'json',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then((response) => {
      return true;
    });
}
