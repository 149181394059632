import * as React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
} from '@material-ui/core';
import { DealAudit } from 'lib/Model/Deal';
import { format, parseISO } from 'date-fns';
import { DateFormat } from 'config';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    textAlign: 'center',
  },
}));

interface DealAuditDialogProps {
  open: boolean;
  dealAudit: DealAudit[];
  loading: boolean;
  onClose: () => void;
}

const DealAuditDialog: React.FC<DealAuditDialogProps> = ({
  open,
  dealAudit,
  loading,
  onClose,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="dealAudit-dialog"
      maxWidth="lg"
    >
      <DialogTitle id="dealAudit-dialog" className={classes.dialogTitle}>
        Deal History
      </DialogTitle>
      <DialogContent>
        {loading ? (
          <Skeleton variant="rect" style={{ height: 200 }} />
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Item</TableCell>
                <TableCell>Old Item</TableCell>
                <TableCell>New Item</TableCell>
                <TableCell>Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dealAudit.map((dealAudit) => (
                <TableRow key={dealAudit.time_of_change}>
                  <TableCell>{dealAudit.change_type}</TableCell>
                  <TableCell>{dealAudit.old_entity}</TableCell>
                  <TableCell>{dealAudit.new_entity}</TableCell>
                  <TableCell>
                    {format(
                      parseISO(dealAudit.time_of_change),
                      DateFormat.EXCEL,
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DealAuditDialog;
