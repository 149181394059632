import axios from 'axios';
import { Config } from 'config';
import { getAccessToken } from 'lib/Helper/Auth';
import { Meeting, PagedMeeting } from 'lib/Model/Meetings';

export function fetchAllMeetings(
  pageNumber: number,
  itemsPerPage: number,
  date_from?: string,
  date_to?: string,
  textSearch?: string,
  unit_name?: string,
): Promise<PagedMeeting> {
  return axios
    .get(`${Config.API_BASE_URL}intranet/meetings`, {
      params: {
        page: pageNumber,
        page_size: itemsPerPage,
        date_from,
        date_to,
        search_param: textSearch?.length ? textSearch : undefined,
        unit_name,
      },
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
        'access-token': getAccessToken(),
      },
    })
    .then((response) => {
      return {
        total_count: response.data.total_count,
        data: response.data.data as Meeting[],
      };
    });
}

export function exportMeetings(
  date_from?: string,
  date_to?: string,
  textSearch?: string,
  unit_name?: string,
): Promise<any> {
  return axios
    .get(`${Config.API_BASE_URL}intranet/meetings/export`, {
      params: {
        date_from,
        date_to,
        search_param: textSearch?.length ? textSearch : undefined,
        unit_name,
      },
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        'access-token': getAccessToken(),
      },
    })
    .then((response) => {
      const now = new Date();
      const date = now.toISOString().split('T')[0];
      const time = now.toTimeString().split(' ')[0].replace(/:/g, '');
      const formattedDate = date + time;
      const filename = `meetings_export_${formattedDate}.xlsx`;

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
    });
}
