import {
  Button,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  useMediaQuery,
  useTheme,
  TableContainerProps,
} from '@material-ui/core';
import { TableProps } from '@material-ui/core/Table';
import { TableCellProps } from '@material-ui/core/TableCell';
import { TableHeadProps } from '@material-ui/core/TableHead';
import { TableRowProps } from '@material-ui/core/TableRow';
import { TableSortLabelProps } from '@material-ui/core/TableSortLabel';
import { Skeleton } from '@material-ui/lab';
import { DelayedLinearProgress } from 'components/DelayedLinearProgress';
import { TableSettings, usePrevious } from 'hooks';
import * as React from 'react';
import { Pagination } from '.';
import { EmptyView } from '../EmptyView';
import { PageableTableHead, PageableTableHeader } from './components';
import { useStyles } from './styles';

export interface PageableTableCell {
  key: string;
  display: React.ReactNode;
  props?: TableCellProps;
}

export interface PageableTableRow {
  key: string;
  cells: PageableTableCell[];
  props?: TableRowProps;
}

interface Props {
  defaultSort?: {
    columnKey: string;
    order: TableSortLabelProps['direction'];
  };
  tableSettings: TableSettings;
  setTableSettings: React.Dispatch<React.SetStateAction<TableSettings>>;
  showSkeletonLoading?: boolean;
  columns: PageableTableHeader[];
  rows: PageableTableRow[];
  rowCount?: number;
  loading?: boolean;
  tableProps?: TableProps;
  tableContainerProps?: TableContainerProps;
  tableHeadProps?: TableHeadProps;
  tableFooter?: JSX.Element;
  emptyTableText?: string;
  disablePagination?: boolean;
  disableSorting?: boolean;
  infiniteScroll?: boolean;
  useMobileView?: boolean;
  pageNumber?: number;
  defaultRowsPerPage?: number;
  hideColumns?: boolean;

  emptyTableCreateAction?(
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ): void;
  onChangePage(pageNumber: number): void;
  onChangePageSize?(pageSize: number): void;
}

export const PageableTable: React.FC<Props> = ({
  defaultSort,
  columns,
  rows,
  rowCount,
  loading = false,
  tableProps,
  tableHeadProps,
  tableFooter,
  emptyTableText,
  disablePagination,
  disableSorting,
  pageNumber,
  hideColumns,
  showSkeletonLoading,
  tableContainerProps,
  emptyTableCreateAction,
  onChangePage,
  onChangePageSize,
  useMobileView,
  tableSettings,
  setTableSettings,
}) => {
  const classes = useStyles();
  // const { pathname, hash } = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')) && useMobileView;

  let sortableColumn;

  if (tableSettings.orderBy) {
    sortableColumn = columns.find((c) => c.key === tableSettings.orderBy);
  } else if (defaultSort) {
    const { columnKey } = defaultSort;

    sortableColumn = columns.find((c) => c.key === columnKey);
  } else {
    sortableColumn = columns.find(
      (c) => c.sortable === undefined || c.sortable,
    );
  }

  const totalRows = rowCount ?? rows.length;

  const [order, setOrder] = React.useState<TableSortLabelProps['direction']>(
    tableSettings.order
      ? tableSettings.order
      : defaultSort
      ? defaultSort.order
      : 'asc',
  );
  const [orderBy, setOrderBy] = React.useState(
    sortableColumn && sortableColumn.key,
  );
  const [page, setPage] = React.useState(pageNumber ?? 0);
  const [rowsPerPage, setRowsPerPage] = React.useState(
    tableSettings.rowsPerPage,
  );

  const prevTotalRows = usePrevious(totalRows);
  React.useEffect(() => {
    const maxPage = Math.ceil(totalRows / rowsPerPage);
    // the counter for page starts from 0, and maxPage starts from 1
    if (
      (page >= maxPage || (prevTotalRows && prevTotalRows !== totalRows)) &&
      !loading
    ) {
      setPage(0);
    }
  }, [rowsPerPage, page, totalRows, prevTotalRows, loading]);

  const prevPage = usePrevious(page);
  React.useEffect(() => {
    if (prevPage !== page) {
      onChangePage(page);
    }
  }, [onChangePage, page, prevPage]);

  const prevRowsPerPage = usePrevious(rowsPerPage);
  React.useEffect(() => {
    if (prevRowsPerPage !== rowsPerPage && onChangePageSize) {
      onChangePageSize(rowsPerPage);
    }
  }, [onChangePageSize, rowsPerPage, prevRowsPerPage]);

  let rowCopy = rows;

  if (!disablePagination && typeof rowCount === 'undefined') {
    // Paging
    rowCopy = rowCopy.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage,
    );
  }

  if (loading && showSkeletonLoading) {
    return <Skeleton variant="rect" style={{ height: 450 }} />;
  }

  return (
    <React.Fragment>
      {!showSkeletonLoading ? (
        <DelayedLinearProgress loading={loading} />
      ) : null}

      {totalRows ? (
        <React.Fragment>
          <TableContainer {...tableContainerProps}>
            <Table stickyHeader aria-label="sticky table" {...tableProps}>
              {!hideColumns && !isMobile && (
                <PageableTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  columnData={columns}
                  disableSorting={disableSorting}
                  tableHeadProps={tableHeadProps}
                />
              )}

              <TableBody>
                {rowCopy.map((row, index) =>
                  isMobile ? (
                    <React.Fragment>
                      {row.cells
                        .filter(({ props }) => !props?.hidden)
                        .map(({ display, key: cellKey, props }) => {
                          const isControlsRow = cellKey === 'controls';
                          if (cellKey === 'select') {
                            return null; // We do not show the checkboxes on mobile for bulk actions
                          }
                          return (
                            <TableRow
                              tabIndex={-1}
                              key={`${cellKey}_row_${row.key}`}
                              {...row.props}
                              className={
                                index % 2
                                  ? classes.mobileRow
                                  : classes.mobileAlternateRow
                              }
                            >
                              {!isControlsRow && (
                                <TableCell
                                  key={`${cellKey}_label`}
                                  {...props}
                                  className={classes.mobileLabelColumn}
                                  style={{ textAlign: 'left' }}
                                >
                                  {columns.find((c) => c.key === cellKey)
                                    ?.label ?? ''}
                                </TableCell>
                              )}
                              <TableCell
                                key={cellKey}
                                {...props}
                                className={classes.mobileValueColumn}
                                colSpan={isControlsRow ? 2 : 1}
                                style={{
                                  textAlign: isControlsRow ? 'right' : 'left',
                                }}
                              >
                                {display}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </React.Fragment>
                  ) : (
                    <TableRow hover tabIndex={-1} key={row.key} {...row.props}>
                      {row.cells.map(({ display, key: cellKey, props }) => (
                        <TableCell key={cellKey} {...props}>
                          {display}
                        </TableCell>
                      ))}
                    </TableRow>
                  ),
                )}
              </TableBody>
            </Table>
          </TableContainer>

          {!disablePagination && (
            <Pagination
              standalone
              totalRows={totalRows}
              rowsPerPage={rowsPerPage}
              page={page}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
          )}

          {tableFooter && (
            <div className={classes.customFooter}>{tableFooter}</div>
          )}
        </React.Fragment>
      ) : (
        !loading && (
          <EmptyView>
            {emptyTableText ?? (
              <>
                Nothing here yet.
                <br />
                <br />
                Expecting some data? Click "Include data from related companies"
                to view data at an organisational level.
              </>
            )}
            <br />

            {emptyTableCreateAction && (
              <Button
                color="primary"
                size="small"
                onClick={emptyTableCreateAction}
              >
                Create one
              </Button>
            )}
          </EmptyView>
        )
      )}
    </React.Fragment>
  );

  function handleRequestSort(
    e: React.MouseEvent<HTMLElement>,
    newOrderBy: string,
  ) {
    if (disableSorting) {
      return;
    }

    const newOrder =
      orderBy === newOrderBy && order === 'desc' ? 'asc' : 'desc';

    setOrder(newOrder);
    setOrderBy(newOrderBy);

    setTableSettings((s) => ({ ...s, order: newOrder, orderBy: newOrderBy }));
  }

  function handleChangePage(
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) {
    if (disablePagination) {
      return;
    }

    window.scrollTo(0, 0);
    setPage(newPage);
  }

  function handleChangeRowsPerPage({
    target: { value },
  }: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    setRowsPerPage(parseInt(value, 10));
    setPage(0);
    setTableSettings((s) => ({ ...s, rowsPerPage: parseInt(value, 10) }));
  }
};
