import axios from 'axios';
import { Config } from 'config';
import { getAccessToken } from 'lib/Helper/Auth';
import { IntraMerges, Merge, PagedMerges } from 'lib/Model/Merge';
import { PagedRelations, Relation } from 'lib/Model/Relation';
import { Suggestion, PagedSuggestions } from 'lib/Model/Suggestion';

export function fetchSuggestions(
  pageNumber: number,
  itemsPerPage: number,
  searchText?: string,
): Promise<PagedSuggestions> {
  return axios
    .get(`${Config.API_BASE_URL}intranet/suggestions`, {
      params: {
        page: pageNumber,
        page_size: itemsPerPage,
        company_title: searchText,
      },
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
        'access-token': getAccessToken(),
      },
    })
    .then((response) => {
      return {
        total_count: response.data.total_count,
        data: response.data.data as Suggestion[],
      };
    });
}

export function fetchSuggestionsForCompany(
  companyId: string,
  pageNumber: number,
  itemsPerPage: number,
): Promise<PagedSuggestions> {
  return axios
    .get(`${Config.API_BASE_URL}intranet/companies/${companyId}/suggestions`, {
      params: {
        page: pageNumber,
        page_size: itemsPerPage,
      },
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
        'access-token': getAccessToken(),
      },
    })
    .then((response) => {
      return {
        total_count: response.data.total_count,
        data: response.data.data as Suggestion[],
      };
    });
}

export function fetchMergesForCompany(
  companyId: string,
  pageNumber: number,
  itemsPerPage: number,
): Promise<PagedMerges> {
  return axios
    .get(`${Config.API_BASE_URL}intranet/companies/${companyId}/merges`, {
      params: {
        page: pageNumber,
        page_size: itemsPerPage,
      },
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
        'access-token': getAccessToken(),
      },
    })
    .then((response) => {
      return {
        total_count: response.data.total_count,
        data: response.data.data as Merge[],
      };
    });
}

export function fetchRelationsForCompany(
  companyId: string,
  pageNumber: number,
  itemsPerPage: number,
): Promise<PagedRelations> {
  return axios
    .get(`${Config.API_BASE_URL}intranet/companies/${companyId}/relations`, {
      params: {
        page: pageNumber,
        page_size: itemsPerPage,
      },
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
        'access-token': getAccessToken(),
      },
    })
    .then((response) => {
      return {
        total_count: response.data.total_count,
        data: response.data.data as Relation[],
      };
    });
}

export function actionSuggestion(
  companyId: string,
  suggestionId: number,
  action: 'merge' | 'related',
): Promise<boolean> {
  return axios
    .post(
      `${Config.API_BASE_URL}intranet/companies/${companyId}/merges`,
      {
        status: action,
        suggestion_id: suggestionId,
        company_id: companyId,
      },
      {
        responseType: 'json',
        headers: {
          'Content-Type': 'application/json',
          'access-token': getAccessToken(),
        },
      },
    )
    .then((response) => {
      return true;
    });
}

export function deleteMerge(
  companyId: string,
  mergeId: string,
): Promise<boolean> {
  return axios
    .delete(
      `${Config.API_BASE_URL}intranet/companies/${companyId}/merges/${mergeId}`,
      {
        data: {
          merge_id: mergeId,
          parent_id: companyId,
        },
        responseType: 'json',
        headers: {
          'Content-Type': 'application/json',
          'access-token': getAccessToken(),
        },
      },
    )
    .then((response) => {
      return true;
    });
}

export function fetchIntraMergesForCompany(
  companyId: string,
): Promise<IntraMerges[]> {
  return axios
    .get(`${Config.API_BASE_URL}intranet/companies/${companyId}/intramerges`, {
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
        'access-token': getAccessToken(),
      },
    })
    .then((response) => response.data.data as IntraMerges[]);
}

export function exportIntramerges(): Promise<any> {
  return axios
    .get(`${Config.API_BASE_URL}intranet/companies/intramerges/export`, {
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        'access-token': getAccessToken(),
      },
    })
    .then((response) => {
      const now = new Date();
      const date = now.toISOString().split('T')[0];
      const time = now.toTimeString().split(' ')[0].replace(/:/g, '');
      const formattedDate = date + time;
      const filename = `intra_merges_report${formattedDate}.xlsx`;

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
    });
}
