import DateFnsUtils from '@date-io/date-fns';
import { CssBaseline } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { GlobalStateProvider } from 'App.state';
import { AppTheme } from 'App.theme';
import {
  DelayedCircularProgress,
  ErrorView,
  SnackbarContextProvider,
} from 'components';
import { LayoutSwitcher } from 'LayoutSwitcher';
import * as React from 'react';
import { BrowserRouter } from 'react-router-dom';

interface Props {}

interface State {
  error?: Error;
}

export default class App extends React.Component<Props, State> {
  /**
   * https://reactjs.org/docs/hooks-faq.html#how-do-lifecycle-methods-correspond-to-hooks
   *
   * componentDidCatch and getDerivedStateFromError: There are no Hook equivalents for these methods yet, but they will be added soon.
   */
  public static getDerivedStateFromError(error: Error) {
    return { error };
  }

  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  public render() {
    const { error } = this.state;

    if (error) {
      return <ErrorView error={error} />;
    }

    const loader = (
      <div
        style={{
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <DelayedCircularProgress variant="indeterminate" />
      </div>
    );

    return (
      <AppTheme>
        <SnackbarContextProvider>
          <GlobalStateProvider>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              {/* https://material-ui.com/style/css-baseline/ */}
              <CssBaseline />
              <React.Suspense fallback={loader}>
                <BrowserRouter>
                  <LayoutSwitcher />
                </BrowserRouter>
              </React.Suspense>
            </MuiPickersUtilsProvider>
          </GlobalStateProvider>
        </SnackbarContextProvider>
      </AppTheme>
    );
  }
}
