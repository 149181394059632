import {
  Avatar,
  Button,
  Card,
  CardHeader,
  Divider,
  makeStyles,
  Typography,
  useTheme,
} from '@material-ui/core';
import { PageableTable } from 'components';
import { PageableTableHeader } from 'components/PageableTable/components';
import {
  useLocalStorageSettings,
  TableSettings,
  getTableSettingsStorageKey,
} from 'hooks';
import { fetchRelationsForCompany } from 'lib/Api/Matches';
import { stringAvatar } from 'lib/Helper/Avatars';
import { PagedRelations, Relation } from 'lib/Model/Relation';
import * as React from 'react';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: theme.spacing(2),
  },
  contactColumn: {},
  actionsColumn: {
    width: 80,
    textAlign: 'right',
    paddingLeft: 0,
  },
  avatarColumn: {
    width: 30,
    paddingRight: 0,
    verticalAlign: 'top',
  },
  smallAvatar: {
    width: 26,
    height: 26,
    fontSize: 14,
  },
  boldTitle: {
    fontWeight: 500,
  },
}));

interface Props {
  companyId: string;
  doCompanyRefresh(): void;
}

export const RelatedSuggestions: React.FC<Props> = ({
  companyId,
  doCompanyRefresh,
}) => {
  const [loading, setLoading] = React.useState(false);
  const classes = useStyles();
  const [pageNumber, setPageNumber] = React.useState(0);
  // const [pageSize, setPageSize] = React.useState(10);

  const { pathname, hash } = useLocation();
  const [tableSettings, setTableSettings] =
    useLocalStorageSettings<TableSettings>(
      getTableSettingsStorageKey(pathname, hash),
      { rowsPerPage: 25 },
    );

  const [data, setData] = React.useState<PagedRelations | undefined>();

  const fetchData = React.useCallback(() => {
    setLoading(true);
    fetchRelationsForCompany(
      companyId,
      pageNumber + 1,
      tableSettings.rowsPerPage,
    ).then((d) => {
      setLoading(false);
      setData(d);
    });
  }, [setLoading, setData, pageNumber, tableSettings.rowsPerPage, companyId]);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  const theme = useTheme();

  function onOpenDetail(d: Relation) {
    window.open(`/clients/${d.company_id}`);
  }

  const columns: PageableTableHeader[] = [
    {
      key: 'id',
      label: '',
      sortable: false,
    },
    {
      key: 'contact',
      label: 'Contact',
      sortable: false,
    },
    {
      key: 'actions',
      label: '',
      sortable: false,
    },
  ];

  return (
    <Card className={classes.card}>
      <CardHeader
        title="Affiliated Companies"
        subheader="Companies that are related to each other. These company family trees are built on Zendesk Sell."
      />
      <Divider />
      <PageableTable
        tableSettings={tableSettings}
        setTableSettings={setTableSettings}
        showSkeletonLoading={loading}
        hideColumns
        rows={
          data?.data?.map((d, index) => ({
            key: index.toString(),
            cells: [
              {
                key: 'row',
                display: (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      paddingLeft: `${
                        theme.spacing(4.5) * d.hierarchy_level + 1
                      }px`,
                    }}
                  >
                    <div className={classes.avatarColumn}>
                      <Avatar
                        variant="rounded"
                        {...stringAvatar(d.company_title)}
                        className={classes.smallAvatar}
                      />
                    </div>
                    <div style={{ marginLeft: theme.spacing(1) }}>
                      {' '}
                      {/* Optional spacing between avatar and button */}
                      <Button
                        variant="text"
                        color="primary"
                        style={{ padding: 0, textAlign: 'left' }}
                        onClick={() => onOpenDetail(d)}
                      >
                        {d.company_title}
                      </Button>
                      {d.hierarchy_level > 0 && (
                        <React.Fragment>
                          <Typography
                            variant="caption"
                            style={{ display: 'block' }}
                          >
                            Parent Company: {d.direct_parent_name}
                          </Typography>
                        </React.Fragment>
                      )}
                    </div>
                  </div>
                ),
              },
            ],
          })) ?? []
        }
        loading={loading}
        pageNumber={pageNumber}
        rowCount={data?.total_count ?? 0}
        columns={columns}
        onChangePage={setPageNumber}
      />
    </Card>
  );
};
