import {
  Badge,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
  useTheme,
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
  BarChartRounded,
  BusinessCenter,
  Dashboard,
  CalendarToday,
  ExitToApp,
  // MergeType,
  TableChart,
  Mood,
  Assistant,
  NewReleases,
} from '@material-ui/icons';
import { RouterNavLink } from 'components';
import { logout } from 'lib';
import { getUserData } from 'lib/Helper/Auth';
import * as React from 'react';
import { SidebarLogo } from './components';
import { useStyles } from './styles';
import WellBeingMessage from './WellBeingMessage';

interface Props {
  openDrawer: () => void;
  closeDrawer: () => void;
  open: boolean;
}

export const Sidebar: React.FC<Props> = ({ open, openDrawer, closeDrawer }) => {
  const {
    breakpoints: { up },
  } = useTheme();
  const isLg = useMediaQuery(up('lg'));

  React.useEffect(() => {
    // When screen is large, we set the open state to false, so when the user minimizes screen size,
    // the drawer will start in a closed state.
    if (isLg && open) {
      closeDrawer();
    }
  });

  const classes = useStyles();

  interface MenuRoute {
    path: string;
    label: string;
    secondary?: string;
    icon?: React.ReactNode;
    onClick?: () => void;
    divider?: boolean;
  }

  const user = getUserData();
  if (!user) {
    return null;
  }

  const permissions = user.permissions ?? [];

  const routes: MenuRoute[] = [
    {
      path: '/dashboard',
      label: 'Dashboard',
      icon: <Dashboard />,
    },
  ];

  if (permissions.includes('read')) {
    routes.push({
      path: '/clients',
      label: 'Our Clients',
      icon: <BusinessCenter />,
    });
  }

  // if (permissions.includes('merge')) {
  //   routes.push({
  //     path: '/suggestions',
  //     label: 'Suggested Client Matches',
  //     icon: <MergeType />,
  //   });
  // }

  routes.push(
    ...[
      {
        path: '/chatbot',
        label: 'AI Assistant',
        icon: <Assistant />,
      },
      ...(permissions.includes('beta_helpbot')
        ? [
            {
              path: '/help',
              label: 'Help Center Bot',
              icon: (
                <Badge badgeContent="BETA" color="primary" overlap="rectangle">
                  <NewReleases />
                </Badge>
              ),
            },
          ]
        : []),
      {
        path: '/meetings',
        label: 'Client Meetings',
        icon: <CalendarToday />,
      },
      {
        path: '/dashboards',
        label: 'Insight Dashboards',
        icon: <BarChartRounded />,
      },
      {
        path: '/nps',
        label: 'Net Promoter Scores',
        icon: <Mood />,
      },
      {
        path: '/spreadsheets',
        label: 'Resources & Spreadsheets',
        icon: <TableChart />,
      },
      {
        path: '',
        label: '',
        divider: true,
      },
      {
        path: '/logout',
        label: 'Logout',
        icon: <ExitToApp />,
        onClick: () => {
          logout();
        },
      },
    ],
  );

  return (
    <SwipeableDrawer
      anchor="left"
      ModalProps={{ keepMounted: true }}
      variant={isLg ? 'permanent' : 'temporary'}
      open={isLg || open}
      onClose={closeDrawer}
      onOpen={openDrawer}
      classes={{ paper: classes.drawer }}
    >
      <List dense className={classes.list}>
        {user && (
          <ListItem>
            <ListItemText
              primary={`Welcome, ${user.name.split(' ')[0]}!`}
              secondary={user.sub}
            />
          </ListItem>
        )}
        {routes.map(
          ({ path, label, secondary, icon, divider, onClick }, index) =>
            divider ? (
              <React.Fragment key={`menuitem_${index}`}>
                <Divider key={`menuitem_${index}`} />
                {label && (
                  <React.Fragment>
                    <ListItem>
                      <ListItemText
                        primary={label}
                        primaryTypographyProps={{ style: { fontWeight: 500 } }}
                      />
                    </ListItem>
                    <Divider key={`menuitem_${index}`} />
                  </React.Fragment>
                )}
              </React.Fragment>
            ) : (
              <ListItem
                button
                onClick={() => (onClick ? onClick() : handleClick())}
                className={classes.item}
                key={path}
                component={RouterNavLink}
                to={path}
                activeClassName={classes.itemActive}
              >
                {icon && (
                  <ListItemIcon className={classes.itemIcon}>
                    {icon}
                  </ListItemIcon>
                )}

                <ListItemText
                  primary={label}
                  secondary={secondary}
                  secondaryTypographyProps={{
                    className: classes.itemText,
                  }}
                />
              </ListItem>
            ),
        )}
      </List>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          zIndex: 2,
          alignItems: 'center',
        }}
      >
        <WellBeingMessage />

        <SidebarLogo
          logoSrc={`/exp42-logo.png`}
          text="Expedition42"
          logoOnly={true}
        />
      </div>

      <div className={classes.background} />
    </SwipeableDrawer>
  );

  function handleClick() {
    closeDrawer();
  }
};
