import { Grid } from '@material-ui/core';
import { VerticalMenu } from 'components/VerticalMenu/VerticalMenu';
import * as React from 'react';
import { ActivityLog } from './ActivityLog';
import { Calls } from './Calls';
import { Deals } from './Deals';
import { Meetings } from './Meetings';
import { Notes } from './Notes';
import { Vacancies } from './Vacancies.';
import { Tickets } from './Tickets';

enum ActivityTabs {
  LOG = 'log',
  CALLS = 'calls',
  MEETINGS = 'meetings',
  TICKETS = 'tickets',
  VACANCIES = 'vacancies',
  DEALS = 'deals',
  NOTES = 'notes',
}

export const Activity: React.FC<{
  companyId: string;
  withRelated: boolean;
  meetingNotes?: string;
}> = ({ companyId, withRelated, meetingNotes }) => {
  const [selectedTab, setSelectedTab] = React.useState<string>(
    ActivityTabs.LOG,
  );
  const tabs = [
    { label: 'Activity Log', value: ActivityTabs.LOG },
    { label: 'Calls', value: ActivityTabs.CALLS },
    { label: 'Meetings', value: ActivityTabs.MEETINGS },
    { label: 'Vacancies', value: ActivityTabs.VACANCIES },
    { label: 'Support Tickets', value: ActivityTabs.TICKETS },
    { label: 'Deals', value: ActivityTabs.DEALS },
    { label: 'Notes', value: ActivityTabs.NOTES },
  ];

  return (
    <Grid container>
      <Grid item xs={12} sm={2}>
        <VerticalMenu
          menuItems={tabs}
          selectedItemKey={selectedTab}
          setSeletedItemKey={(key) => setSelectedTab(key)}
        />
      </Grid>
      <Grid item xs={12} sm={10}>
        {getCurrentTab()}
      </Grid>
    </Grid>
  );

  function getCurrentTab() {
    switch (selectedTab) {
      case ActivityTabs.LOG:
        return <ActivityLog companyId={companyId} withRelated={withRelated} />;
      case ActivityTabs.CALLS:
        return <Calls companyId={companyId} withRelated={withRelated} />;
      case ActivityTabs.MEETINGS:
        return <Meetings companyId={companyId} withRelated={withRelated} />;
      case ActivityTabs.VACANCIES:
        return <Vacancies companyId={companyId} withRelated={withRelated} />;
      case ActivityTabs.TICKETS:
        return <Tickets companyId={companyId} withRelated={withRelated} />;
      case ActivityTabs.DEALS:
        return <Deals companyId={companyId} withRelated={withRelated} />;
      case ActivityTabs.NOTES:
        return (
          <Notes
            companyId={companyId}
            withRelated={withRelated}
            meetingNotes={meetingNotes}
          />
        );
      default:
        return null;
    }
  }
};
