import { Typography, Button } from '@material-ui/core';
import brandLogo from '../../layouts/App/assets/exp42-logo.png';
import * as React from 'react';
import { useStyles } from './styles';

export const FetchFailed: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <a href="https://exp42.com" target="_blank" rel="noopener noreferrer">
        <img
          src={brandLogo}
          alt="Expedition 42"
          className={classes.websiteHeaderLogo}
        />
      </a>
      <Typography color="inherit" align="center" component="div">
        <Typography variant="h5" gutterBottom>
          Your browser is not able to reach Exp42 Intranet at this moment.
        </Typography>
        <Typography variant="subtitle2" gutterBottom>
          This may result from a networking problem. Please try reloading by
          pressing the button below.
        </Typography>
        <div className={classes.buttons}>
          <Button
            color="primary"
            variant="contained"
            onClick={() => document.location.reload()}
          >
            Click to reload
          </Button>{' '}
        </div>
      </Typography>
    </div>
  );
};
