import axios from 'axios';
import { Config } from 'config';
import { getAccessToken } from 'lib/Helper/Auth';
import {
  Job,
  PagedJob,
  JobInvoice,
  PublishingOptions,
  JobCounts,
} from 'lib/Model/Job';

export function fetchJobs(
  companyId: string,
  pageNumber: number,
  itemsPerPage: number,
  hideClosedRoles: boolean,
  withRelated: boolean,
  resourceTitle?: string,
  resourceType?: string[],
  dateFrom?: string,
  dateTo?: string,
): Promise<PagedJob> {
  return axios
    .get(`${Config.API_BASE_URL}intranet/companies/${companyId}/jobs`, {
      params: {
        page: pageNumber,
        page_size: itemsPerPage,
        hide_closed_roles: hideClosedRoles,
        show_related: withRelated,
        resource_title: resourceTitle,
        resource_type: resourceType,
        date_from: dateFrom,
        date_to: dateTo,
      },
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
        'access-token': getAccessToken(),
      },
    })
    .then((response) => {
      return {
        total_count: response.data.total_count,
        data: response.data.data as Job[],
      };
    });
}

export function fetchJobCounts(
  companyId: string,
  withRelated: boolean,
): Promise<JobCounts> {
  return axios
    .get(`${Config.API_BASE_URL}intranet/companies/${companyId}/jobs/count`, {
      params: {
        show_related: withRelated,
      },
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
        'access-token': getAccessToken(),
      },
    })
    .then((response) => {
      return response.data;
    });
}

export function fetchJobDetails(id: string): Promise<Job[]> {
  return axios
    .get(`${Config.API_BASE_URL}intranet/companies/jobs/${id}`, {
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
        'access-token': getAccessToken(),
      },
    })
    .then((response) => {
      return response.data.data as Job[];
    });
}

export function fetchVacancyInvoices(
  vacancy_id: string,
): Promise<JobInvoice[]> {
  return axios
    .get(
      `${Config.API_BASE_URL}intranet/companies/jobs/${vacancy_id}/invoices`,
      {
        params: {
          vacancy_id: vacancy_id,
        },
        responseType: 'json',
        headers: {
          'Content-Type': 'application/json',
          'access-token': getAccessToken(),
        },
      },
    )
    .then((response) => {
      return response.data.data as JobInvoice[];
    });
}

export function fetchForwardApplicationInvoices(
  candidate_id: string,
  client_id: string,
): Promise<JobInvoice[]> {
  return axios
    .get(
      `${Config.API_BASE_URL}intranet/companies/jobs/${client_id}/${candidate_id}/invoices`,
      {
        responseType: 'json',
        headers: {
          'Content-Type': 'application/json',
          'access-token': getAccessToken(),
        },
      },
    )
    .then((response) => {
      return response.data.data as JobInvoice[];
    });
}

export function fetchVacancyPublishingOptions(
  resource_id: string,
): Promise<PublishingOptions[]> {
  return axios
    .get(
      `${Config.API_BASE_URL}intranet/companies/jobs/publishing_options/${resource_id}`,
      {
        responseType: 'json',
        headers: {
          'Content-Type': 'application/json',
          'access-token': getAccessToken(),
        },
      },
    )
    .then((response) => {
      return response.data.data as PublishingOptions[];
    });
}
