import * as React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  List,
  ListItem,
  makeStyles,
  Typography,
  Divider,
  Grid,
} from '@material-ui/core';
import { Alert, Skeleton } from '@material-ui/lab';
import { CspList } from 'lib/Model/ProductStack';
import { isValid, parseISO, format } from 'date-fns';

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    textAlign: 'center',
  },
  listItem: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    '& .MuiButtonBase-root': {
      textAlign: 'left',
    },
  },
  CspClientAvatar: {
    fontSize: 10,
    width: '16px',
    height: '16px',
    marginRight: theme.spacing(1),
  },
  listText: {
    textAlign: 'left',
  },
  header: {
    fontWeight: 'bold',
  },
  alertPadding: {
    padding: theme.spacing(2),
  },
  tablePadding: {
    padding: theme.spacing(2),
  },
}));

interface Props {
  open: boolean;
  cspClients: CspList[];
  onClose(): void;
  loading?: boolean;
}

const CspListDialog: React.FC<Props> = ({
  open,
  cspClients,
  onClose,
  loading,
}) => {
  const classes = useStyles();

  const handleCspClientClick = (clientId: string) => {
    const url = `https://intranet.exp42.com/clients/${clientId}`;
    window.open(url, '_blank');
  };

  const formatDate = (dateString: string | undefined) => {
    return dateString && isValid(parseISO(dateString))
      ? format(parseISO(dateString), 'dd-MM-yyyy')
      : '';
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      aria-labelledby="product-stack-csps-dialog"
    >
      <DialogTitle
        id="product-stack-csps-dialog"
        className={classes.dialogTitle}
      >
        CSP Clients
      </DialogTitle>
      <DialogContent className={classes.alertPadding}>
        <Alert severity="warning">
          Important: Any clients that are not our direct clients should not be
          contacted.
        </Alert>
      </DialogContent>
      <DialogContent className={classes.tablePadding}>
        {loading ? (
          <Skeleton variant="rect" style={{ height: 200 }} />
        ) : (
          <List>
            <ListItem className={classes.listItem}>
              <Grid container spacing={2}>
                <Grid item xs={3} className={classes.header}>
                  <Typography className={classes.header}>
                    Client Name
                  </Typography>
                </Grid>
                <Grid item xs={3} className={classes.header}>
                  <Typography className={classes.header}>CAM</Typography>
                </Grid>
                <Grid item xs={3} className={classes.header}>
                  <Typography className={classes.header}>
                    Subscription Start Date
                  </Typography>
                </Grid>
                <Grid item xs={3} className={classes.header}>
                  <Typography className={classes.header}>
                    Number of Employees Last Month
                  </Typography>
                </Grid>
              </Grid>
            </ListItem>
            {cspClients.map((CspClient, index) => (
              <React.Fragment key={index}>
                <ListItem className={classes.listItem}>
                  <Grid container spacing={2}>
                    <Grid item xs={3} className={classes.listText}>
                      <Button
                        color="primary"
                        onClick={() =>
                          handleCspClientClick(CspClient.client_id ?? '')
                        }
                      >
                        <Typography>
                          {CspClient.csp_client_name ?? 'N/A'}
                        </Typography>
                      </Button>
                    </Grid>
                    <Grid item xs={3} className={classes.listText}>
                      <Typography>{CspClient.cam ?? ''}</Typography>
                    </Grid>
                    <Grid item xs={3} className={classes.listText}>
                      <Typography>
                        {formatDate(CspClient.subscription_start_date)}
                      </Typography>
                    </Grid>
                    <Grid item xs={3} className={classes.listText}>
                      <Typography>
                        {CspClient.actual_employees_last_month ?? 'N/A'}
                      </Typography>
                    </Grid>
                  </Grid>
                </ListItem>
                {index < cspClients.length - 1 && <Divider />}
              </React.Fragment>
            ))}
          </List>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CspListDialog;
