import * as React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  List,
  ListItem,
  makeStyles,
  Typography,
  Avatar,
  Divider,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { TalexioSubcompanies } from 'lib/Model/Wallet';
import { stringAvatar } from 'lib/Helper/Avatars';

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    textAlign: 'center',
  },
  listItem: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2),
  },
  subCompanyAvatar: {
    fontSize: 10,
    width: '16px',
    height: '16px',
    marginRight: theme.spacing(1),
  },
}));

interface Props {
  open: boolean;
  subCompanies: TalexioSubcompanies[];
  onClose(): void;
  loading?: boolean;
}

const WalletSubcompaniesDialog: React.FC<Props> = ({
  open,
  subCompanies,
  onClose,
  loading,
}) => {
  const classes = useStyles();

  const handleSubCompanyClick = (jimClientId: string) => {
    const url = `https://intranet.exp42.com/clients/${jimClientId}`;
    window.open(url, '_blank');
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="wallet-subcompanies-dialog"
    >
      <DialogTitle
        id="wallet-subcompanies-dialog"
        className={classes.dialogTitle}
      >
        Subcompanies
      </DialogTitle>
      <DialogContent>
        {loading ? (
          <Skeleton variant="rect" style={{ height: 200 }} />
        ) : (
          <List>
            {subCompanies.map((subCompany, index) => (
              <>
                <ListItem key={index} className={classes.listItem}>
                  <Button
                    color="primary"
                    onClick={() =>
                      handleSubCompanyClick(subCompany.jim_client_id ?? '')
                    }
                  >
                    {subCompany.company_title && (
                      <Avatar
                        {...stringAvatar(subCompany.company_title)}
                        className={classes.subCompanyAvatar}
                      />
                    )}
                    <Typography>{subCompany.company_title ?? 'N/A'}</Typography>
                  </Button>
                </ListItem>
                {index < subCompanies.length - 1 && <Divider />}
              </>
            ))}
          </List>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WalletSubcompaniesDialog;
