import axios from 'axios';
import { Config } from 'config';
import { getAccessToken } from 'lib/Helper/Auth';
import { Invoice, PagedInvoice } from 'lib/Model/Invoice';

export function fetchInvoices(
  companyId: string,
  pageNumber: number,
  itemsPerPage: number,
  withRelated: boolean,
  InvoiceText?: string,
  dateFrom?: string,
  dateTo?: string,
): Promise<PagedInvoice> {
  return axios
    .get(`${Config.API_BASE_URL}intranet/companies/${companyId}/invoices`, {
      params: {
        page: pageNumber,
        page_size: itemsPerPage,
        show_related: withRelated,
        description: InvoiceText,
        date_from: dateFrom,
        date_to: dateTo,
      },
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
        'access-token': getAccessToken(),
      },
    })
    .then((response) => {
      return {
        total_count: response.data.total_count,
        credit_sum: response.data.credit_sum,
        tax_sum: response.data.tax_sum,
        total_sum: response.data.total_sum,
        sub_total_sum: response.data.sub_total_sum,
        outstanding_sum: response.data.outstanding_sum,
        data: response.data.data as Invoice[],
      };
    });
}
