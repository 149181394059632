import * as React from 'react';

const brandTitle = 'Exp42 Intranet';

export function useDocumentTitle(input = '') {
  React.useEffect(() => {
    if (!input) {
      document.title = brandTitle;
      return;
    }
    const title = input.trim();

    document.title = title ? `${title} - ${brandTitle}` : brandTitle;

    // Unmount
    return () => {
      document.title = brandTitle;
    };
  }, [input]);
}
