import {
  Paper,
  TableFooter,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import * as React from 'react';
import { useStyles } from './styles';

interface Props {
  totalRows: number;
  rowsPerPage: number;
  page: number;
  standalone?: boolean;

  handleChangePage(
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ): void;
  handleChangeRowsPerPage({
    target: { value },
  }: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void;
}

export const Pagination: React.FC<Props> = ({
  totalRows,
  rowsPerPage,
  page,
  standalone = false,

  handleChangePage,
  handleChangeRowsPerPage,
}) => {
  const classes = useStyles();

  const paging = (
    <TablePagination
      component={standalone ? 'div' : 'td'}
      nextIconButtonText="Next page"
      backIconButtonText="Previous page"
      labelRowsPerPage="Rows per page"
      labelDisplayedRows={({ from, to, count }) =>
        `${from}-${to} of ${count !== -1 ? count : `more than ${to}`}`
      }
      count={totalRows}
      rowsPerPageOptions={[5, 10, 25, 50, 100]}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      classes={{
        selectRoot: classes.selectRootResponsive,
        actions: classes.actionsResponsive,
        toolbar: classes.toolbar,
      }}
      nextIconButtonProps={{
        classes: {
          root: classes.btnRootResponsive,
        },
      }}
      backIconButtonProps={{
        classes: {
          root: classes.btnRootResponsive,
        },
      }}
    />
  );

  return standalone ? (
    <Paper>{paging}</Paper>
  ) : (
    <TableFooter>
      <TableRow>{paging}</TableRow>
    </TableFooter>
  );
};
