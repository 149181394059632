import axios from 'axios';
import { Config } from 'config';
import { getAccessToken } from 'lib/Helper/Auth';
import { HelpRequest, HelpResponse } from 'lib/Model/Helpbot';

export function sendMessageToHelpbot(
  request: HelpRequest,
): Promise<HelpResponse> {
  return axios
    .post(`${Config.API_BASE_URL}intranet/help`, request, {
      headers: {
        'Content-Type': 'application/json',
        'access-token': getAccessToken(),
      },
    })
    .then((response) => response.data);
}

export function createSupportTicket(
  sessionId: string,
  subject?: string,
): Promise<{ message: string }> {
  return axios
    .post(
      `${Config.API_BASE_URL}intranet/help/ticket`,
      { session_id: sessionId, subject },
      {
        headers: {
          'Content-Type': 'application/json',
          'access-token': getAccessToken(),
        },
      },
    )
    .then((response) => response.data);
}
