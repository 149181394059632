import axios from 'axios';
import { Config } from 'config';
import { getAccessToken } from 'lib/Helper/Auth';
import { CspList, ProductStackDetails } from 'lib/Model/ProductStack';

export function fetchProductStack(
  companyId: string,
  withRelated: boolean,
): Promise<ProductStackDetails[]> {
  return axios
    .get(
      `${Config.API_BASE_URL}intranet/companies/${companyId}/product_stack`,
      {
        params: {
          company_id: companyId,
          show_related: withRelated,
        },
        responseType: 'json',
        headers: {
          'Content-Type': 'application/json',
          'access-token': getAccessToken(),
        },
      },
    )
    .then((response) => response.data.data as ProductStackDetails[]);
}

export function fetchCspList(companyId: string): Promise<CspList[]> {
  return axios
    .get(
      `${Config.API_BASE_URL}intranet/companies/${companyId}/product_stack/csp_clients`,
      {
        params: {
          company_id: companyId,
        },
        responseType: 'json',
        headers: {
          'Content-Type': 'application/json',
          'access-token': getAccessToken(),
        },
      },
    )
    .then((response) => response.data.data as CspList[]);
}
