import classNames from 'classnames';
import { UpdateNotification } from 'layouts/App/components';
import * as React from 'react';
import { Link, Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { AuthHandler } from './components/AuthHandler';
import { LoginForm } from './components/LoginForm';
import { useStyles } from './styles';

interface Props {
  waitingServiceWorker: ServiceWorker | null;
  updateServiceWorker(worker: ServiceWorker | null, fromBanner: boolean): void;
}

export const AuthLayout: React.FC<Props> = ({
  waitingServiceWorker,
  updateServiceWorker,
}) => {
  const classes = useStyles();
  const { hash, pathname, search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const isLogoutAction = hash === '#logout';
  // Build a redirect url including URL parameters
  const redirectURL =
    pathname === '/logout' || pathname === '/'
      ? 'r=/dashboard'
      : 'r=' + pathname;
  const iFrame = queryParams.get('iframe')
    ? '&iframe=' + queryParams.get('iframe')
    : '';
  const query = search ? '&q=' + encodeURIComponent(search) : '';
  return (
    <React.Fragment>
      {Boolean(waitingServiceWorker) && (
        <UpdateNotification
          updateServiceWorker={() =>
            updateServiceWorker(waitingServiceWorker, true)
          }
        />
      )}

      <div className={classNames(classes.container)}>
        <div>
          <div className={classes.logoContainer}>
            <Link to="/">{getExp42Logo()}</Link>
          </div>

          <div className={classes.authContainer}>
            <Switch>
              <Route path="/auth">
                <AuthHandler />
              </Route>
              <Route path="/login">
                <LoginForm />
              </Route>
              <Route>
                <Redirect
                  to={`/login${
                    isLogoutAction ? hash : `?${redirectURL}${query}${iFrame}`
                  }`}
                />
              </Route>
            </Switch>
          </div>
        </div>
      </div>
    </React.Fragment>
  );

  function getExp42Logo() {
    return (
      <img src="/exp42-logo.png" alt="Expedition 42" className={classes.logo} />
    );
  }
};
