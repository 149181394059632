import React, { useState, useRef, useEffect } from 'react';
import {
  Container,
  TextField,
  List,
  ListItem,
  Typography,
  Box,
  CircularProgress,
  IconButton,
  Button,
  Grid,
  Snackbar,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { getUserData } from 'lib/Helper/Auth';
import { HelpMessage, HelpRequest, HelpResponse } from 'lib/Model/Helpbot';
import { sendMessageToHelpbot, createSupportTicket } from 'lib/Api/Helpbot';
import ReactMarkdown from 'react-markdown';
import { Send, ThumbUpOutlined, ThumbDownOutlined } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';

const user = getUserData();

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '90vh',
    position: 'relative',
  },
  chatBox: {
    flex: 1,
    overflowY: 'auto',
  },
  messageBox: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(2),
    position: 'relative',
    '&:hover $feedbackButtonsBox': {
      display: 'flex',
    },
  },
  userMessage: {
    alignSelf: 'flex-end',
    backgroundColor: '#FD6A68',
    color: theme.palette.common.white,
    padding: theme.spacing(1),
    borderRadius: '10px',
    maxWidth: '70%',
    wordWrap: 'break-word',
  },
  botMessage: {
    alignSelf: 'flex-start',
    backgroundColor: theme.palette.grey[300],
    padding: theme.spacing(1),
    borderRadius: '10px',
    maxWidth: '70%',
    wordWrap: 'break-word',
    position: 'relative',
  },
  feedbackButtonsBox: {
    display: 'none',
    position: 'absolute',
    right: '-40px',
    top: '50%',
    transform: 'translateY(-50%)',
    flexDirection: 'column',
  },
  iconButtonRoot: {
    padding: 4,
  },
  inputBox: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
  },
  inputField: {
    flex: 1,
    marginRight: theme.spacing(1),
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#FD6A68',
      },
    },
  },
  messageHeader: {
    fontSize: '0.8rem',
    fontWeight: 'bold',
    marginBottom: theme.spacing(0.5),
    alignSelf: 'flex-end',
  },
  botHeader: {
    alignSelf: 'flex-start',
  },
  loader: {
    alignSelf: 'flex-start',
    padding: theme.spacing(1),
  },
  footer: {
    paddingTop: theme.spacing(0.5),
    textAlign: 'center',
    fontSize: '0.8rem',
    color: theme.palette.text.secondary,
  },
  feedbackButtonContainer: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
  },
  infoBox: {
    // backgroundColor: theme.palette.info.light,
    padding: theme.spacing(2),
    borderRadius: '10px',
    textAlign: 'center',
    color: theme.palette.common.black,
    marginBottom: theme.spacing(2),
    fontSize: '1rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.875rem',
    },
  },
  optionsContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    position: 'relative',
  },
  optionBox: {
    width: '100%',
    minHeight: '75px',
    backgroundColor: '#FD6A68',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    borderRadius: '10px',
    textAlign: 'center',
    padding: theme.spacing(0.5, 2, 0.5, 2),
    color: 'white',
    border: `1px solid black`,
    '&:hover': {
      backgroundColor: '#D45553',
    },
    zIndex: 2,
  },
  backgroundImage: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '200px',
    height: 'auto',
    opacity: 0.2,
    zIndex: -1,
  },
}));

const sessionId = [...Array(16)]
  .map(() => Math.random().toString(36)[2])
  .join('');

const options = [
  'How do I book leave?',
  'How do I set up a non-resident employee?',
  'What are the Leave Permissions in Talexio?',
  'How do I set up performance competencies?',
  'How is government bonus calculated if my job schedule is 38.75 hours per week?',
];

const OptionBoxes: React.FC<{ onSelect: (option: string) => void }> = ({
  onSelect,
}) => {
  const classes = useStyles();
  const [randomOptions, setRandomOptions] = useState<string[]>([]);

  useEffect(() => {
    const shuffledOptions = [...options].sort(() => 0.5 - Math.random());
    setRandomOptions(shuffledOptions.slice(0, 5));
  }, []);

  return (
    <>
      <Alert severity="info" variant="outlined">
        <strong>Tip:</strong> Try to be as specific as possible in your question
        - the more accurate your question, the more accurate the response. This
        includes specifying full names, brands, etc.
        <br />
        <br />
        You can get started by clicking one of the below sample prompts, or
        typing in your own question.
      </Alert>
      <Box mt={2}>
        <Alert severity="warning" variant="outlined">
          This bot is currently in Beta version and is not actively being
          maintained. Additionally, this bot has a knowledge cut-off date as of
          November 10th 2024. This means it won't have knowledge of any recent
          developments after this date.
        </Alert>
      </Box>
      <Box className={classes.optionsContainer}>
        <img
          src={'/talexio.png'}
          alt="Expedition42 Logo"
          className={classes.backgroundImage}
        />

        <Grid container spacing={2} justify="center" alignItems="stretch">
          {randomOptions.map((option, index) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={2}
              key={index}
              onClick={() => onSelect(option)}
            >
              <Box className={classes.optionBox}>
                <Typography>{option}</Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};

const HelpbotView: React.FC = () => {
  const classes = useStyles();
  const [message, setMessage] = useState('');
  const [chatHistory, setChatHistory] = useState<HelpMessage[]>([]);
  const [loading, setLoading] = useState(false);
  const [showOptions, setShowOptions] = useState(true);
  const endOfMessagesRef = useRef<HTMLDivElement | null>(null);

  const [ticketCreated, setTicketCreated] = useState(false);
  const [ticketMessage, setTicketMessage] = useState('');
  const [creatingTicket, setCreatingTicket] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);

  const handleSend = async (msg: string) => {
    if (msg.trim() === '') return;

    setShowOptions(false);

    const newMessage: HelpMessage = { role: 'user', content: msg };
    setChatHistory((prevHistory) => [...prevHistory, newMessage]);
    setMessage('');
    setLoading(true);

    const requestPayload: HelpRequest = {
      message: msg,
      session_id: sessionId,
    };

    try {
      const response: HelpResponse = await sendMessageToHelpbot(requestPayload);
      const botMessage: HelpMessage = {
        role: 'bot',
        content: response.response,
      };
      setChatHistory((prevHistory) => [...prevHistory, botMessage]);
    } finally {
      setLoading(false);
    }
  };

  const handleFeedback = (feedback: string) => {
    handleSend(feedback);
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSend(message);
    }
  };

  const handleCreateTicket = async () => {
    setCreatingTicket(true);
    try {
      const response = await createSupportTicket(sessionId);
      setTicketMessage(response.message);
      setTicketCreated(true);
      setShowSnackbar(true);
    } catch (error) {
      setTicketMessage(
        'Failed to create support ticket. Please try again later.',
      );
      setTicketCreated(false);
      setShowSnackbar(true);
    } finally {
      setCreatingTicket(false);
    }
  };

  useEffect(() => {
    if (endOfMessagesRef.current) {
      endOfMessagesRef.current.scrollIntoView({ behavior: 'smooth' });
    }

    setTicketCreated(false);
  }, [chatHistory]);

  return (
    <Container className={classes.container}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Box />
        <Button
          color="primary"
          variant="contained"
          onClick={handleCreateTicket}
          // we should disable the button if theres no chat history
          disabled={creatingTicket || chatHistory.length === 0}
        >
          {creatingTicket ? 'Creating Ticket...' : 'Speak to a human'}
        </Button>
      </Box>

      {showOptions && (
        <OptionBoxes
          onSelect={(selectedOption) => {
            handleSend(selectedOption);
          }}
        />
      )}
      <Box className={classes.chatBox}>
        <List>
          {chatHistory.map((chat, index) => (
            <React.Fragment key={index}>
              {chat.role === 'user' ? (
                <ListItem className={classes.messageBox}>
                  <Typography className={classes.messageHeader}>
                    {user?.name}
                  </Typography>
                  <Box className={classes.userMessage}>
                    <Typography>{chat.content}</Typography>
                  </Box>
                </ListItem>
              ) : (
                <ListItem className={classes.messageBox}>
                  <Typography
                    className={`${classes.messageHeader} ${classes.botHeader}`}
                  >
                    Talexio Bot
                  </Typography>
                  <Box className={classes.botMessage}>
                    <ReactMarkdown>{chat.content}</ReactMarkdown>
                    <div className={classes.feedbackButtonsBox}>
                      <IconButton
                        classes={{ root: classes.iconButtonRoot }}
                        onClick={() =>
                          handleFeedback(
                            'This response was correct and informative.',
                          )
                        }
                      >
                        <ThumbUpOutlined color="primary" />
                      </IconButton>
                      <IconButton
                        classes={{ root: classes.iconButtonRoot }}
                        onClick={() =>
                          handleFeedback(
                            'This response was incorrect, uninformative, inaccurate or an error.',
                          )
                        }
                      >
                        <ThumbDownOutlined color="primary" />
                      </IconButton>
                    </div>
                  </Box>
                </ListItem>
              )}
            </React.Fragment>
          ))}
          {loading && (
            <ListItem className={classes.messageBox}>
              <CircularProgress className={classes.loader} />
            </ListItem>
          )}
          <div ref={endOfMessagesRef} />
        </List>
      </Box>
      <Box className={classes.inputBox}>
        <TextField
          className={classes.inputField}
          fullWidth
          autoFocus
          variant="outlined"
          value={message}
          placeholder="Message Talexio Bot"
          onChange={(e) => setMessage(e.target.value)}
          onKeyPress={handleKeyPress}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            endAdornment: (
              <IconButton
                type="submit"
                size="small"
                disabled={loading}
                onClick={() => handleSend(message)}
              >
                <Send />
              </IconButton>
            ),
            style: {
              borderColor: '#FD6A68',
            },
          }}
        />
      </Box>
      <Typography className={classes.footer}>
        This bot can make mistakes. Do <strong>NOT</strong> base any important
        decisions solely on the information provided by this tool.
      </Typography>

      <Typography className={classes.footer}>
        Please report any issues to insights@exp42.com!
      </Typography>

      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={() => setShowSnackbar(false)}
      >
        <Alert
          onClose={() => setShowSnackbar(false)}
          severity={ticketCreated ? 'success' : 'error'}
          variant="filled"
        >
          {ticketMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default HelpbotView;
