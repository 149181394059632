import { CircularProgress, Fade } from '@material-ui/core';
import { CircularProgressProps } from '@material-ui/core/CircularProgress';
import { useTheme } from '@material-ui/core';
import * as React from 'react';

interface Props extends CircularProgressProps {
  loading?: boolean;
}

// https://www.nngroup.com/articles/response-times-3-important-limits/
export const DelayedCircularProgress: React.FC<Props> = ({
  loading = true,
  ...props
}) => {
  const { loadingDelay } = useTheme();

  return (
    <Fade style={{ transitionDelay: loadingDelay }} in={loading}>
      <CircularProgress {...props} />
    </Fade>
  );
};
