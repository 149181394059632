import DateFnsUtils from '@date-io/date-fns';
import {
  DatePicker as MaterialDatePicker,
  DatePickerProps,
  KeyboardDatePicker,
  KeyboardDatePickerProps,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { isValid } from 'date-fns';
import * as React from 'react';
import { DateFormat } from '../../config';

type DatePickerComponentProps = KeyboardDatePickerProps | DatePickerProps;

export const DatePicker: React.FC<
  DatePickerComponentProps & {
    disableKeyboardInput?: boolean;
  }
> = ({
  value,
  format = DateFormat.SHORT,
  onChange,
  disableKeyboardInput,
  ...rest
}) => {
  const [error, setError] = React.useState(false);
  const [shouldShowError, setShouldShowError] = React.useState(false);

  return disableKeyboardInput ? (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <MaterialDatePicker
        format={format}
        value={value || null}
        clearLabel={'Clear'}
        okLabel={'Ok'}
        cancelLabel={'Cancel'}
        todayLabel={'Today'}
        placeholder={format && format.toLowerCase()}
        autoOk
        onChange={onChange}
        {...rest}
      />
    </MuiPickersUtilsProvider>
  ) : (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        format={format}
        error={shouldShowError && error}
        value={value || null}
        clearLabel={'Clear'}
        okLabel={'Ok'}
        cancelLabel={'Cancel'}
        todayLabel={'Today'}
        placeholder={format && format.toLowerCase()}
        helperText={shouldShowError && error ? 'Invalid date' : ''}
        FormHelperTextProps={{ error: true }}
        onBlur={() => setShouldShowError(true)}
        onFocus={() => setShouldShowError(false)}
        onChange={(date, value) => {
          if (!date || isValid(date)) {
            setError(false);
            onChange(date, value);
          } else {
            setError(true);
          }
        }}
        autoOk
        {...rest}
      />
    </MuiPickersUtilsProvider>
  );
};
