import {
  Card,
  CardHeader,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { PageableTable } from 'components';
import { DateDisplay } from 'components/DateDisplay/DateDisplay';
import { PageableTableHeader } from 'components/PageableTable/components';
import { RelatedCompanyChip } from 'components/RelatedCompanyChip';
import {
  useLocalStorageSettings,
  TableSettings,
  getTableSettingsStorageKey,
} from 'hooks';
import { fetchCalls } from 'lib/Api/Activity';
import { PagedCalls } from 'lib/Model/Activity';
import * as React from 'react';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: theme.spacing(2),
  },
  dateColumn: {
    width: 60,
    textAlign: 'center',
    paddingRight: 0,
  },
  keyHeading: {
    fontWeight: 500,
    textTransform: 'capitalize',
  },
  keyValue: {},
  keyRow: {
    marginBottom: theme.spacing(1),
  },
  attendeesPopper: {
    width: 350,
    background: theme.palette.common.white,
  },
  attendeeList: {
    maxHeight: 300,
    overflow: 'auto',
  },
  time: {
    fontSize: 24,
    textAlign: 'right',
  },
}));

interface Props {
  companyId: string;
  withRelated: boolean;
}

export const Calls: React.FC<Props> = ({ companyId, withRelated }) => {
  const [loading, setLoading] = React.useState(false);

  const classes = useStyles();
  const [pageNumber, setPageNumber] = React.useState(0);
  const { pathname, hash } = useLocation();
  const [tableSettings, setTableSettings] =
    useLocalStorageSettings<TableSettings>(
      getTableSettingsStorageKey(pathname, hash),
      { rowsPerPage: 25 },
    );

  const [data, setData] = React.useState<PagedCalls | undefined>();

  const fetchData = React.useCallback(() => {
    setLoading(true);
    fetchCalls(
      companyId,
      pageNumber + 1,
      tableSettings.rowsPerPage,
      withRelated,
    ).then((d) => {
      setLoading(false);
      setData(d);
    });
  }, [
    setLoading,
    setData,
    pageNumber,
    tableSettings.rowsPerPage,
    companyId,
    withRelated,
  ]);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  const columns: PageableTableHeader[] = [
    {
      key: 'date',
      label: '',
      sortable: false,
    },
    {
      key: 'type',
      label: 'Type',
      sortable: false,
    },
    {
      key: 'summary',
      label: '',
      sortable: false,
    },
    {
      key: 'duration',
      label: '',
      sortable: false,
    },
  ];

  return (
    <Grid container>
      <Grid item xs={12}>
        <Card className={classes.card}>
          <CardHeader
            title="Calls"
            subheader="Calls with the client as tracked by our business development team."
          />
          <PageableTable
            tableSettings={tableSettings}
            setTableSettings={setTableSettings}
            showSkeletonLoading
            hideColumns={true}
            rows={
              data?.data?.map((d, index) => ({
                key: index.toString(),
                cells: [
                  {
                    key: 'date',
                    display: d.made_at && <DateDisplay date={d.made_at} />,
                    props: {
                      className: classes.dateColumn,
                    },
                  },
                  {
                    key: 'summary',
                    display: (
                      <React.Fragment>
                        <div>
                          <Typography variant="h6">
                            {d.call_handled_by} - {d.contact_person}
                          </Typography>
                          {d.call_summary && (
                            <Typography variant="body1">
                              Summary: {d.call_summary}
                            </Typography>
                          )}
                          {d.call_outcome && (
                            <Typography variant="body1">
                              Outcome: {d.call_outcome}
                            </Typography>
                          )}
                        </div>
                        {withRelated && (
                          <RelatedCompanyChip
                            companyId={d.company_id}
                            companyName={d.company_title}
                          />
                        )}
                      </React.Fragment>
                    ),
                  },
                  {
                    key: 'duration',
                    display:
                      d.call_duration <= 0
                        ? 'No answer'
                        : toHHMMSS(d.call_duration),
                    props: {
                      className: classes.time,
                    },
                  },
                ],
              })) ?? []
            }
            loading={loading}
            pageNumber={pageNumber}
            rowCount={data?.total_count ?? 0}
            columns={columns}
            onChangePage={setPageNumber}
          />
        </Card>
      </Grid>
    </Grid>
  );
};

function toHHMMSS(sec_num: number, hideEmptyHrs?: boolean) {
  let hours: number | string = Math.floor(sec_num / 3600);
  let minutes: number | string = Math.floor((sec_num - hours * 3600) / 60);
  let seconds: number | string = Math.floor(
    sec_num - hours * 3600 - minutes * 60,
  );

  if (hours < 10) {
    hours = '0' + hours;
  }
  if (minutes < 10) {
    minutes = '0' + minutes;
  }
  if (seconds < 10) {
    seconds = '0' + seconds;
  }
  if (hours === '00' && hideEmptyHrs) {
    return `${minutes}:${seconds}`;
  }
  return `${hours}:${minutes}:${seconds}`;
}
