import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    minHeight: '100%',
    position: 'relative',
    justifyContent: 'center',
    backgroundSize: 'cover',
    paddingBottom: 80,
    margin: '0px auto',
  },
  hideOverflow: {
    overflow: 'hidden',
  },
  authContainer: {
    width: 'auto',
    [theme.breakpoints.up('sm')]: {
      width: 400,
    },
  },
  quotationContainer: {
    maxWidth: 600,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 500,
    },
  },
  loadingWrapper: {
    display: 'flex',
    height: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logoContainer: {
    textAlign: 'center',
    margin: theme.spacing(2, 0, 1, 0),
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(6, 0, 4, 0),
    },
  },
  logo: {
    height: 30,
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(2),
  },
  spaceBetween: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  flexEnd: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  centered: {
    display: 'flex',
    justifyContent: 'center',
  },
  jimBrandingCirclesContainerRegister: {
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(10),
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(21),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(25),
    },
  },
  jimBrandingCirclesContainerDomainLogin: {
    paddingTop: theme.spacing(35),
  },
  jimCircle: {
    position: 'absolute',
    zIndex: -1,
  },
  jimCirclePosition1a: {
    bottom: '-125px',
    left: '-140px',
  },
  jimCirclePosition1b: {
    bottom: '-30px',
    left: '-35px',
  },
  jimCirclePosition2: {
    bottom: '200px',
    left: '40px',
  },
  jimCirclePosition3: {
    bottom: '160px',
    left: '160px',
  },
  jimCirclePosition4: {
    bottom: '-130px',
    right: '170px',
  },
  jimCirclePosition5: {
    bottom: '90px',
    right: '20px',
  },
}));
