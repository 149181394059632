import { AppBar, Grid, Toolbar, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { HelpPopper } from 'components';
import { useDocumentTitle } from 'hooks';
import { fetchNpsScore } from 'lib/Api/Nps';
import { NpsMetrics, NpsResponse } from 'lib/Model/Nps';
import * as React from 'react';
import { NpsView } from 'views/NPS';

interface Props {
  companyId: string;
}

export const ClientNpsView: React.FC<Props> = ({ companyId }) => {
  const [loadingNpsScore, setLoadingNpsScore] = React.useState(false);

  useDocumentTitle('Net Promoter Score');
  const [npsDialogOpen, setNpsDialogOpen] = React.useState(false);
  const [scoreData, setScoreData] = React.useState<NpsMetrics | undefined>();
  const [selectedNpsResponse, setSelectedNpsReponse] = React.useState<
    NpsResponse | undefined
  >();

  React.useEffect(() => {
    setSelectedNpsReponse(undefined);
  }, [setSelectedNpsReponse]);

  const fetchScoreData = React.useCallback(() => {
    setLoadingNpsScore(true);
    fetchNpsScore(companyId)
      .then((d) => {
        setLoadingNpsScore(false);
        setScoreData(d);
      })
      .catch((e) => {
        // do nothing
        setLoadingNpsScore(false);
        setScoreData({
          detractors: 0,
          promoters: 0,
          neutrals: 0,
          nps_score: 0,
          response_rate: 0,
        });
      });
  }, [setLoadingNpsScore, setScoreData, companyId]);

  React.useEffect(() => {
    fetchScoreData();
  }, [fetchScoreData]);

  React.useEffect(() => {
    if (!selectedNpsResponse) {
      return;
    }
    setNpsDialogOpen(true);
  }, [selectedNpsResponse, setNpsDialogOpen]);

  React.useEffect(() => {
    if (npsDialogOpen) {
      return;
    }
    setSelectedNpsReponse(undefined);
  }, [npsDialogOpen, setSelectedNpsReponse]);

  return (
    <React.Fragment>
      {loadingNpsScore ? (
        <Skeleton variant="rect" style={{ height: 50, marginBottom: 10 }} />
      ) : (
        // TODO: Ask Mark how to handle 404s in the Appbar
        <AppBar
          color="primary"
          position="static"
          style={{ textAlign: 'center', marginBottom: 8 }}
        >
          <Toolbar>
            <Grid container>
              <Grid item xs={4}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography
                    variant="h6"
                    style={{ paddingLeft: 4, marginRight: 4 }}
                  >
                    {scoreData?.nps_score}
                  </Typography>
                </div>
                <Typography variant="caption">Lifetime NPS</Typography>
                <HelpPopper style={{ padding: 8 }}>
                  <Typography variant="body2" gutterBottom>
                    This client's NPS figure is calculated for all brands,
                    considering all submissions. Filtering will be available in
                    a future update.
                  </Typography>
                </HelpPopper>
              </Grid>
              <Grid item xs={4}>
                <Grid container>
                  <Grid item xs={4}>
                    <Typography variant="h6">{scoreData?.promoters}</Typography>
                    <Typography variant="caption">Promoters</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="h6">{scoreData?.neutrals}</Typography>
                    <Typography variant="caption">Neutrals</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="h6">
                      {scoreData?.detractors}
                    </Typography>
                    <Typography variant="caption">Detractors</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="h6">
                  {scoreData?.response_rate}%
                </Typography>
                <Typography variant="caption">Response Rate</Typography>
                <HelpPopper style={{ padding: 8 }}>
                  <Typography variant="body2" gutterBottom>
                    This response rate figure currently applies to Konnekt and
                    jobsinmalta submissions.
                  </Typography>
                </HelpPopper>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      )}
      <NpsView filteredCompanyId={companyId} />
    </React.Fragment>
  );
};
