import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import { DatePicker, SnackbarContext } from 'components';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { DateTimeFormat } from 'config';
import { format, parseISO } from 'date-fns';
import { updateComment } from 'lib/Api/Nps';
import { NpsComment, NpsLeader, NpsResponse } from 'lib/Model/Nps';
import * as React from 'react';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { grey } from '@material-ui/core/colors';
import { usePrevious } from 'hooks';
import { Autocomplete } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  commentContent: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    backgroundColor: grey[200],
    padding: theme.spacing(2),
  },
  accordion: {
    background: 'none',
    border: 0,
    padding: 0,
    '&::before': {
      background: 'none',
    },
  },
  accordionSummary: {
    padding: 0,
    margin: 0,
  },
  accordionDetails: {
    padding: 0,
  },
  actions: {
    textAlign: 'right',
  },
}));

interface Props {
  comment: NpsComment;
  selectedNpsResponse: NpsResponse;
  canEdit: boolean;
  fetchComments: () => void;
  reviewees: NpsLeader[];
}

export const NpsCommentBox: React.FC<Props> = ({
  comment,
  selectedNpsResponse,
  canEdit,
  fetchComments,
  reviewees,
}) => {
  const snackbar = React.useContext(SnackbarContext);
  const classes = useStyles();
  const [actionNeeded, setActionNeeded] = React.useState<boolean>(
    Boolean(comment.action_needed),
  );
  const [actionBy, setActionBy] = React.useState<string | undefined>(
    comment.action_by,
  );
  const prevComment = usePrevious(comment);
  const [completedBy, setCompletedBy] = React.useState<string | undefined>(
    comment.completed_by,
  );
  const [outcomeComment, setOutcomeComment] = React.useState<
    string | undefined
  >(comment.outcome_comment);
  const [content, setContent] = React.useState<string>(comment.content);

  React.useEffect(() => {
    if (comment !== prevComment) {
      setActionBy(comment.action_by);
      setCompletedBy(comment.completed_by);
      setActionNeeded(Boolean(comment.action_needed));
      setOutcomeComment(comment.outcome_comment);
      setContent(comment.content);
    }
  }, [comment, prevComment]);

  const handleSaveChanges = async () => {
    if (selectedNpsResponse.platform_nps_id) {
      try {
        await updateComment(
          selectedNpsResponse.platform_nps_id,
          comment.id,
          outcomeComment,
          actionBy,
          completedBy,
          actionNeeded ?? false,
          content,
        );
        snackbar.success('Comment updated.');
        fetchComments();
      } catch (e) {
        snackbar.error(`Failed to update comment: ${e}`);
      }
    }
  };

  return (
    <div className={classes.commentContent}>
      <Box justifyContent="space-between" alignItems="start">
        <div>
          <Typography variant="caption">
            {comment.created_on &&
              format(
                parseISO(comment.created_on),
                DateTimeFormat.MEDIUM_FRIENDLY,
              )}
          </Typography>
          <TextField
            variant="outlined"
            fullWidth
            multiline
            disabled={!canEdit}
            value={content}
            onChange={(e) => setContent(e.target.value)}
          />
          <Typography variant="caption">
            Author:{' '}
            {!Boolean(comment.created_by)
              ? `${selectedNpsResponse.user_first_name} ${selectedNpsResponse.user_last_name}` ??
                'N/A'
              : comment.created_by}
          </Typography>
        </div>
      </Box>
      <Accordion className={classes.accordion} elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          className={classes.accordionSummary}
        >
          <FormControlLabel
            control={
              <Checkbox
                disabled={!canEdit}
                checked={actionNeeded}
                onChange={(e, checked) => {
                  setActionNeeded(checked);
                }}
              />
            }
            label="Requires Action"
          />
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <DatePicker
                label="Completed On"
                inputVariant="outlined"
                InputLabelProps={{ shrink: true }}
                size="small"
                fullWidth
                format="dd/MM/yyyy"
                value={completedBy}
                disabled={!canEdit}
                onChange={(date: MaterialUiPickersDate) => {
                  setCompletedBy(date ? date.toISOString() : undefined);
                }}
              />
            </Grid>
            <Grid item xs={6}>
              {reviewees.length > 0 && (
                <Autocomplete
                  getOptionLabel={(option) => option.reviewee}
                  options={reviewees}
                  disabled={!canEdit}
                  renderInput={(params) => (
                    <TextField
                      variant="outlined"
                      label="Action By"
                      {...params}
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      size="small"
                    />
                  )}
                  disableClearable
                  value={
                    reviewees.find(
                      (reviewee) => reviewee.reviewee === actionBy,
                    ) || undefined
                  }
                  onChange={(e, newValue) => {
                    setActionBy(newValue?.reviewee);
                  }}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Outcome Comment"
                variant="outlined"
                size="small"
                fullWidth
                multiline
                disabled={!canEdit}
                InputLabelProps={{ shrink: true }}
                value={outcomeComment}
                onChange={(e) => setOutcomeComment(e.target.value)}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      {canEdit && (
        <Grid item xs={12} className={classes.actions}>
          <Button variant="text" color="primary" onClick={handleSaveChanges}>
            Save Comment
          </Button>
        </Grid>
      )}
    </div>
  );
};
