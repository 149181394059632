import {
  Button,
  Card,
  CardHeader,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  makeStyles,
  Switch,
  Typography,
} from '@material-ui/core';
import { History, RemoveRedEye } from '@material-ui/icons';
import classNames from 'classnames';
import { PageableTable } from 'components';
import { DateDisplay } from 'components/DateDisplay/DateDisplay';
import { PageableTableHeader } from 'components/PageableTable/components';
import { RelatedCompanyChip } from 'components/RelatedCompanyChip';
import { DateFormat } from 'config';
import { format, parseISO } from 'date-fns';
import { toMonetary } from 'lib';
import { fetchDealDetails, fetchDealAudit, fetchDeals } from 'lib/Api/Deals';
import { Deal, DealAudit, PagedDeal } from 'lib/Model/Deal';
import * as React from 'react';
import DealDetailsDialog from './DealDetailsDialog';
import DealAuditDialog from './DealAuditDialog';
import {
  useLocalStorageSettings,
  TableSettings,
  getTableSettingsStorageKey,
} from 'hooks';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: theme.spacing(2),
  },
  clickable: {
    cursor: 'pointer',
    color: theme.palette.secondary.main,
    textAlign: 'left',
  },
  boldTitle: {
    fontWeight: 500,
    padding: 0,
    clear: 'both',
    display: 'block',
    cursor: 'default',
  },
  dateColumn: {
    width: 110,
    textAlign: 'center',
  },
  leaderColumn: {
    width: 120,
  },
  valueColumn: {
    width: 110,
  },
  iconColumn: {
    width: 40,
  },
  successColumn: {
    width: 150,
  },
  amountColumn: {
    width: 200,
  },
  stageColumn: {
    width: 200,
  },
  titleColumn: {
    width: 250,
  },
}));

interface Props {
  companyId: string;
  withRelated: boolean;
}

export const Deals: React.FC<Props> = ({ companyId, withRelated }) => {
  const [loading, setLoading] = React.useState(false);
  const classes = useStyles();
  const [pageNumber, setPageNumber] = React.useState(0);
  const { pathname, hash } = useLocation();
  const [tableSettings, setTableSettings] =
    useLocalStorageSettings<TableSettings>(
      getTableSettingsStorageKey(pathname, hash),
      { rowsPerPage: 25 },
    );

  const [data, setData] = React.useState<PagedDeal | undefined>();
  const [hideClosedDeals, setHideClosedDeals] = React.useState(false);
  const [viewMoreDialogOpen, setViewMoreDialogOpen] = React.useState(false);
  const [dialogDataLoading, setDialogDataLoading] = React.useState(false);
  const [selectedDeal, setSelectedDeal] = React.useState<Deal[]>();
  const [dealHistoryDialogOpen, setDealHistoryDialogOpen] =
    React.useState(false);
  const [selectedDealHistory, setSelectedDealHistory] = React.useState<
    DealAudit[]
  >([]);

  const fetchData = React.useCallback(() => {
    setLoading(true);
    fetchDeals(
      companyId,
      pageNumber + 1,
      tableSettings.rowsPerPage,
      hideClosedDeals,
      withRelated,
    ).then((d) => {
      setLoading(false);
      setData(d);
    });
  }, [
    setLoading,
    setData,
    pageNumber,
    tableSettings.rowsPerPage,
    companyId,
    hideClosedDeals,
    withRelated,
  ]);

  const handleOpenViewMoreDialog = async (dealId: string) => {
    setViewMoreDialogOpen(true);
    setDialogDataLoading(true);
    const dealDetails = await fetchDealDetails(dealId);
    setSelectedDeal(dealDetails);
    setDialogDataLoading(false);
  };

  const handleOpenDealHistoryDialog = async (dealId: string) => {
    setDialogDataLoading(true);
    setDealHistoryDialogOpen(true);

    try {
      const auditData = await fetchDealAudit(dealId);
      setSelectedDealHistory(auditData);
    } finally {
      setDialogDataLoading(false);
    }
  };
  const handleCloseDialog = () => {
    setViewMoreDialogOpen(false);
    setDealHistoryDialogOpen(false);
  };

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  const columns: PageableTableHeader[] = [
    {
      key: 'dateCreated',
      label: 'Date created',
      sortable: false,
      props: {
        className: classes.dateColumn,
      },
    },
    {
      key: 'title',
      label: 'Deal name',
      sortable: false,
      props: {
        className: classes.titleColumn,
      },
    },
    {
      key: 'service',
      label: 'Service',
      sortable: false,
    },
    {
      key: 'product',
      label: 'Product(s)',
      sortable: false,
    },
    {
      key: 'stage',
      label: 'Stage',
      sortable: false,
      props: {
        className: classes.stageColumn,
      },
    },
    {
      key: 'owner',
      label: 'Owner',
      sortable: false,
      props: {
        className: classes.leaderColumn,
      },
    },
    {
      key: 'value',
      label: 'Deal Value',
      sortable: false,
      props: {
        className: classes.valueColumn,
      },
    },
    {
      key: 'viewMore',
      label: '',
      sortable: false,
      props: {
        className: classes.iconColumn,
      },
    },
    {
      key: 'history',
      label: '',
      sortable: false,
      props: {
        className: classes.iconColumn,
      },
    },
  ];

  return (
    <Card className={classes.card}>
      <CardHeader
        title="Deals"
        subheader="Proposals tracked by our business development team. Click the deal name to view it on Zendesk Sell."
        style={{ paddingBottom: 0, marginBottom: 0 }}
      />
      <FormControl margin="normal" style={{ marginLeft: 20 }}>
        <FormControlLabel
          control={
            <Switch
              checked={hideClosedDeals}
              onChange={(e, checked) => {
                setHideClosedDeals(checked);
              }}
            />
          }
          label={'Hide closed deals'}
        />
      </FormControl>
      <Divider />
      <PageableTable
        tableSettings={tableSettings}
        setTableSettings={setTableSettings}
        showSkeletonLoading
        rows={
          data?.data?.map((d, index) => ({
            key: `job_${index}`,
            cells: [
              {
                key: 'dateCreated',
                display: <DateDisplay date={d.created_at} />,
              },
              {
                key: 'title',
                display: (
                  <React.Fragment>
                    <div>
                      <Button
                        className={classNames(
                          d.deal_url
                            ? [classes.boldTitle, classes.clickable]
                            : [classes.boldTitle],
                        )}
                        variant="text"
                        onClick={
                          d.deal_url
                            ? () => {
                                window.open(d.deal_url);
                              }
                            : undefined
                        }
                      >
                        {d.deal_name}
                      </Button>
                    </div>
                    <div>
                      <Typography variant="caption">Deal ID: {d.id}</Typography>
                    </div>
                    {withRelated && (
                      <RelatedCompanyChip
                        companyId={d.company_id}
                        companyName={d.company_title}
                      />
                    )}
                  </React.Fragment>
                ),
              },
              {
                key: 'service',
                display: d.deal_service,
              },
              {
                key: 'product',
                display: (
                  <React.Fragment>
                    {d.vacancies?.length
                      ? d.vacancies.map((v) => (
                          <Chip style={{ margin: 2 }} label={v} size="small" />
                        ))
                      : d.deal_products?.map((product) => {
                          return (
                            <Chip
                              style={{ margin: 2 }}
                              label={product}
                              size="small"
                            />
                          );
                        })}
                  </React.Fragment>
                ),
              },
              {
                key: 'stage',
                display: (
                  <React.Fragment>
                    <div>
                      <Typography
                        variant="body1"
                        style={{
                          color:
                            d.stage === 'Won'
                              ? 'green'
                              : ['Lost', 'Unqualified'].includes(d.stage)
                              ? 'red'
                              : 'teal',
                          textTransform: 'uppercase',
                          fontWeight: 500,
                        }}
                      >
                        {d.stage.toUpperCase()}
                      </Typography>
                    </div>
                    <div>
                      <Typography variant="caption">{d.loss_reason}</Typography>
                    </div>
                    <div>
                      <Typography variant="caption">
                        {d.last_stage_change_date &&
                          `Updated on: ${format(
                            parseISO(d.last_stage_change_date),
                            DateFormat.SHORT,
                          )}`}
                      </Typography>
                    </div>
                  </React.Fragment>
                ),
              },
              {
                key: 'owner',
                display: d.owner,
              },
              {
                key: 'value',
                display: toMonetary(d.deal_value ?? 0),
              },
              {
                key: 'viewMore',
                display: (
                  <IconButton
                    onClick={() => handleOpenViewMoreDialog(d.id)}
                    style={{
                      cursor: 'pointer',
                    }}
                    color="primary"
                  >
                    <RemoveRedEye />
                  </IconButton>
                ),
              },
              {
                key: 'history',
                display: (
                  <IconButton
                    onClick={() => handleOpenDealHistoryDialog(d.id)}
                    style={{
                      cursor: 'pointer',
                    }}
                    color="primary"
                  >
                    <History />
                  </IconButton>
                ),
              },
            ],
          })) ?? []
        }
        loading={loading}
        pageNumber={pageNumber}
        rowCount={data?.total_count ?? 0}
        columns={columns}
        onChangePage={setPageNumber}
      />

      <DealDetailsDialog
        open={viewMoreDialogOpen}
        deal={selectedDeal!}
        loading={dialogDataLoading}
        onClose={handleCloseDialog}
      />
      <DealAuditDialog
        open={dealHistoryDialogOpen}
        dealAudit={selectedDealHistory}
        loading={dialogDataLoading}
        onClose={handleCloseDialog}
      />
    </Card>
  );
};
