import { Typography } from '@material-ui/core';
import { Variant } from '@material-ui/core/styles/createTypography';
import { Alert } from '@material-ui/lab';
import * as React from 'react';
import { ErrorStack } from './ErrorStack';

interface Props {
  error: Error;
  showStack?: boolean;
  useAlertContainer?: boolean;
  variant?: Variant;
  className?: string;
}

export const ErrorMessage: React.FC<Props> = ({
  error,
  showStack = false,
  useAlertContainer = false,
  variant,
  className,
}) => {
  const message = (
    <React.Fragment>
      <Typography
        color="error"
        component="span"
        variant={variant}
        className={className}
      >
        {error.message}
      </Typography>

      {showStack && error.stack && <ErrorStack stack={error.stack} />}
    </React.Fragment>
  );

  if (useAlertContainer) {
    return <Alert severity="error">{message}</Alert>;
  }

  return message;
};
