import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  FormControl,
  FormControlLabel,
  Grid,
  Snackbar,
  Switch,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Alert, Autocomplete, Skeleton } from '@material-ui/lab';
import PersonIcon from '@material-ui/icons/Person';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { PageableTable } from 'components';
import { DateDisplay } from 'components/DateDisplay/DateDisplay';
import { PageableTableHeader } from 'components/PageableTable/components';
import { EmptyView } from 'components';
import {
  getTableSettingsStorageKey,
  TableSettings,
  useDebounce,
  useLocalStorageSettings,
} from 'hooks';
import {
  fetchAllocationClients,
  fetchAllocationIndustries,
  identifyCam,
  assignCam,
  fetchAllocationHistory,
  fetchCurrentAllocations,
} from 'lib/Api/ClientAllocation';
import {
  AllocationCompany,
  AllocationIndustry,
  CurrentAllocations,
  PagedHistory,
  identifyCamResults,
} from 'lib/Model/ClientAllocation';
import React from 'react';
import AllocationDialog from './CurrentAllocationsDialog';
import { getUserData } from 'lib/Helper/Auth';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginBottom: theme.spacing(2),
  },
  alertMessage: {
    marginTop: theme.spacing(1),
  },
  searchAllocation: {
    padding: theme.spacing(1),
  },
  icon: {
    verticalAlign: 'bottom',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

const ClientAllocationView: React.FC = () => {
  const classes = useStyles();

  const [camData, setCamData] = React.useState<identifyCamResults | null>(null);
  const [loadingCamData, setLoadingCamData] = React.useState(false);
  const [loadingAssignCamData, setLoadingAssignCamData] = React.useState(false);

  const [loadingCompanies, setLoadingCompanies] = React.useState(false);
  const [loadingIndustries, setLoadingIndustries] = React.useState(false);
  const [loadingHistory, setLoadingHistory] = React.useState(false);

  const [companies, setCompanies] = React.useState<
    AllocationCompany[] | undefined
  >();
  const [selectedCompany, setSelectedCompany] =
    React.useState<AllocationCompany | null>(null);

  const [industries, setIndustries] = React.useState<
    AllocationIndustry[] | undefined
  >();
  const [selectedIndustry, setSelectedInudstry] =
    React.useState<AllocationIndustry | null>(null);

  const [showNewCompany, setShowNewCompany] = React.useState(false);
  const [newCompanyName, setNewCompanyName] = React.useState('');

  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');

  const [showAssignmentAlert, setShowAssignmentAlert] = React.useState(false);
  const [assignmentAlertMessage, setAssignmentAlertMessage] =
    React.useState('');

  const [product, setProduct] = React.useState<string | undefined>();
  const [headcount, setHeadcount] = React.useState<string | undefined>();
  const [history, setHistory] = React.useState<PagedHistory | undefined>();
  const [textSearch, setTextSearch] = React.useState<string | undefined>();
  const debouncedTextSearch = useDebounce(textSearch, 400);

  const [pageNumber, setPageNumber] = React.useState(0);
  const { pathname, hash } = useLocation();
  const [tableSettings, setTableSettings] =
    useLocalStorageSettings<TableSettings>(
      getTableSettingsStorageKey(pathname, hash),
      { rowsPerPage: 25 },
    );

  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [currentAllocations, setCurrentAllocations] = React.useState<
    CurrentAllocations[]
  >([]);
  const [loadingAllocations, setLoadingAllocations] = React.useState(false);

  const user = getUserData();
  const canView = user?.permissions?.includes('client_allocation') || false;

  const columns: PageableTableHeader[] = [
    {
      key: 'date',
      label: 'Assigned On',
      sortable: false,
    },
    {
      key: 'company_title',
      label: 'Company Title',
      sortable: false,
    },
    {
      key: 'industry',
      label: 'Industry',
      sortable: false,
    },
    {
      key: 'headcount',
      label: 'Headcount',
      sortable: false,
    },
    {
      key: 'product',
      label: 'Product',
      sortable: false,
    },
    {
      key: 'cam',
      label: 'CAM',
      sortable: false,
    },
    {
      key: 'created_by',
      label: 'Assigned By',
      sortable: false,
    },
  ];

  const handleOpenDialog = () => {
    setLoadingAllocations(true);
    fetchCurrentAllocations().then((data) => {
      setCurrentAllocations(data);
      setLoadingAllocations(false);
      setDialogOpen(true);
    });
  };

  const handleAssign = async () => {
    if (!camData || !selectedIndustry || !product || !headcount) {
      return;
    }

    const companyTitle = selectedCompany
      ? selectedCompany.company_title
      : newCompanyName;
    const camId = camData.cam;
    const isExistingClient = Boolean(selectedCompany);

    setLoadingAssignCamData(true);
    try {
      await assignCam(
        camId,
        product,
        headcount,
        selectedIndustry.industry_title,
        camData.overridden_from_exp42_admin,
        isExistingClient,
        companyTitle,
        camData.overridden_from_cas,
      );
      setSnackbarMessage('Agent successfully assigned.');
      setAssignmentAlertMessage(
        'Proceed to Zendesk Sell to assign the agent to the client.',
      );
      setShowAssignmentAlert(true);

      // fetch the history data so it gets freshly updated
      fetchAllocationHistoryData();

      // reset states of the dropdowns
      setSelectedCompany(null);
      setShowNewCompany(false);
      setNewCompanyName('');
      setSelectedInudstry(null);
      setProduct(undefined);
      setHeadcount(undefined);
      setCamData(null);
    } catch (error) {
      setSnackbarMessage('Failed to assign agent.');
      setShowAssignmentAlert(true);
    } finally {
      setLoadingAssignCamData(false);
      setSnackbarOpen(true);
    }
  };

  const fetchCompanies = React.useCallback(() => {
    setLoadingCompanies(true);
    fetchAllocationClients().then((d) => {
      setCompanies(d.data);
      setLoadingCompanies(false);
    });
  }, [setLoadingCompanies, setCompanies]);

  const fetchIndustries = React.useCallback(() => {
    setLoadingIndustries(true);
    fetchAllocationIndustries().then((d) => {
      setIndustries(d.data);
      setLoadingIndustries(false);
    });
  }, [setLoadingIndustries, setIndustries]);

  const fetchAllocationHistoryData = React.useCallback(() => {
    setLoadingHistory(true);
    fetchAllocationHistory(
      pageNumber + 1,
      tableSettings.rowsPerPage,
      debouncedTextSearch,
    ).then((d) => {
      setLoadingHistory(false);
      setHistory(d);
    });
  }, [
    setLoadingHistory,
    setHistory,
    pageNumber,
    tableSettings.rowsPerPage,
    debouncedTextSearch,
  ]);

  React.useEffect(() => {
    if (!loadingCompanies && companies === undefined) {
      fetchCompanies();
    }
  }, [fetchCompanies, loadingCompanies, companies]);

  React.useEffect(() => {
    if (!loadingIndustries && industries === undefined) {
      fetchIndustries();
    }
  }, [fetchIndustries, loadingIndustries, industries]);

  React.useEffect(() => {
    if (
      selectedIndustry &&
      product &&
      headcount &&
      (selectedCompany || Boolean(newCompanyName))
    ) {
      setLoadingCamData(true);
      const companyId = selectedCompany
        ? selectedCompany.id.toString()
        : undefined;
      identifyCam(
        selectedIndustry.industry_title,
        headcount,
        product,
        companyId,
      )
        .then((response) => {
          setCamData(response.data);
        })
        .finally(() => {
          setLoadingCamData(false);
        });
    }
  }, [selectedCompany, selectedIndustry, product, headcount, newCompanyName]);

  React.useEffect(() => {
    fetchAllocationHistoryData();
  }, [
    fetchAllocationHistoryData,
    pageNumber,
    tableSettings.rowsPerPage,
    debouncedTextSearch,
  ]);

  return (
    <Grid container>
      {canView && (
        <>
          <Grid item xs={12}>
            <Typography variant="h6">Client Allocation Tool</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl className={classes.formControl} fullWidth>
              <Autocomplete
                getOptionLabel={(c) => c.company_title}
                options={companies ?? []}
                loading={loadingCompanies}
                disabled={loadingCompanies}
                renderInput={(params) => (
                  <TextField
                    variant="outlined"
                    label="Company"
                    {...params}
                    fullWidth
                    autoFocus
                    placeholder="Start typing the name of the client. If they exist, you can select it."
                    InputLabelProps={{ shrink: true }}
                  />
                )}
                value={selectedCompany ?? null}
                onChange={(e, newValue) => {
                  setSelectedCompany(newValue);
                  if (newValue) {
                    const industry = industries?.find(
                      (i) => i.industry_title === newValue.industry,
                    );
                    setSelectedInudstry(industry ?? null);

                    setHeadcount(newValue.headcount);
                  } else {
                    setSelectedInudstry(null);
                    setHeadcount(undefined);
                  }
                }}
              />
            </FormControl>

            {!selectedCompany && (
              <React.Fragment>
                <FormControl className={classes.formControl} fullWidth>
                  <FormControlLabel
                    control={
                      <Switch
                        onChange={(e, checked) => {
                          setShowNewCompany(checked);
                        }}
                        checked={showNewCompany}
                      />
                    }
                    label={'The company does not exist in our database yet.'}
                  />
                </FormControl>
                {showNewCompany && (
                  <FormControl className={classes.formControl} fullWidth>
                    <TextField
                      variant="outlined"
                      label="New Company Name"
                      fullWidth
                      value={newCompanyName}
                      onChange={(e) => setNewCompanyName(e.target.value)}
                      placeholder="Enter the name of the new company"
                      InputLabelProps={{ shrink: true }}
                    />
                  </FormControl>
                )}
              </React.Fragment>
            )}
            <FormControl className={classes.formControl} fullWidth>
              <Autocomplete
                getOptionLabel={(c) => c.industry_title}
                options={industries ?? []}
                loading={loadingIndustries}
                disabled={loadingIndustries}
                renderInput={(params) => (
                  <TextField
                    variant="outlined"
                    label="Industry"
                    {...params}
                    fullWidth
                    placeholder="Select the client industry"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
                value={selectedIndustry ?? null}
                onChange={(e, newValue) => {
                  setSelectedInudstry(newValue ?? null);
                }}
              />
            </FormControl>
            <FormControl className={classes.formControl} fullWidth>
              <Autocomplete
                options={[
                  '0-9 employees',
                  '10-49 employees',
                  '50-249 employees',
                  '250+ employees',
                  'Unknown',
                ]}
                renderInput={(params) => (
                  <TextField
                    variant="outlined"
                    label="Headcount"
                    {...params}
                    fullWidth
                    placeholder="Select the client employee headcount"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
                disableClearable={true}
                value={headcount ?? ''}
                onChange={(e, newValue) => {
                  setHeadcount(newValue);
                }}
              />
            </FormControl>
            <FormControl className={classes.formControl} fullWidth>
              <Autocomplete
                options={[
                  'jobsinmalta Single Listing',
                  'jobsinmalta Bulk Package',
                  'jobsinmalta Unlimited Package',
                  'Konnekt Recruitment',
                  'Talexio (HR)',
                  'Talexio (ATS)',
                  'Talexio Team Voice',
                  'Salariesinmalta',
                ]}
                renderInput={(params) => (
                  <TextField
                    variant="outlined"
                    label="Product"
                    {...params}
                    fullWidth
                    placeholder="Select the product the client is interested in"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
                disableClearable={true}
                value={product ?? ''}
                onChange={(e, newValue) => {
                  setProduct(newValue);
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card>
              <CardHeader
                title="Suggested Agent"
                subheader="This information will suggest which agent is recommended for the given client and its requested product."
                action={
                  <Button
                    size="small"
                    onClick={handleOpenDialog}
                    color="secondary"
                  >
                    View Current Allocations
                  </Button>
                }
              />

              <CardContent>
                {loadingCamData ? (
                  <React.Fragment>
                    <Skeleton variant="text" />
                    <Skeleton variant="text" />
                    <Skeleton variant="text" />
                  </React.Fragment>
                ) : !Boolean(camData) ? (
                  <EmptyView>
                    Start inputting information on the left to view the
                    suggested CAM for allocation.
                  </EmptyView>
                ) : (
                  <React.Fragment>
                    {camData?.current_cam && (
                      <Box mb={2}>
                        <Typography variant="body1">
                          <PersonIcon className={classes.icon} />
                          <Box component="span" fontWeight="fontWeightBold">
                            Current Owner:
                          </Box>{' '}
                          {camData.current_cam}
                        </Typography>
                      </Box>
                    )}
                    <Typography variant="body1">
                      <PersonAddIcon className={classes.icon} />
                      <Box component="span" fontWeight="fontWeightBold">
                        Suggested Owner:
                      </Box>{' '}
                      {camData?.cam}
                    </Typography>
                  </React.Fragment>
                )}
              </CardContent>

              <CardActions>
                <Button
                  type="button"
                  color="primary"
                  onClick={handleAssign}
                  disabled={loadingCamData || loadingAssignCamData}
                >
                  Log Assignment
                </Button>
              </CardActions>
            </Card>
            {showAssignmentAlert && (
              <Alert
                severity="info"
                onClose={() => setShowAssignmentAlert(false)}
                className={classes.alertMessage}
              >
                {assignmentAlertMessage}
              </Alert>
            )}
          </Grid>
        </>
      )}

      <Grid item xs={12}>
        <Card>
          <CardHeader
            title="Allocation History"
            subheader="A history of all allocation logs."
          />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                placeholder="Type here to search for a company or agent."
                value={textSearch}
                className={classes.searchAllocation}
                onChange={(e) => setTextSearch(e.target.value ?? '')}
                fullWidth
              />
            </Grid>
          </Grid>
          <PageableTable
            tableSettings={tableSettings}
            setTableSettings={setTableSettings}
            showSkeletonLoading
            hideColumns={false}
            rows={
              history?.data?.map((d, index) => ({
                key: index.toString(),
                cells: [
                  {
                    key: 'date',
                    display: d.created_on && (
                      <DateDisplay date={d.created_on} />
                    ),
                  },
                  {
                    key: 'company_title',
                    display: d.company_title,
                  },
                  {
                    key: 'industry',
                    display: (
                      <React.Fragment>
                        <div>{d.industry}</div>
                        <div>
                          <Typography variant="caption">
                            {d.team_specialisation}
                          </Typography>
                        </div>
                      </React.Fragment>
                    ),
                  },
                  {
                    key: 'headcount',
                    display: d.headcount,
                  },
                  {
                    key: 'product',
                    display: d.product_name,
                  },
                  {
                    key: 'cam',
                    display: d.CAM,
                  },
                  {
                    key: 'created_by',
                    display: d.created_by,
                  },
                ],
              })) ?? []
            }
            loading={loadingHistory}
            pageNumber={pageNumber}
            rowCount={history?.total_count ?? 0}
            columns={columns}
            onChangePage={setPageNumber}
          />
        </Card>
      </Grid>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        action={
          <Button
            color="secondary"
            size="small"
            onClick={() => setSnackbarOpen(false)}
          >
            CLOSE
          </Button>
        }
      />
      <AllocationDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        allocations={currentAllocations}
        loading={loadingAllocations}
      />
    </Grid>
  );
};

export default ClientAllocationView;
