import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  makeStyles,
  TextField,
} from '@material-ui/core';
import { PageableTable } from 'components';
import { DateDisplay } from 'components/DateDisplay/DateDisplay';
import { PageableTableHeader } from 'components/PageableTable/components';
import { RelatedCompanyChip } from 'components/RelatedCompanyChip';
import {
  getTableSettingsStorageKey,
  TableSettings,
  useDebounce,
  useLocalStorageSettings,
} from 'hooks';
import { fetchNotes } from 'lib/Api/Notes';
import { PagedNote } from 'lib/Model/Note';
import { resolvePlatformName } from './SuggestedMatches';
import * as React from 'react';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: theme.spacing(2),
  },
  dateColumn: {
    width: 120,
  },
  platformColumn: {
    width: 100,
  },
  valueColumn: {
    width: 150,
  },
  authorColumn: {
    width: 150,
  },
  amountColumn: {
    width: 200,
  },
}));

interface Props {
  companyId: string;
  withRelated: boolean;
  meetingNotes?: string;
}

export const Notes: React.FC<Props> = ({
  companyId,
  withRelated,
  meetingNotes,
}) => {
  const [loading, setLoading] = React.useState(false);
  const classes = useStyles();
  const [pageNumber, setPageNumber] = React.useState(0);
  // const [pageSize, setPageSize] = React.useState(10);
  const { pathname, hash } = useLocation();
  const [tableSettings, setTableSettings] =
    useLocalStorageSettings<TableSettings>(
      getTableSettingsStorageKey(pathname, hash),
      { rowsPerPage: 25 },
    );

  const [data, setData] = React.useState<PagedNote | undefined>();
  const [textSearch, setTextSearch] = React.useState<string | undefined>();

  const debouncedTextSearch = useDebounce(textSearch, 400);

  const fetchData = React.useCallback(() => {
    setLoading(true);
    fetchNotes(
      companyId,
      pageNumber + 1,
      tableSettings.rowsPerPage,
      withRelated,
      debouncedTextSearch,
    ).then((d) => {
      setLoading(false);
      setData(d);
    });
  }, [
    setLoading,
    setData,
    pageNumber,
    tableSettings.rowsPerPage,
    companyId,
    debouncedTextSearch,
    withRelated,
  ]);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  const columns: PageableTableHeader[] = [
    {
      key: 'dateCreated',
      label: 'Date created',
      sortable: false,
      props: {
        className: classes.dateColumn,
      },
    },
    {
      key: 'content',
      label: 'Content',
      sortable: false,
    },
    {
      key: 'author',
      label: 'Author',
      sortable: false,
      props: {
        className: classes.authorColumn,
      },
    },
    {
      key: 'platform',
      label: 'Platform',
      sortable: false,
      props: {
        className: classes.platformColumn,
      },
    },
  ];

  return (
    <Card className={classes.card}>
      <CardHeader
        title="Notes"
        subheader="Notes from our different platforms."
        style={{ paddingBottom: 0, marginBottom: 0 }}
        action={
          meetingNotes ? (
            <React.Fragment>
              <Button color="primary" href={meetingNotes} target="_blank">
                Meeting Notes
              </Button>
            </React.Fragment>
          ) : null
        }
      />
      <CardContent>
        <TextField
          value={textSearch}
          variant="outlined"
          autoFocus
          onChange={(e) => setTextSearch(e.currentTarget.value ?? undefined)}
          fullWidth
          placeholder="Type here to search"
        />
      </CardContent>
      <Divider />
      <PageableTable
        tableSettings={tableSettings}
        setTableSettings={setTableSettings}
        showSkeletonLoading
        rows={
          data?.data?.map((d, index) => ({
            key: `job_${index}`,
            cells: [
              {
                key: 'dateCreated',
                display: <DateDisplay date={d.created_on} />,
              },
              {
                key: 'content',
                display: (
                  <React.Fragment>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: d.content
                          .toString()
                          .replace(/(?:\r\n|\r|\n)/g, '<br>'),
                      }}
                    />
                    {withRelated && (
                      <RelatedCompanyChip
                        companyId={d.company_id}
                        companyName={d.company_title}
                      />
                    )}
                  </React.Fragment>
                ),
              },
              {
                key: 'author',
                display: d.created_by,
              },
              {
                key: 'platform',
                display: resolvePlatformName(d.platform),
              },
            ],
          })) ?? []
        }
        loading={loading}
        pageNumber={pageNumber}
        rowCount={data?.total_count ?? 0}
        columns={columns}
        onChangePage={setPageNumber}
      />
    </Card>
  );
};
