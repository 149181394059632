import {
  AppBar,
  Card,
  CardHeader,
  FormControl,
  Grid,
  makeStyles,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { DatePicker, HelpPopper, PageableTable } from 'components';
import { DateDisplay } from 'components/DateDisplay/DateDisplay';
import { PageableTableHeader } from 'components/PageableTable/components';
import { RelatedCompanyChip } from 'components/RelatedCompanyChip';
import { DateFormat } from 'config';
import {
  endOfYear,
  format,
  parseISO,
  startOfDay,
  startOfYear,
  subYears,
} from 'date-fns';
import {
  useLocalStorageSettings,
  TableSettings,
  getTableSettingsStorageKey,
  useDebounce,
} from 'hooks';
import { toMonetary } from 'lib';
import { fetchInvoices } from 'lib/Api/Invoices';
import { PagedInvoice } from 'lib/Model/Invoice';
import * as React from 'react';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: theme.spacing(2),
  },
  dateColumn: {
    width: 130,
    textAlign: 'center',
  },
  referenceColumn: {
    width: 150,
  },
  amountColumn: {
    width: 80,
  },
  formControl: {
    marginBottom: theme.spacing(2),
  },
  datePickerFormControl: {
    marginBottom: theme.spacing(0.5),
  },
}));

interface Props {
  companyId: string;
  withRelated: boolean;
}

export const Invoices: React.FC<Props> = ({ companyId, withRelated }) => {
  const [loading, setLoading] = React.useState(false);
  const classes = useStyles();
  const [pageNumber, setPageNumber] = React.useState(0);
  const [textSearch, setTextSearch] = React.useState('');
  const today = React.useMemo(() => startOfDay(new Date()), []);
  const [dateFrom, setDateFrom] = React.useState<string | undefined>(
    startOfYear(subYears(today, 2)).toISOString(),
  );
  const [dateTo, setDateTo] = React.useState<string | undefined>(
    endOfYear(today).toISOString(),
  );

  const InvoiceTitle = useDebounce(textSearch, 400);
  const { pathname, hash } = useLocation();
  const [tableSettings, setTableSettings] =
    useLocalStorageSettings<TableSettings>(
      getTableSettingsStorageKey(pathname, hash),
      { rowsPerPage: 25 },
    );

  const [data, setData] = React.useState<PagedInvoice | undefined>();

  const fetchData = React.useCallback(() => {
    setLoading(true);
    fetchInvoices(
      companyId,
      pageNumber + 1,
      tableSettings.rowsPerPage,
      withRelated,
      InvoiceTitle,
      dateFrom,
      dateTo,
    ).then((d) => {
      setLoading(false);
      setData(d);
    });
  }, [
    setLoading,
    setData,
    pageNumber,
    tableSettings.rowsPerPage,
    companyId,
    withRelated,
    InvoiceTitle,
    dateFrom,
    dateTo,
  ]);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  const columns: PageableTableHeader[] = [
    {
      key: 'dateIssued',
      label: 'Invoice date',
      sortable: false,
      props: {
        className: classes.dateColumn,
        align: 'center',
      },
    },
    {
      key: 'dueDate',
      label: 'Due Date',
      sortable: false,
      props: {
        className: classes.dateColumn,
        align: 'center',
      },
    },
    {
      key: 'description',
      label: 'Description',
      sortable: false,
    },
    {
      key: 'subTotal',
      label: 'Sub Total',
      sortable: false,
      props: {
        className: classes.amountColumn,
      },
    },
    {
      key: 'totalTax',
      label: 'Tax',
      sortable: false,
      props: {
        className: classes.amountColumn,
      },
    },
    {
      key: 'totalAmount',
      label: 'Total',
      sortable: false,
      props: {
        className: classes.amountColumn,
      },
    },
    {
      key: 'amountCredited',
      label: 'Credited',
      sortable: false,
      props: {
        className: classes.amountColumn,
      },
    },
    {
      key: 'outstandingBalance',
      label: 'Outstanding',
      sortable: false,
      props: {
        className: classes.amountColumn,
      },
    },
    {
      key: 'datePaid',
      label: 'Date paid',
      sortable: false,
      props: {
        className: classes.dateColumn,
      },
    },
  ];

  return (
    <Card className={classes.card}>
      <CardHeader title="Invoices" />
      <div style={{ paddingLeft: '10px', paddingRight: '10px' }}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <TextField
              variant="outlined"
              placeholder="Type here to search for invoices"
              value={textSearch}
              className={classes.formControl}
              onChange={(e) => setTextSearch(e.target.value ?? '')}
              autoFocus
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControl className={classes.datePickerFormControl} fullWidth>
              <DatePicker
                label={'Date from'}
                margin="none"
                fullWidth
                value={dateFrom}
                inputVariant="outlined"
                InputLabelProps={{ shrink: true }}
                onChange={(date: MaterialUiPickersDate) => {
                  setDateFrom(date ? date.toISOString() : undefined);
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControl className={classes.datePickerFormControl} fullWidth>
              <DatePicker
                label={'Date to'}
                margin="none"
                fullWidth
                value={dateTo}
                inputVariant="outlined"
                InputLabelProps={{ shrink: true }}
                onChange={(date: MaterialUiPickersDate) => {
                  setDateTo(date ? date.toISOString() : undefined);
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
      </div>
      {(data?.data.length ?? 0) > 0 && (
        <AppBar
          color="primary"
          position="static"
          style={{ textAlign: 'center' }}
        >
          <Toolbar>
            <Grid container>
              <Grid item xs={4}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography
                    variant="h6"
                    style={{ paddingLeft: 4, marginRight: 4 }}
                  >
                    {toMonetary(data?.total_sum ?? 0)}
                  </Typography>
                  <HelpPopper style={{ padding: 8 }}>
                    <Typography variant="body2" gutterBottom>
                      This figure does not exclude credit notes.
                    </Typography>
                  </HelpPopper>
                </div>
                <Typography variant="caption">Total Revenue</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="h6">
                  {toMonetary(data?.outstanding_sum ?? 0)}
                </Typography>
                <Typography variant="caption">
                  Current Outstanding Balance
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="h6">
                  {toMonetary(data?.credit_sum ?? 0)}
                </Typography>
                <Typography variant="caption">Total Amount Credited</Typography>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      )}
      <PageableTable
        tableSettings={tableSettings}
        setTableSettings={setTableSettings}
        showSkeletonLoading
        rows={
          data?.data?.map((d, index) => ({
            key: `invoice_${index}`,
            cells: [
              {
                key: 'dateIssued',
                display: <DateDisplay date={d.invoice_date} />,
              },
              {
                key: 'dateIssued',
                display: <DateDisplay date={d.invoice_due_date} />,
              },
              {
                key: 'description',
                display: (
                  <React.Fragment>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: d.Description.replace(/\|/g, '<br/><br/>')
                          .replace(/â‚¬/g, '€')
                          .replaceAll('MONTHLY', 'Monthly '),
                      }}
                    />
                    {withRelated && (
                      <RelatedCompanyChip
                        companyId={d.company_id}
                        companyName={d.company_title}
                      />
                    )}
                  </React.Fragment>
                ),
              },
              {
                key: 'subTotal',
                display: toMonetary(d.sub_total),
              },
              {
                key: 'totalTax',
                display: toMonetary(d.total_tax),
              },
              {
                key: 'totalAmount',
                display: toMonetary(d.total_amount),
              },
              {
                key: 'creditAmount',
                display: toMonetary(d.amount_credited),
              },
              {
                key: 'outstandingBalance',
                display: toMonetary(d.outstanding_balance),
              },
              {
                key: 'datePaid',
                display:
                  d.paid_on && d.paid_on !== 'NaT' ? (
                    format(parseISO(d.paid_on), DateFormat.SHORT)
                  ) : (
                    <Typography color="error" variant="body1">
                      Pending Payment
                    </Typography>
                  ),
              },
            ],
          })) ?? []
        }
        loading={loading}
        pageNumber={pageNumber}
        rowCount={data?.total_count ?? 0}
        columns={columns}
        onChangePage={setPageNumber}
      />
    </Card>
  );
};
