import { Grow, makeStyles, Typography } from '@material-ui/core';
import classNames from 'classnames';
import * as React from 'react';
import EmptyIcon from '@material-ui/icons/EmojiFoodBeverage';

interface Props
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  hideImage?: boolean;
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '100%',
    display: 'inline-flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 30,
  },
  image: {
    fontSize: theme.typography.fontSize * 3,
    marginBottom: theme.spacing(1),
  },
  text: {
    textAlign: 'center',
  },
}));

export const EmptyView: React.FC<Props> = ({
  hideImage = false,
  children,
  className,
  ...rest
}) => {
  const { wrapper, text, image } = useStyles();

  return (
    <div className={classNames(wrapper, className)} {...rest}>
      {!hideImage && (
        <Grow in={true} timeout={500}>
          <EmptyIcon className={image} />
        </Grow>
      )}

      <Typography
        className={text}
        component={typeof children === 'string' ? 'p' : 'div'}
      >
        {children || 'Nothing here yet.'}
      </Typography>
    </div>
  );
};
