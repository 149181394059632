import { makeStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

export const useStyles = makeStyles(
  ({ palette, breakpoints: { down }, spacing }) => ({
    row: {
      '&:nth-of-type(odd)': {
        backgroundColor: palette.background.default,
      },
    },
    selectRootResponsive: {
      [down('sm')]: {
        marginRight: spacing(2),
      },
    },
    actionsResponsive: {
      [down('sm')]: {
        marginLeft: spacing(2),
      },
    },
    btnRootResponsive: {
      [down('sm')]: {
        padding: spacing(3, 1.5),
      },
    },
    customFooter: {
      padding: spacing(2),
    },
    mobileRow: {
      margin: 0,
      background: palette.common.white,
      borderTop: '12px solid ' + palette.common.white,
      borderBottom: '4px solid ' + palette.common.white,
    },
    mobileAlternateRow: {
      margin: 0,
      background: grey[200],
      padding: spacing(2, 0),
      borderTop: '12px solid ' + grey[200],
      borderBottom: '4px solid ' + grey[200],
    },
    mobileLabelColumn: {
      width: '30%',
      padding: spacing(0.5, 2),
      background: 'none',
      border: 0,
      fontWeight: 500,
      fontSize: 14,
      verticalAlign: 'top',
    },
    mobileValueColumn: {
      width: 'auto',
      padding: spacing(0.5, 2),
      background: 'none',
      border: 0,
      fontSize: 14,
      '&:last-child': {
        padding: spacing(0.5, 2),
      },
      verticalAlign: 'top',
    },

    toolbar: {
      '& .MuiTablePagination-caption:nth-last-child(2)': {
        marginInline: 'auto',
      },
    },
  }),
);
