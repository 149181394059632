import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grow,
  IconButton,
  Paper,
  Popper,
  PopperPlacementType,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import classNames from 'classnames';
import React, { useRef } from 'react';
import { Close } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  paperShadow: {
    boxShadow: '0 0 3px #ccc',
  },
  arrow: {
    overflow: 'hidden',
    position: 'absolute',
    width: '2em',
    height: '1.26em',
    boxSizing: 'border-box',
    color: 'white',
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: '100%',
      height: '100%',
      boxShadow:
        '0px 2px 11px -1px rgb(0 0 0 / 13%), 7px 7px 1px 8px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
      backgroundColor: 'currentColor',
      transform: 'rotate(45deg)',
    },
  },
  extraBottomPopperPadding: {
    paddingBottom: theme.spacing(2),
  },
  extraBottomArrowMargin: {
    marginBottom: theme.spacing(2),
  },
  extraTopPopperPadding: {
    paddingTop: theme.spacing(2),
  },
  extraTopArrowMargin: {
    marginTop: theme.spacing(2),
  },
  extraRightPopperPadding: {
    marginRight: theme.spacing(2),
  },
  extraLeftPopperPadding: {
    marginLeft: theme.spacing(2),
  },

  popperResponsive: {
    zIndex: 5,
    [theme.breakpoints.down('md')]: {
      ...theme.mixins.popperResponsive,
    },
  },
  body: {
    whiteSpace: 'pre-line', //to allow line breaks in strings
    wordWrap: 'break-word',
  },
  popper: {
    zIndex: 10000,
    '&[x-placement*="bottom"] $arrow': {
      top: theme.spacing(-1.9),
      left: 0,
      marginLeft: theme.spacing(0.75),
      marginRight: theme.spacing(0.5),
      '&::before': {
        transformOrigin: '0 100%',
      },
    },
    '&[x-placement*="top"] $arrow': {
      bottom: theme.spacing(-1.9),
      left: 0,
      marginLeft: theme.spacing(0.75),
      marginRight: theme.spacing(0.5),
      '&::before': {
        transformOrigin: '0 100%',
      },
      transform: 'rotate(180deg)',
    },
    '&[x-placement*="right"] $arrow': {
      left: theme.spacing(-2.3),
      '&::before': {
        transformOrigin: '0 100%',
      },
      transform: 'rotate(270deg)',
    },
    '&[x-placement*="left"] $arrow': {
      right: theme.spacing(-2.3),
      '&::before': {
        transformOrigin: '0 100%',
      },
      transform: 'rotate(90deg)',
    },
  },
  cardContent: {
    paddingBottom: theme.spacing(1),
    position: 'relative',
  },
  dismissButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  alignButtons: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  subBodyText: {
    paddingTop: theme.spacing(1),
  },
  actionsContent: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  primaryButton: {
    paddingTop: theme.spacing(0),
  },
  titleHeader: {
    paddingBottom: 4,
    fontSize: 15,
  },
}));

interface Props {
  titleHeader: string;
  bodyText?: React.ReactNode | string;
  subBodyText?: string;
  maxWidth?: number;
  buttonText?: string;
  onButtonClick?: () => void;
  children: React.ReactNode;
  bodyNode?: React.ReactNode;
  className?: string;
  popperClassName?: string;
  placement?: PopperPlacementType;
  enabledArrow?: boolean;
  offset?: string;
  useDefaultUnderline?: boolean;
  showDismissButton?: boolean;
}

export const CustomTooltip: React.FC<Props> = ({
  titleHeader,
  bodyText,
  subBodyText,
  maxWidth,
  buttonText,
  children,
  bodyNode,
  className,
  popperClassName,
  placement,
  enabledArrow = false,
  offset = '0, 5',
  onButtonClick,
  useDefaultUnderline,
  showDismissButton,
}) => {
  const classes = useStyles();

  const [popperEl, setPopperEl] = React.useState<HTMLDivElement | null>(null);
  const triggerRef = useRef<HTMLDivElement | null>(null);
  const [arrowRef, setArrowRef] = React.useState<HTMLElement | null>(null);

  const {
    breakpoints: { down },
  } = useTheme();
  const isMobile = useMediaQuery(down('sm'));

  const isLeftPlacement = checkPlacement(placement, [
    'left',
    'left-start',
    'left-end',
  ]);
  const isTopPlacement = checkPlacement(placement, [
    'top',
    'top-start',
    'top-end',
  ]);
  const isRightPlacement = checkPlacement(placement, [
    'right',
    'right-start',
    'right-end',
  ]);
  const isBottomPlacement = checkPlacement(placement, [
    'bottom',
    'bottom-start',
    'bottom-end',
  ]);

  let timeout: NodeJS.Timeout | undefined;
  const popperDelay = 300;

  return (
    <div
      role="presentation"
      className={className}
      ref={triggerRef}
      onMouseEnter={(e) => {
        const target = e.currentTarget;
        stopTimeout();
        timeout = setTimeout(() => {
          stopTimeout();
          setPopperEl(target);
        }, popperDelay);
      }}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        if (Boolean(popperEl)) {
          // Do nothing when already open
          return false;
        }
        // Ensuring that clicking a tooltip opens it (useful on mobile)
        const target = e.currentTarget;
        stopTimeout();
        // Show immediately on click
        setPopperEl(target);
        return false;
      }}
      onMouseLeave={(e) => {
        stopTimeout();
        if (isMobile) {
          // Hide immediately on mobile
          setPopperEl(null);
          return;
        }
        timeout = setTimeout(() => {
          stopTimeout();
          setPopperEl(null);
        }, popperDelay);
      }}
    >
      {useDefaultUnderline ? (
        <span
          style={{
            borderBottom: '1px dashed #37474F',
          }}
        >
          {children}
        </span>
      ) : (
        children
      )}

      <Popper
        open={Boolean(popperEl)}
        anchorEl={triggerRef.current}
        className={classNames(
          classes.popperResponsive,
          classes.popper,
          popperClassName,
          {
            [classes.extraBottomPopperPadding]:
              enabledArrow && isBottomPlacement,
            [classes.extraTopPopperPadding]: enabledArrow && isTopPlacement,
            [classes.extraRightPopperPadding]: enabledArrow && isRightPlacement,
            [classes.extraLeftPopperPadding]: enabledArrow && isLeftPlacement,
          },
        )}
        placement={placement ?? 'bottom'}
        transition
        modifiers={{
          offset: {
            enabled: true,
            offset: offset,
          },
          arrow: {
            enabled: enabledArrow,
            element: arrowRef,
          },
        }}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper className={classes.paperShadow}>
              {enabledArrow && (
                <span className={classNames(classes.arrow)} ref={setArrowRef} />
              )}

              <Card style={{ maxWidth }}>
                <CardContent className={classes.cardContent}>
                  {(isMobile || showDismissButton) && (
                    <IconButton
                      className={classes.dismissButton}
                      size="small"
                      onClick={() => setPopperEl(null)}
                    >
                      <Close />
                    </IconButton>
                  )}
                  <Typography className={classes.titleHeader} variant="h6">
                    {titleHeader}
                  </Typography>

                  {!bodyNode ? (
                    <React.Fragment>
                      {bodyText && (
                        <Typography className={classes.body}>
                          {bodyText}
                        </Typography>
                      )}
                      {subBodyText && (
                        <Typography className={classes.subBodyText}>
                          {' '}
                          {subBodyText}{' '}
                        </Typography>
                      )}
                    </React.Fragment>
                  ) : (
                    bodyNode
                  )}
                </CardContent>

                {buttonText && (
                  <CardActions className={classes.alignButtons}>
                    <div className={classes.actionsContent}>
                      <Button
                        color="primary"
                        onClick={onButtonClick}
                        className={classes.primaryButton}
                      >
                        {buttonText}
                      </Button>
                    </div>
                  </CardActions>
                )}
              </Card>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );

  function stopTimeout() {
    if (timeout) {
      clearTimeout(timeout);
      timeout = undefined;
    }
  }

  function checkPlacement(
    placement: string | undefined,
    validPlacements: string[],
  ): boolean {
    return typeof placement === 'string' && validPlacements.includes(placement);
  }
};
