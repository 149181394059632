import * as React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
} from '@material-ui/core';
import { PublishingOptions } from 'lib/Model/Job';
import { format, isValid, parseISO } from 'date-fns';
import { DateFormat } from 'config';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    textAlign: 'center',
  },
}));

interface Props {
  open: boolean;
  title: string;
  publishOptions: PublishingOptions[];
  onClose(): void;
  loading?: boolean;
}

const PublishedOptionsDialog: React.FC<Props> = ({
  open,
  title,
  publishOptions,
  onClose,
  loading,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="publishOptions-dialog"
      maxWidth="lg"
    >
      <DialogTitle id="publishOptions-dialog" className={classes.dialogTitle}>
        Publishing Options for {title}
      </DialogTitle>
      <DialogContent>
        {loading ? (
          <Skeleton variant="rect" style={{ height: 200 }} />
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Published On</TableCell>
                <TableCell>Expired / Unpublished On</TableCell>
                <TableCell>Publish Option</TableCell>
                <TableCell>Publish Status</TableCell>
                <TableCell>Published By</TableCell>
                <TableCell>Duration (Days)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {publishOptions.map((publishOption) => (
                <TableRow key={publishOption.created_on}>
                  <TableCell>
                    {format(
                      parseISO(publishOption.created_on),
                      DateFormat.EXCEL,
                    )}
                  </TableCell>
                  <TableCell>
                    {publishOption.unpublished_on
                      ? isValid(parseISO(publishOption.unpublished_on))
                        ? format(
                            parseISO(publishOption.unpublished_on),
                            DateFormat.EXCEL,
                          )
                        : 'N/A'
                      : 'N/A'}
                  </TableCell>
                  <TableCell>
                    {publishOption.publishing_option_name ?? 'N/A'}
                  </TableCell>
                  <TableCell>{publishOption.publish_status}</TableCell>
                  <TableCell>{publishOption.created_by}</TableCell>
                  <TableCell>{publishOption.published_duration}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PublishedOptionsDialog;
