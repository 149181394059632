import {
  Avatar,
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  Divider,
  makeStyles,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { Email, Phone, Smartphone } from '@material-ui/icons';
import { PageableTable, PopperDropdownMenu, PopperMenuItem } from 'components';
import { PageableTableHeader } from 'components/PageableTable/components';
import { RelatedCompanyChip } from 'components/RelatedCompanyChip';
import {
  useLocalStorageSettings,
  TableSettings,
  getTableSettingsStorageKey,
} from 'hooks';
import { fetchContacts } from 'lib/Api/Contacts';
import { stringAvatar } from 'lib/Helper/Avatars';
import { PagedContacts } from 'lib/Model/Contact';
import * as React from 'react';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: theme.spacing(2),
  },
  contactColumn: {},
  contactsColumn: {
    width: 150,
    textAlign: 'right',
  },
  avatarColumn: {
    width: 60,
  },
}));

interface Props {
  companyId: string;
  withRelated: boolean;
}

export const Contacts: React.FC<Props> = ({ companyId, withRelated }) => {
  const [loading, setLoading] = React.useState(false);
  const classes = useStyles();
  const [pageNumber, setPageNumber] = React.useState(0);
  const { pathname, hash } = useLocation();
  const [tableSettings, setTableSettings] =
    useLocalStorageSettings<TableSettings>(
      getTableSettingsStorageKey(pathname, hash),
      { rowsPerPage: 25 },
    );

  const [data, setData] = React.useState<PagedContacts | undefined>();

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const fetchData = React.useCallback(() => {
    setLoading(true);
    fetchContacts(
      companyId,
      pageNumber + 1,
      tableSettings.rowsPerPage,
      withRelated,
    ).then((d) => {
      setLoading(false);
      setData(d);
    });
  }, [
    setLoading,
    setData,
    pageNumber,
    tableSettings.rowsPerPage,
    companyId,
    withRelated,
  ]);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  const columns: PageableTableHeader[] = [
    {
      key: 'avatar',
      label: '',
      sortable: false,
    },
    {
      key: 'contact',
      label: 'Contact',
      sortable: false,
    },
    {
      key: 'actions',
      label: 'Actions',
      sortable: false,
    },
    {
      key: 'contacts',
      label: '',
      sortable: false,
    },
  ];

  return (
    <Card className={classes.card}>
      <CardHeader title="Contacts" />
      <Divider />
      <PageableTable
        tableSettings={tableSettings}
        setTableSettings={setTableSettings}
        showSkeletonLoading
        hideColumns
        rows={
          data?.data?.map((d) => {
            const menuItems: PopperMenuItem[] = [];
            if (d.email_address) {
              menuItems.push({
                label: 'Email',
                subText: d.email_address,
                onClick: () => {
                  window.open(`mailto:${d.email_address}`);
                },
              });
            }

            if (d.phone_number) {
              menuItems.push({
                label: 'Phone',
                subText: d.phone_number,
                onClick: () => {
                  window.open(`tel:${d.phone_number}`);
                },
              });
            }

            if (d.mobile_number) {
              menuItems.push({
                label: 'Mobile',
                subText: d.mobile_number,
                onClick: () => {
                  window.open(`tel:${d.mobile_number}`);
                },
              });
            }
            return {
              key: d.id,
              cells: [
                {
                  key: 'avatar',
                  display: <Avatar {...stringAvatar(d.name_surname)} />,
                  props: {
                    className: classes.avatarColumn,
                  },
                },
                {
                  key: 'actions',
                  props: {
                    style: isDesktop
                      ? {
                          width: 160,
                          textAlign: 'left',
                        }
                      : {
                          display: 'none',
                        },
                  },
                  display: (
                    <ButtonGroup>
                      {d.email_address && (
                        <Tooltip title={d.email_address}>
                          <Button href={`mailto:${d.email_address}`}>
                            <Email />
                          </Button>
                        </Tooltip>
                      )}
                      {d.phone_number && (
                        <Tooltip title={d.phone_number}>
                          <Button href={`tel:${d.phone_number}`}>
                            <Phone />
                          </Button>
                        </Tooltip>
                      )}
                      {d.mobile_number && (
                        <Tooltip title={d.mobile_number}>
                          <Button href={`tel:${d.mobile_number}`}>
                            <Smartphone />
                          </Button>
                        </Tooltip>
                      )}
                    </ButtonGroup>
                  ),
                },
                {
                  key: 'contact',
                  display: (
                    <React.Fragment>
                      <Typography variant="h6">{d.name_surname}</Typography>
                      <Typography variant="caption">
                        {d.email_address}
                      </Typography>
                    </React.Fragment>
                  ),
                },
                {
                  key: 'position_title',
                  display: (
                    <React.Fragment>
                      <Typography variant="h6">{d.position_title}</Typography>
                      {withRelated && (
                        <RelatedCompanyChip
                          companyId={d.company_id}
                          companyName={d.company_title}
                        />
                      )}
                    </React.Fragment>
                  ),
                },
                {
                  key: 'contacts',
                  display: (
                    <React.Fragment>
                      <PopperDropdownMenu menuItems={menuItems} />
                    </React.Fragment>
                  ),
                  props: {
                    className: classes.contactsColumn,
                  },
                },
              ],
            };
          }) ?? []
        }
        loading={loading}
        pageNumber={pageNumber}
        rowCount={data?.total_count ?? 0}
        columns={columns}
        onChangePage={setPageNumber}
      />
    </Card>
  );
};
