import { Typography, Button, Divider } from '@material-ui/core';
import brandLogo from '../../layouts/App/assets/exp42-logo.png';
import * as React from 'react';
import { useStyles } from './styles';

export const RefreshRequired: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <a href="https://exp42.com" target="_blank" rel="noopener noreferrer">
        <img
          src={brandLogo}
          alt="Expedition 42"
          className={classes.websiteHeaderLogo}
        />
      </a>
      <Typography color="inherit" align="center" component="div">
        <Typography variant="h5" gutterBottom>
          Reload required
        </Typography>
        <Typography variant="subtitle2" gutterBottom>
          A newer version of Exp42 Intranet is available.
        </Typography>
        <div className={classes.buttons}>
          <Button
            color="primary"
            variant="contained"
            className={classes.successButton}
            onClick={() => document.location.reload()}
          >
            Reload for latest version
          </Button>
        </div>
        <Divider className={classes.divider} />
        <Typography>
          If that doesn't work, please try refreshing manually
          <br />
          <em>Ctrl + Shift + R (Windows)</em> or{' '}
          <em>⌘ Cmd + Shift + R (Mac).</em>
          <br /> <br />
          Kindly{' '}
          <a
            href="https://talexio.com/contact-us/?src=reload-required"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.link}
          >
            contact us
          </a>{' '}
          if you continue seeing this message after reloading
        </Typography>
      </Typography>
    </div>
  );
};
