import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from '@material-ui/core';
import { DialogProps } from '@material-ui/core/Dialog';
import * as React from 'react';
import { ErrorContent } from './ErrorContent';

interface Props {
  error: Error;
  helpMsg?: string;
  dialogProps?: DialogProps;
}

export const ErrorView: React.FC<Props> = ({ dialogProps, ...rest }) => {
  if (!dialogProps) {
    return <ErrorContent {...rest} />;
  }

  const { open, onClose } = dialogProps;

  return (
    <Dialog disableEnforceFocus open={open} onClose={onClose}>
      <DialogContent>
        <ErrorContent {...rest} />
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};
