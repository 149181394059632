import {
  Avatar,
  Card,
  CardContent,
  Chip,
  CardHeader,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  makeStyles,
  TextField,
  Typography,
  Tooltip,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { BarChartRounded, Folder } from '@material-ui/icons';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Alert } from '@material-ui/lab';
import { useDebounce } from 'hooks';
import { fetchClients } from 'lib/Api/Clients';
import { stringAvatar } from 'lib/Helper/Avatars';
import { PagedClients } from 'lib/Model/Client';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import {
  resolveMergedAliasesToPlatformNames,
  resolvePlatformName,
} from 'views/Clients/components/SuggestedMatches';
import { DashboardsList } from 'views/Dashboards/DashboardsView';
import { ActivityFeed } from './components/ActivityFeed';
import { DelayedLinearProgress } from 'components';

const useStyles = makeStyles((theme) => ({
  clickableListItem: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: grey[200],
    },
  },
  itemIconImg: {
    width: 32,
  },
  card: {
    marginBottom: theme.spacing(2),
  },
}));

export const DashboardView: React.FC = () => {
  const classes = useStyles();
  const shortcuts = [
    {
      link: 'https://exp42.talexiohr.com',
      title: 'Expedition42 Talexio',
      description: 'Manage your personal details, book leave and more.',
      icon: '/talexio.png',
    },
    {
      link: 'https://exp42.talexiohr.com/ats/dashboard',
      title: 'Konnekt ATS',
      description:
        'Our applicant tracking system with candidates, vacancies and clients.',
      icon: '/konnekt.png',
    },
    {
      link: 'https://employers.jobsinmalta.com/users/login',
      title: 'JIMHub',
      description: 'Admin portal for the jobsinmalta website.',
      icon: '/jobsinmalta.png',
    },
    {
      divider: true,
    },
    {
      link: 'https://chat.exp42.com/',
      title: 'Mattermost',
      description: 'Our internal chat platform.',
      icon: '/mattermost.png',
    },
    {
      link: 'https://talexiohr.zendesk.com/agent/',
      title: 'Zendesk Support',
      description:
        'Receive and action support tickets submitted from users of all our systems.',
      icon: '/zendesk_support.png',
    },
    {
      link: 'https://app.futuresimple.com/crm/',
      title: 'Zendesk Sell',
      description: 'Manage incoming leads, clients and proposals.',
      icon: '/zendesk_sell.png',
    },
    {
      link: 'https://preeosoftware.atlassian.net/',
      title: 'JIRA',
      description: 'Keep track of development tasks and issues.',
      icon: '/jira.ico',
    },
    {
      link: 'https://drive.google.com/drive/folders/0ANqsmV1Ux7tQUk9PVA',
      title: 'Client Drive',
      description:
        'Our Google Drive folder for client agreements and meeting notes.',
      icon: '/Google_Drive.png',
    },
    {
      link: 'https://exp42.signrequest.com/',
      title: 'SignRequest',
      description:
        'Quickly sign and send terms of business and agreements to clients.',
      icon: '/signrequest.png',
    },
    {
      link: 'https://registry.mbr.mt/ROC/index.jsp#companySearch.do?action=companyDetails',
      title: 'Malta Business Registry',
      description: 'Find information on companies listed on the MBR.',
      icon: '/roca-logo.png',
    },
    {
      link: 'https://vat-search.eu/search/mt',
      title: 'VAT Search',
      description: 'Find the VAT Number of a company or entity.',
      icon: '/vat-search.png',
    },
    {
      link: '/spreadsheets',
      title: 'Other Resources',
      description: 'A list of other spreadsheets, reports and tools.',
    },
  ];
  const sections = [
    {
      title: 'HR & Recruitment',
      shortcuts: shortcuts.slice(0, 4),
    },
    {
      title: 'Internal Communication & Tools',
      shortcuts: shortcuts.slice(4, 9),
    },
    {
      title: 'Resources & Others',
      shortcuts: shortcuts.slice(10, 14),
    },
  ];

  const [textSearch, setTextSearch] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const debouncedTextSearch = useDebounce(textSearch, 500);

  const [data, setData] = React.useState<PagedClients | undefined>();

  const fetchData = React.useCallback(() => {
    if (debouncedTextSearch.length === 0) {
      setData(undefined);
      return;
    }
    setLoading(true);
    fetchClients(1, 6, debouncedTextSearch).then((d) => {
      setLoading(false);
      setData(d);
    });
  }, [setLoading, setData, debouncedTextSearch]);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  const history = useHistory();

  const commercialOpsDashboards = [
    'Customer Support',
    'Commercial Data',
    'PROACT',
    'Marketing',
    'Quality Applications',
    'Net Promoter Score (NPS)',
  ];

  const brandServicesDashboards = [
    'Jobsinmalta',
    'Executive Recruitment',
    'Recruitment Service',
    'Recruitment - Vacancy Success',
    'Payroll Service',
    'Product Dashboard',
    'Salariesinmalta',
  ];

  const otherDashboards = ['Employment Data'];

  const renderDashboardList = (categoryList: string | string[]) => {
    return DashboardsList.filter((dashboard) =>
      categoryList.includes(dashboard.label),
    ).map((d, index) => (
      <ListItem
        key={`dashboard_${index}`}
        button
        onClick={() => history.push(d.path)}
      >
        <ListItemIcon>
          <BarChartRounded />
        </ListItemIcon>
        <ListItemText primary={d.label} secondary={d.description} />
      </ListItem>
    ));
  };

  return (
    <Grid container>
      <Grid item xs={12} md={8}>
        <ActivityFeed />
      </Grid>
      <Grid item xs={12} md={4}>
        <Card
          className={classes.card}
          style={{ position: 'relative', overflow: 'visible' }}
        >
          <CardHeader
            title="Client Search"
            subheader="Search for clients across different platforms."
            action={
              <Button color="secondary" href="/clients" target="_blank">
                Advanced Search
              </Button>
            }
          />

          <CardContent>
            <TextField
              variant="outlined"
              placeholder="Type here to search for clients"
              value={textSearch}
              onChange={(e) => setTextSearch(e.target.value ?? '')}
              autoFocus
              fullWidth
              helperText={
                data?.data && data?.total_count
                  ? `Showing ${data.data.length} of ${data.total_count} result${
                      data.total_count !== 1 ? 's' : ''
                    }.`
                  : undefined
              }
            />
          </CardContent>
          <DelayedLinearProgress loading={loading} />
          <React.Fragment>
            {data && data.data.length > 0 && (
              <List
                style={{
                  position: 'absolute',
                  zIndex: 9999,
                  padding: 10,
                  left: 16,
                  right: 16,
                  backgroundColor: 'rgba(255,255,255,0.95)',
                  boxShadow: '0px 0px 5px #ccc',
                }}
              >
                {data?.data.map((client, index) => (
                  <ListItem
                    key={`client_${index}`}
                    className={classes.clickableListItem}
                    onClick={() => {
                      history.push(`/clients/${client.id}`);
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar
                        variant="rounded"
                        {...stringAvatar(client.company_title)}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={client.company_title}
                      secondary={
                        <>
                          {resolvePlatformName(client.id)}
                          {Boolean(client.merge_count) &&
                            resolveMergedAliasesToPlatformNames(
                              client.merged_aliases ?? null,
                            ).length > 0 &&
                            ' and '}
                          {Boolean(client.merge_count) &&
                            resolveMergedAliasesToPlatformNames(
                              client.merged_aliases ?? null,
                            ).length > 0 && (
                              <Tooltip
                                title={
                                  <React.Fragment>
                                    {resolveMergedAliasesToPlatformNames(
                                      client.merged_aliases ?? null,
                                    ).map((name, index) => (
                                      <div key={index}>{name}</div>
                                    ))}
                                  </React.Fragment>
                                }
                                placement="top"
                              >
                                <Chip
                                  label={`${client.merge_count} ${
                                    client.merge_count! > 1 ? 'merges' : 'merge'
                                  }`}
                                  size="small"
                                />
                              </Tooltip>
                            )}
                        </>
                      }
                    />
                  </ListItem>
                ))}
              </List>
            )}
          </React.Fragment>
          <Alert severity="warning">
            <Typography variant="body1">
              Information found in the intranet is highly confidential.
              <br />
              <strong>Never share this information</strong> outside of
              Expedition42.
            </Typography>
          </Alert>
        </Card>
        <Card className={classes.card}>
          <CardHeader
            title="Expedition42 Tools"
            subheader="Quick links to our tools and platforms."
          />

          {sections.map((section, secIndex) => (
            <Accordion key={`section_${secIndex}`}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`section${secIndex + 1}-content`}
                id={`section${secIndex + 1}-header`}
              >
                <Typography>{section.title}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <List>
                  {section.shortcuts.map((d, index) =>
                    d.divider ? (
                      <Divider key={`shortcut_${index}`} />
                    ) : (
                      <ListItem
                        key={`shortcut_${index}`}
                        className={classes.clickableListItem}
                        onClick={() => {
                          window.open(d.link);
                        }}
                      >
                        {d.icon ? (
                          <ListItemIcon>
                            <img
                              alt={d.title}
                              className={classes.itemIconImg}
                              src={d.icon}
                            />
                          </ListItemIcon>
                        ) : (
                          <ListItemIcon>
                            <Folder />
                          </ListItemIcon>
                        )}
                        <ListItemText
                          primary={d.title}
                          secondary={d.description}
                        />
                      </ListItem>
                    ),
                  )}
                </List>
              </AccordionDetails>
            </Accordion>
          ))}
        </Card>

        <Card className={classes.card}>
          <CardHeader
            title="Dashboards"
            subheader="Looker Studio dashboards."
          />
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Commercial & Operations</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List>{renderDashboardList(commercialOpsDashboards)}</List>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Recruitment, Job Board & Other Services</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List>{renderDashboardList(brandServicesDashboards)}</List>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Other Dashboards</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List>{renderDashboardList(otherDashboards)}</List>
            </AccordionDetails>
          </Accordion>
        </Card>
      </Grid>
    </Grid>
  );
};
