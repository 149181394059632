import { AppBar, Typography } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import { ResponsiveIconButton } from 'components';
import * as React from 'react';
import { useStyles } from './styles';

interface Props {
  updateServiceWorker(): void;
}

export const UpdateNotification: React.FC<Props> = ({
  updateServiceWorker,
}) => {
  const classes = useStyles();

  return (
    <AppBar position="relative" component="div" className={classes.wrapper}>
      <Typography variant="h6" component="span">
        A new update is available! Please refresh to get the latest version of
        Exp42 Intranet.
      </Typography>

      <ResponsiveIconButton
        color="inherit"
        icon={RefreshIcon}
        onClick={updateServiceWorker}
        variant="outlined"
      >
        Click to Refresh
      </ResponsiveIconButton>
    </AppBar>
  );
};
