import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ClientDetails } from './components/ClientDetails';
import { ClientList } from './components/ClientList';

export const ClientsView: React.FC = () => {
  return (
    <Switch>
      <Route exact path={'/clients'}>
        <ClientList />
      </Route>
      <Route path={`/clients/:id`}>
        <ClientDetails />
      </Route>
    </Switch>
  );
};
