import axios from 'axios';
import { Config } from 'config';
import { getAccessToken } from 'lib/Helper/Auth';
import {
  Activity,
  Call,
  Meeting,
  PagedActivity,
  PagedCalls,
  PagedMeetings,
} from 'lib/Model/Activity';

export function fetchActivity(
  companyId: string | null,
  pageNumber: number,
  itemsPerPage: number,
  withRelated: boolean,
  selectedCams: string[],
  resourceType: string[],
): Promise<PagedActivity> {
  const url = companyId
    ? `${Config.API_BASE_URL}intranet/companies/${companyId}/activity`
    : `${Config.API_BASE_URL}intranet/companies/activity`;

  return axios
    .get(url, {
      params: {
        company_id: companyId,
        page: pageNumber,
        page_size: itemsPerPage,
        show_related: withRelated,
        zendesk_leader: selectedCams,
        resource_type: resourceType,
      },
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
        'access-token': getAccessToken(),
      },
    })
    .then((response) => {
      return {
        total_count: response.data.total_count,
        data: response.data.data as Activity[],
      };
    });
}

export function fetchMeetings(
  companyId: string,
  pageNumber: number,
  itemsPerPage: number,
  includeFutureEvents: boolean,
  withRelated: boolean,
): Promise<PagedMeetings> {
  return axios
    .get(`${Config.API_BASE_URL}intranet/companies/${companyId}/meetings`, {
      params: {
        page: pageNumber,
        page_size: itemsPerPage,
        include_future_events: includeFutureEvents,
        show_related: withRelated,
      },
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
        'access-token': getAccessToken(),
      },
    })
    .then((response) => {
      return {
        total_count: response.data.total_count,
        data: response.data.data as Meeting[],
      };
    });
}

export function fetchCalls(
  companyId: string,
  pageNumber: number,
  itemsPerPage: number,
  withRelated: boolean,
): Promise<PagedCalls> {
  return axios
    .get(`${Config.API_BASE_URL}intranet/companies/${companyId}/calls`, {
      params: {
        page: pageNumber,
        page_size: itemsPerPage,
        show_related: withRelated,
      },
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
        'access-token': getAccessToken(),
      },
    })
    .then((response) => {
      return {
        total_count: response.data.total_count,
        data: response.data.data as Call[],
      };
    });
}

export function fetchActivityDetail(
  companyId: string,
  id: string,
): Promise<any> {
  return axios
    .get(
      `${Config.API_BASE_URL}intranet/companies/${companyId}/activity/${id}`,
      {
        headers: {
          'access-token': getAccessToken(),
        },
      },
    )
    .then((response) => {
      return response.data.data[0] as Activity;
    });
}

export function fetchActivityFeedDetail(id: string): Promise<any> {
  return axios
    .get(`${Config.API_BASE_URL}intranet/companies/activity/${id}`, {
      headers: {
        'access-token': getAccessToken(),
      },
    })
    .then((response) => {
      return response.data.data[0] as Activity;
    });
}
