import * as React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { toMonetary } from 'lib';
import { WalletInvoice } from 'lib/Model/Wallet';
import { format, isValid, parseISO } from 'date-fns';
import { DateFormat } from 'config';
import { Skeleton } from '@material-ui/lab';
import { CheckCircleOutline, HighlightOff } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    textAlign: 'center',
  },
}));

interface Props {
  open: boolean;
  createdOn: string;
  invoices: WalletInvoice[];
  onClose(): void;
  loading?: boolean;
}

const WalletInvoicesDialog: React.FC<Props> = ({
  open,
  createdOn,
  invoices,
  onClose,
  loading,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="wallet-invoices-dialog"
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle id="wallet-invoices-dialog" className={classes.dialogTitle}>
        Invoices for{' '}
        {isValid(parseISO(createdOn))
          ? format(parseISO(createdOn), DateFormat.EXCEL)
          : 'Invalid Date'}
      </DialogTitle>
      <DialogContent>
        {loading ? (
          <Skeleton variant="rect" style={{ height: 200 }} />
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Created On</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Invoice Amount</TableCell>
                <TableCell>Processed</TableCell>
                <TableCell>Paid</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {invoices.map((invoice) => (
                <TableRow key={invoice.created_on}>
                  <TableCell>
                    {parseISO(invoice.created_on)
                      ? format(parseISO(invoice.created_on), DateFormat.EXCEL)
                      : 'Invalid Date'}
                  </TableCell>
                  <TableCell>{invoice.description}</TableCell>
                  <TableCell>{toMonetary(invoice.amount) ?? 'N/A'}</TableCell>
                  <TableCell>
                    {invoice.is_processed ? (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <CheckCircleOutline style={{ color: 'green' }} />
                        <Typography
                          style={{ color: 'green', marginLeft: '5px' }}
                        >
                          Processed
                        </Typography>
                      </div>
                    ) : (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <HighlightOff style={{ color: 'red' }} />
                        <Typography style={{ color: 'red', marginLeft: '5px' }}>
                          Not Processed
                        </Typography>
                      </div>
                    )}
                  </TableCell>
                  <TableCell>
                    {invoice.is_paid ? (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <CheckCircleOutline style={{ color: 'green' }} />
                        <Typography
                          style={{ color: 'green', marginLeft: '5px' }}
                        >
                          Paid
                        </Typography>
                      </div>
                    ) : (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <HighlightOff style={{ color: 'red' }} />
                        <Typography style={{ color: 'red', marginLeft: '5px' }}>
                          Not Paid
                        </Typography>
                      </div>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WalletInvoicesDialog;
